import { IconCopy, IconX } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLocale } from "../../constants/language";
import QRCode from "react-qr-code";
import { Success } from "../Core/Errors";
import { getCoinNetwork, getWalletAddress } from "../../service/api";

function DepositCoin() {
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState(true);
  const [loading, setLoading] = useState(false);
  const [network, setNetwork] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState();
  const [copied, setCopied] = useState(true);
  const [data, setData] = useState({ address: "", network: "", amount: "" });
  // const [dataErr, setDataErr] = useState({
  //   addressErr: false,
  //   networkErr: false,
  //   amountErr: false,
  // });
  const { user, activeWithdrawCoin } = useSelector((state) => state?.user);

  // const getaddreddData = () => {
  //   getWalletAddress("66ee9a6dda3fbafa465f1c68")
  //     ?.then((res) => {
  //       console.log("%c Line:26 🍒 res", "color:#b03734", res);
  //     })
  //     .catch((e) => {
  //       console.log("%c Line:29 🥛 e", "color:#465975", e);
  //     });
  // };

  const getaddreddData = async () => {
    setLoading(true);
    getWalletAddress(user?._id)
      ?.then((res) => {
        if (res?.status) {
          setSelectedNetwork(res?.data);
        }
      })
      .finally(() => setLoading(false));
  };

  const getNetwrokData = () => {
    getCoinNetwork({
      page: 1,
      sizePerPage: 100,
      pagination: false,
      coin: activeWithdrawCoin?._id,
    })
      .then((res) => {
        if (res?.status) {
          setNetwork(res?.data?.result[0]?.networks);
        }
      })
      .catch((e) => {
        console.log("%c Line:30 🍏 e", "color:#93c0a4", e);
      });
  };

  useEffect(() => {
    if (activeWithdrawCoin) getNetwrokData();
  }, [activeWithdrawCoin]);

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };

  return (
    <div
      // onClick={() => setDropdown(!dropdown)}
      className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col w-full"
    >
      <div className=" flex justify-center items-center relative">
        <IconX
          className="size-7 cursor-pointer absolute left-0"
          onClick={(e) => {
            e.stopPropagation();
            navigate(-1);
          }}
        />
        <label className={`text-[#111] text-base`}>
          {/* Deposit  */}
          {getLocale("deposit")}
          {activeWithdrawCoin?.name}
        </label>
      </div>
      <div className="mt-8">
        <div className="flex items-start justify-start gap-y-1 flex-col mx-auto">
          <QRCode
            value={
              !loading
                ? data?.network
                  ? data?.network?.chainName === "Bitcoin"
                    ? selectedNetwork?.btc?.address
                    : data?.network?.chainName === "Solana"
                    ? selectedNetwork?.sol?.publicKey
                    : selectedNetwork?.evm?.address
                  : "KMBIO"
                : "KMBIO"
            }
            size={200}
            className="mx-auto"
          />
          <div className="mt-4 border border-[#111] rounded-lg py-3 px-4 w-full text-left">
            <p className={`text-[#111] text-xs opacity-70`}>Network</p>
            <p
              className={`text-[#111] text-base opacity-100 border-b border-[#111] pb-3`}
            >
              {data?.network ? data?.network?.chainName : "******"}
            </p>
            <p className={`text-[#111] text-xs opacity-70 pt-3`}>
              Deposit Address
            </p>
            <div className="flex items-center justify-between gap-4">
              {" "}
              <p
                className={`text-[#111] w-11/12 text-base opacity-100 overflow-x-hidden`}
              >
                {data?.network?.chainName === "Bitcoin"
                  ? selectedNetwork?.btc?.address
                  : data?.network?.chainName === "Solana"
                  ? selectedNetwork?.sol?.publicKey
                  : selectedNetwork?.evm?.address}
              </p>
              <IconCopy
                onClick={(e) => {
                  e.stopPropagation();
                  handleCopy("13ttxE7WHxQuFC9FJqnbFErxvnYK7YTSeg");
                  Success("Text copied");
                }}
                className="size-5 w-1/12 cursor-pointer"
              />
            </div>
          </div>
        </div>

        {/* <div className="flex items-center justify-between mt-48">
         
          <button className="rounded-md bg-[#111] hover:bg-opacity-85 px-3 py-1 text-white text-base font-semibold">
            // Withdraw
            {getLocale("withdraw")}
          </button>
        </div> */}
      </div>
      <div
        // onClick={(e) => {
        //   e.stopPropagation();
        //   setDropdown(!dropdown);
        // }}
        className={`${
          dropdown ? "min-h-[50vh] h-full" : "h-0"
        } transition-all w-full rounded-2xl bg-slate-300 border-t-2 absolute left-0 bottom-0  flex justify-end flex-col overflow-hidden scrollbar-hidden`}
      >
        <div className=" py-5 min-h-[50vh] h-full bg-[#f6f5f5] bottom-0 left-0 rounded-tl-[20px] rounded-tr-[20px] flex flex-col overflow-y-scroll scrollbar-hidden">
          <div className=" px-4">
            <h1 className="text-[22px] font-medium text-[#111111] leading-[145%] tracking-[-1px] text-left">
              Choose Network
            </h1>
            {network && network?.length > 0 ? (
              network?.map((obj, index) => {
                return (
                  <div
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation();
                      setDropdown(!dropdown);
                      getaddreddData();
                      setData({ ...data, network: obj });
                    }}
                    className={`w-full ${
                      !obj?.IsWithdrawEnable
                        ? "cursor-not-allowed hover:bg-transparent"
                        : "cursor-pointer hover:bg-white"
                    } rounded-md border border-[#111]  p-1 text-left flex items-start justify-center flex-col mt-2 px-2 relative`}
                  >
                    <label className={`text-[#111] text-base opacity-100`}>
                      {obj?.chainName}
                    </label>
                    <label className={`text-[#111] text-xs opacity-70`}>
                      {getLocale("fee")} 0 {obj?.chainName}
                    </label>
                    <label className={`text-[#111] text-xs opacity-70`}>
                      {getLocale("minWithdraw")}10 {obj?.chainName}
                    </label>
                    <label className={`text-[#111] text-xs opacity-70`}>
                      {/* Arrival time = 2 mins */}
                      {getLocale("arrivalTime")} = 2 min
                    </label>
                    {!obj?.IsWithdrawEnable && (
                      <div className="absolute top-2 right-0 p-1 bg-red-400 text-white text-xs rounded-s-md border border-[#111]">
                        {/* Suspended */}
                        {getLocale("suspended")}
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="bg-transparent border border-gray-600 rounded-lg p-4 w-[90%] mx-auto text-black font-medium text-lg mt-6">
                {/* No any network found */}
                {getLocale("noNetwork")}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DepositCoin;
