import { IconSearch, IconX } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getTradingPair } from "../../service/api";
import actions from "../../redux/user/actions";
import { useDispatch } from "react-redux";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";

function Market() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [active, setActive] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [search, setSearch] = useState("");

  const getData = () => {
    setLoading(true);
    let params = {
      page: 1,
      sizePerPage: 100,
      pagination: false,
    };
    if (search) {
      params.search = search;
    }
    getTradingPair(params)
      ?.then((res) => {
        if (res?.status) {
          setData(res?.data?.result);
        } else {
          setData([]);
        }
      })
      .catch((e) => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const getApi = setTimeout(() => {
      getData();
    }, 500);

    return () => clearTimeout(getApi);
  }, [search]);

  return (
    <div>
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col ">
        <div>
          <p className="text-sm  text-[14px] leading-[145%] tracking-normal text-left text-[#707070]">
            {/* In the last 24 hours */}
            {getLocale("inLast")}
          </p>

          <div className=" flex w-full items-start gap-5  justify-between">
            <div>
              <h1 className=" text-[30px] font-medium leading-[100%] tracking-[-0.5px] text-[#11111] text-left">
                {/* Market grew */}
                {getLocale("marketGrew")}
              </h1>
              <h2 className=" text-[30px] font-medium leading-[100%] tracking-[-0.5px] text-[#3F845F] text-left">
                {" "}
                +4.19%
              </h2>
            </div>
            <div>
              {!isSearch ? (
                <div
                  onClick={() => setIsSearch(!isSearch)}
                  className=" w-[40px] cursor-pointer  h-[40px] rounded-full  border flex justify-center items-center "
                >
                  <IconSearch />
                </div>
              ) : (
                <div className="relative border border-[#111] rounded-lg px-3 max-w-30">
                  <input
                    className="w-full border-0 outline-none h-7 rounded-lg"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <IconX
                    className="absolute right-2 top-1.5 size-4 cursor-pointer"
                    onClick={() => {
                      setIsSearch(false);
                      setSearch("");
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          <div className=" flex items-center mt-3">
            {[
              getLocale("all"),
              getLocale("tradable"),
              getLocale("growing"),
              getLocale("shrinking"),
            ]?.map((item, index) => (
              <div
                key={index}
                g
                onClick={() => setActive(index)}
                className={`px-3 rounded-[20px] py-1 cursor-pointer ${
                  active === index ? "bg-[#F5F8FE]" : "bg-white"
                } sticky top-0 left-0`}
              >
                {item}
              </div>
            ))}
          </div>
          {!loading ? (
            data?.length > 0 ? (
              data.map((item, index) => {
                const change = item?.lastPrice
                  ? ((+item?.currentPrice - +item?.lastPrice) /
                      +item?.lastPrice) *
                    100
                  : 0;
                return (
                  <div
                    key={index}
                    onClick={() => {
                      dispatch(actions.setActivePair(item));
                      navigate(ROUTES.CRYPTO_INFO);
                    }}
                    className="rounded-md bg-opacity-10 w-[98%] px-4 relative cursor-pointer mt-2 flex items-center justify-center h-[74px] mx-auto active:bg-[#ccc] hover:bg-[#cbcbca] hover:bg-opacity-80"
                  >
                    <img
                      alt=""
                      src={item?.fromCoin?.image}
                      className="size-8 border-2 border-white rounded-full"
                    />
                    <img
                      alt=""
                      src={item?.toCoin?.image}
                      className="size-8 -ml-3 border-2 border-white rounded-full"
                    />
                    <div className="text-[#111] text-left ml-3 text-base mr-auto">
                      <h3 className="text-base">
                        <span className="font-semibold">
                          {item?.fromCoin?.name}
                        </span>
                        /{item?.toCoin?.name}
                      </h3>
                      <h6 className="text-sm opacity-60">
                        {" "}
                        <span className="font-semibold">
                          {item?.fromCoin?.symbol}
                        </span>
                        /{item?.toCoin?.symbol}
                      </h6>
                    </div>
                    <div className="text-right text-base ml-auto">
                      <h3 className="text-base">${item?.currentPrice}</h3>
                      <h6
                        className={`text-sm ${
                          change > 0
                            ? "text-green-500"
                            : change < 0
                            ? "text-red-500"
                            : "text-[#111]"
                        }`}
                      >
                        {change?.toFixed(2)}%
                      </h6>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="border border-[#111] mt-4 py-2 rounded-lg w-full text-[#111] font-medium text-base bg-transparent">
                {/* No pair found */}
                {getLocale("noPair")}
              </div>
            )
          ) : (
            Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className="rounded-md bg-gray-200 bg-opacity-10 w-[98%] px-4 mt-2 flex items-center justify-center h-[74px] mx-auto animate-pulse"
              >
                <div className="bg-gray-300 rounded-full w-10 h-10"></div>
                <div className="ml-3 flex flex-col flex-grow">
                  <div className="bg-gray-300 h-4 w-3/4 mb-2 rounded"></div>
                  <div className="bg-gray-300 h-3 w-1/2 rounded opacity-60"></div>
                </div>
                <div className="ml-auto flex flex-col items-end">
                  <div className="bg-gray-300 h-4 w-12 mb-2 rounded"></div>
                  <div className="bg-gray-300 h-3 w-8 rounded"></div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default Market;
