import {
  IconArrowNarrowLeft,
  IconArrowsSort,
  IconCreditCard,
} from "@tabler/icons-react";
import React, { useState } from "react";
import { getLocale } from "../../constants/language";

import { useNavigate } from "react-router-dom";
import frmimg from "../../Assets/image/From.png";
import toimg from "../../Assets/image/Crypto.png";
import { ROUTES } from "../../constants/routes";

function Convert() {
  const navigate = useNavigate();
  const [number, setNumber] = useState("1000");
  const [isInfo, setIsInfo] = useState(false);
  const [isInfo2, setIsInfo2] = useState(false);
  const handleClick = (value, index) => {
    if (index === 11) {
      let copyNumber = number;
      copyNumber = copyNumber.substring(0, number.length - 1);
      setNumber(copyNumber);
    } else {
      setNumber((prev) => prev + value);
    }
  };
  return (
    <div>
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 pt-12 pb-5 flex flex-col justify-between">
        <div>
          <div className=" flex justify-between items-center">
            <IconArrowNarrowLeft
              className="size-7 cursor-pointer"
              onClick={() => navigate(-1)}
            />
            <div>
              <h2 className=" font-semibold leading-[140%] tracking-[-1px] ">
                {/* Security Alert Settings */}
                {getLocale("secAlert")}
              </h2>
              <pc className="text-[16px] leading-[140%] text-[#707070] font-normal text-center">
                $0.00 {getLocale("available")}
              </pc>
            </div>
            <p></p>
          </div>
          <div className="mt-5 ">
            <div className=" flex justify-center items-center ">
              <div className=" flex justify-center items-center">
                <div>
                  <div className=" w-10 h-10 rounded-full border border-[#CFCFCF] flex justify-center items-center cursor-pointer shadow-sm ">
                    {/* Max */}
                    {getLocale("max")}
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  <h1 className="font-medium leading-[110%] tracking-[-1px] text-[56px] text-[#2752E7] ">
                    $
                  </h1>
                  <input
                    className=" outline-none border-none font-medium leading-[110%] w-[50%]  text-left tracking-[-1px] text-[56px] text-[#2752E7] "
                    type="text"
                    value={number}
                    name=""
                    id=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className=" mt-5">
            <div className="rounded-md border border-[#CFCFCF] bg-opacity-10 w-[98%] px-4 cursor-pointer mt-2 flex items-center justify-between h-[74px] mx-auto active:bg-[#ccc]">
              <div className="text-[#111]  text-base flex items-center gap-2">
                <div className="h-[50px] w-[50px] rounded-full border flex justify-center items-center">
                  <img className=" w-full h-full" src={frmimg} alt="" />
                </div>
                <div>
                  <h6 className="text-sm opacity-60 text-left">from</h6>
                  <h3 className="text-base text-left">USDT</h3>
                </div>
              </div>
              <div className="h-[40px] w-[40px] rounded-full border flex justify-center items-center">
                <IconArrowsSort />
              </div>
              <div className="text-[#111] text-left  text-base flex items-center gap-2">
                <div>
                  <h6 className="text-sm opacity-60">to</h6>
                  <h3 className="text-base">KMB</h3>
                </div>
                <div className="h-[50px] w-[50px] rounded-full border flex justify-center items-center">
                  <img className=" w-full h-full" src={toimg} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className=" mt-3">
            <div className="bg-white w-full ">
              <div className="grid grid-cols-3 gap-2">
                {[
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  ".",
                  "0",
                  <IconArrowNarrowLeft />,
                ].map((key, index) => (
                  <button
                    key={key}
                    onClick={() => handleClick(key, index)}
                    className=" text-black font-medium text-[28px] py-4 px-6 rounded-lg flex justify-center items-center active:bg-[#ccc] "
                  >
                    {key}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div>
            <button
              onClick={() => navigate(ROUTES.RECEIVE)}
              className="w-full p-4 bg-bgBlue text-white rounded-lg mt-2 text-base"
            >
              {/* Previsualize */}
              {getLocale("previsualize")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Convert;
