import React, { useState } from "react";
import { IconArrowLeft, IconEye, IconEyeOff, IconX } from "@tabler/icons-react";
import { CustomInput } from "../Core/input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { changePassword } from "../../service/api";
import { CircularProgress } from "@mui/material";
import { Danger, Success } from "../Core/Errors";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";

const validationSchema = yup.object().shape({
  oldPassword: yup.string().required("Old password is required"),
  password: yup.string().required("New password is required."),
});
const ChangePassword = () => {
  const { user, firebaseToken } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState("stepOne");
  const [passwordToggle, setPasswordToggle] = useState(true);
  const [passwordToggle2, setPasswordToggle2] = useState(true);
  const [loading, setLoading] = useState(false);
  const email = useLocation().state?.email;
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      changePassword(values)
        .then((res) => {
          setLoading(false);
          setLoading(false);
          Success(res?.message).then((_) => {
            navigate(ROUTES.PROFILE);
          });
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoading(false);
        })
        .finally((res) => {
          setLoading(false);
        });
    },
  });
  return (
    <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12">
      <IconX className="size-7 cursor-pointer" onClick={() => navigate(-1)} />
      <form className="mt-5" onSubmit={formik.handleSubmit}>
        <div className="flex items-start flex-col w-full mt-6">
          <h3 className="text-2xl font-medium text-textDefault">
            {/* Set your new password */}
            {getLocale("serNewPass")}
          </h3>

          <div className="form-control w-full mt-4">
            <label className="block text-left text-textDefault text-base font-medium pb-2">
              {/* Old Password */}
              {getLocale("oldPass")}
            </label>
            <div className="relative">
              <CustomInput
                type={!passwordToggle ? "text" : "password"}
                placeholder="XXXXXXX"
                className="w-full pr-10"
                name="oldPassword"
                id="oldPassword"
                value={formik?.values?.oldPassword}
                onChange={formik.handleChange}
                error={
                  formik.errors.oldPassword && formik.touched.oldPassword
                    ? true
                    : false
                }
              />
              {!passwordToggle ? (
                <IconEye
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer opacity-50"
                  onClick={() => setPasswordToggle(!passwordToggle)}
                />
              ) : (
                <IconEyeOff
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer opacity-50"
                  onClick={() => setPasswordToggle(!passwordToggle)}
                />
              )}
            </div>
          </div>
          <div className="form-control w-full mt-8">
            <label className="block text-left text-textDefault text-base font-medium pb-2">
              {/* New Password */}
              {getLocale("newPass")}
            </label>
            <div className="relative">
              <CustomInput
                type={!passwordToggle2 ? "text" : "password"}
                placeholder="XXXXXXX"
                className="w-full pr-10"
                name="password"
                id="password"
                value={formik?.values?.password}
                onChange={formik.handleChange}
                error={
                  formik.errors.password && formik.touched.password
                    ? true
                    : false
                }
              />
              {!passwordToggle2 ? (
                <IconEye
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer opacity-50"
                  onClick={() => setPasswordToggle2(!passwordToggle2)}
                />
              ) : (
                <IconEyeOff
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer opacity-50"
                  onClick={() => setPasswordToggle2(!passwordToggle2)}
                />
              )}
            </div>
          </div>
          {loading ? (
            <button
              disabled={loading}
              className="w-full p-4 bg-bgBlue text-white rounded-lg mt-10 text-base"
            >
              <CircularProgress size={20} color="white" thickness={6} />{" "}
            </button>
          ) : (
            <button
              type="submit"
              disabled={loading}
              className="w-full p-4 bg-bgBlue text-white rounded-lg mt-10 text-base"
            >
              {/* Submit */}
              {getLocale("submit")}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
