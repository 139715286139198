import { IconChevronLeft, IconLockFilled } from "@tabler/icons-react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import stepImage from "../../Assets/image/step3.png";
import cardImage from "../../Assets/image/securityCard.png";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";

function SecurityInfo() {
  const navigate = useNavigate();
  const email = useLocation().state?.email;

  return (
    <div>
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12">
        <div className=" flex justify-between items-center">
          <IconChevronLeft
            className="size-7 cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <img src={stepImage} alt="step-top" />
          <p></p>
        </div>
        <div className="flex items-start justify-center w-full mt-[50px]">
          <img src={cardImage} alt="card-image" />
        </div>
        <div className=" mt-9">
          <h2 className=" text-[22px] font-[700] text-left ">
            {/* Verify your identity */}
            {getLocale("verifyId")}
          </h2>
          <p className=" text-left text-[16px] font-normal space-x-[0.2px] mt-3">
            {/* To help protect you from fraud and identity theft, and to comply
            with federal regulations, we need the following information */}
            {getLocale("tohelp")}
          </p>

          <ul className=" text-left ml-8 mt-1">
            <li className=" list-disc">
              {/* Legal name, Address, CURP */}
              {getLocale("legalName")}
            </li>
            <li className=" list-disc">
              {/* Photo of an ID  */}
              {getLocale("photoID")}
            </li>
          </ul>
        </div>
        <div className=" mt-6">
          <div className=" flex items-center gap-4">
            <IconLockFilled size={50} />
            <p className=" text-left text-[14px]">
              {" "}
              {/* This information is used solely for identity verification and is
              transmitted securely using 128-bit encryption. */}
              {getLocale("secInfo")}
            </p>
          </div>
          <button
            onClick={() =>
              navigate(ROUTES.PERSONAL_INFO, { state: { email: email } })
            }
            className="w-full p-4 bg-bgBlue text-white rounded-lg mt-2 text-base"
          >
            {/* Continue */}
            {getLocale("continue")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SecurityInfo;
