import React from "react";
import { getLocale } from "../../constants/language";

import crypto from "../../Assets/image/Crypto.png";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

const wallets = [
  {
    name: "USD Coin",
    amount: "0.00",
    leftAmount: "0 USDC",
  },
  {
    name: "Polygon",
    amount: "13.00",
    leftAmount: "8.52889997 MATIC",
  },
  {
    name: "Ox",
    amount: "0.00",
    leftAmount: "0 ZRX",
  },
  {
    name: "Aave",
    amount: "0.00",
    leftAmount: "0 AAVE",
  },
  {
    name: "Algorand",
    amount: "0.00",
    leftAmount: "0 ALGO",
  },
];
function General() {
  const navigate = useNavigate();
  return (
    <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12">
      <div className=" mt-5 flex flex-col justify-start items-start">
        <p className="text-right text-[14px] leading-[145%] font-normal tracking-normal text-[#707070]">
          {/* Wallet balance */}
          {getLocale("walletBalance")}
        </p>
        <h1 className="text-right text-[30px] leading-[145%] font-normal tracking-[-0.5px] text-[#111111]">
          $13.84
        </h1>
      </div>

      <div className=" mt-5">
        {wallets?.map((wallet, index) => (
          <div
            onClick={() => navigate(-1)}
            className=" w-full py-3 flex justify-between items-center px-4 cursor-pointer transition-all rounded-md active:bg-[#ccc] "
          >
            <div className=" flex gap-3 items-center">
              <img
                src={crypto}
                className=" h-[50px] w-[50px] rounded-full"
                alt=""
              />
              <p className=" text-[#111111] leading-[140%] tracking-[0.02px] text-[16px]">
                {wallet?.name}
              </p>
            </div>
            <div>
              <p className="text-right text-[14px] leading-[145%] font-normal tracking-normal text-[#111111]">
                ${wallet?.amount}
              </p>
              <p className=" text-right text-[14px] leading-[145%] font-normal tracking-normal text-[#707070]">
                {wallet?.leftAmount}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default General;
