import {
  IconChevronRight,
  IconInfoCircle,
  IconRecharging,
  IconSearch,
} from "@tabler/icons-react";
import { getLocale } from "../../../constants/language";

import React, { useState } from "react";
const paymentMethods = [
  "All",
  "Lightning UPI",
  "UPI",
  "IMPS",
  "Bank Transfer (India)",
  "Digital eRupee",
  "Google Pay (GPay)",
  "IMPS-PAN",
  "International Wire",
  "Paytm",
  "PhonePe",
  "Skrill (Moneybookers)",
  "UPI-PAN",
  "Western Union",
];
function Filter({ selectedCurrency }) {
  const [tradableAds, setTradableAds] = useState(true);
  const [verifiedMerchantAds, setVerifiedMerchantAds] = useState(true);
  const [noVerificationAds, setNoVerificationAds] = useState(false);
  const [saveFilter, setSaveFilter] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(100);
  const [amount, setAmount] = useState(2000);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("All");

  function formatNumber(num) {
    if (num < 1000) {
      return num.toString(); // Return the number as a string if less than 1000
    } else {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "k"; // Convert to k notation, remove decimal if unnecessary
    }
  }

  return (
    <div className=" max-h-[100%] pb-12 overflow-y-auto ">
      <div className=" text-textPrime rounded-lg ">
        <div className="flex justify-between items-center px-4">
          <h2 className="text-xl font-bold">Filter</h2>
          <button className="text-sm text-gray-400"></button>
        </div>
        <div className="flex items-center justify-between pt-5 border-b px-4 pb-1">
          <span>
            {/* Save filter for next use */}
            {getLocale("saveFilter")}
            </span>
          <input
            type="checkbox"
            checked={saveFilter}
            onChange={() => setSaveFilter(!saveFilter)}
            className="toggle-checkbox"
          />
        </div>
        {/* Toggle buttons */}

        <div className=" px-4 flex items-center gap-1 mt-1">
          <p className="text-sm mb-2 block text-left text-textPrime opacity-50">
            {/* Add Type */}
            {getLocale("addType")}
          </p>
          <IconInfoCircle size={15} />
        </div>

        <div className="mt-4 space-y-4 px-4">
          <div className="flex items-center justify-between">
            <span>
              {/* Tradable Ads Only */}
              {getLocale("tradableAds")}
              </span>
            <input
              type="checkbox"
              checked={tradableAds}
              onChange={() => setTradableAds(!tradableAds)}
              className="toggle-checkbox"
            />
          </div>

          <div className="flex items-center justify-between">
            <span>
              {/* Verified Merchant Ads only */}
              {getLocale("vefifyAds")}
              </span>
            <input
              type="checkbox"
              checked={verifiedMerchantAds}
              onChange={() => setVerifiedMerchantAds(!verifiedMerchantAds)}
              className="toggle-checkbox"
            />
          </div>

          <div className="flex items-center justify-between">
            <span>
              {/* Ads With No Verification Required */}
              {getLocale("noVerifyAds")}
              </span>
            <input
              type="checkbox"
              checked={noVerificationAds}
              onChange={() => setNoVerificationAds(!noVerificationAds)}
              className="toggle-checkbox"
            />
          </div>
        </div>

        {/* Amount input */}
        <div className="mt-6 px-4 border-t pt-2">
          <label
            htmlFor="amount"
            className="text-sm mb-2 block text-left text-textPrime opacity-50"
          >
            {/* Amount */}
            {getLocale("amount")}
          </label>
          <div className=" flex justify-between items-center w-full ">
            <div className=" w-full bg-bgGray items-center rounded-md h-[40px] flex justify-between gap-1 px-2">
              <input
                value={selectedAmount}
                onChange={(e) => setSelectedAmount(e.target.value)}
                type="number"
                className=" w-full h-full border-none outline-none placeholder:opacity-[0.5] bg-transparent "
                placeholder="e.g. 200"
              />
              <p className=" text-textPrime tracking-[0.2px] leading-[145%] ">
                {selectedCurrency}
              </p>
            </div>
          </div>

          <div className=" flex items-center gap-2 mt-2 flex-wrap">
            {[100, 1000, 5000, 7000]?.map((item, index) => (
              <div
                onClick={() => setSelectedAmount(item)}
                className={` cursor-pointer ${
                  selectedAmount === item
                    ? "bg-bgGray text-textPrime "
                    : "bg-primBlue text-textWhite "
                } px-3 min-w-[85px]  rounded-md py-1`}
              >
                {formatNumber(item)}
              </div>
            ))}
          </div>
        </div>

        {/* Payment Time Limit */}
        <div className="mt-6 px-4 border-t pt-2">
          <label className="text-sm mb-2 gap-2 block text-left text-textPrime opacity-50">
            {/* Payment Time Limit (minutes) */}
            {getLocale("paymentTimeLimit")}({getLocale("minutes")})

          </label>
          <div className="flex items-center gap-2 mt-2 flex-wrap ">
            {["All", 15, 30, 45, 60, 120, 180, 240, 300, 360].map((time) => (
              <button
                key={time}
                className={` cursor-pointer ${
                  selectedAmount === time
                    ? "bg-bgGray text-textPrime "
                    : "bg-primBlue text-textWhite "
                } px-3 py-1 min-w-[85px]  rounded-md`}
              >
                {time}
              </button>
            ))}
          </div>
        </div>
        <div className=" px-4 mt-5 border-t py-2">
          <div className=" flex  bg-bgGray items-start gap-2 rounded-md py-2  px-2">
            <IconRecharging className="text-orange-600 mt-1" size={15} />
            <p className="text-[14px] text-left tracking-[0.2px] leading-[140%]">
              {/* Release the crypto automatically upon payment complition */}
              {getLocale("releaseCrypto")}
            </p>
          </div>
          <div className=" flex items-start gap-2 rounded-md py-2  px-2 justify-between">
            <div className=" flex items-center gap-1">
              <p className="text-[14px] text-left tracking-[0.2px] leading-[140%] opacity-50">
                {/* Payment Method(s) (1 selected) */}
                {getLocale("paymentMethod")}(1{getLocale("selected")})
                {" "}
              </p>
              <IconInfoCircle size={15} className=" text-textPrime" />
            </div>
            <div className=" flex gap-1 items-center cursor-pointer">
              <p className="text-[14px] text-left tracking-[0.2px] leading-[140%]">
                {/* All */}
                {getLocale("all")}
              </p>
              <IconChevronRight size={15} className=" text-textPrime" />
            </div>
          </div>
          <div className=" grid grid-cols-2 m-auto gap-1 mt-2 ">
            {paymentMethods?.map((item, index) => (
              <div
                onClick={() => setSelectedPaymentMethod(item)}
                key={`payMethod-${index}`}
                className={`text-center flex justify-center px-2 py-1 ${
                  selectedPaymentMethod === item
                    ? " bg-bgGray text-textPrime"
                    : "bg-bgBlue text-textWhite "
                } rounded-md cursor-pointer`}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
        <div className=" px-4 mt-5 border-t py-2">
          <div className=" flex items-start gap-2 rounded-md py-2  px-2 justify-between">
            <div className=" flex items-center gap-1">
              <p className="text-[14px] text-left tracking-[0.2px] leading-[140%] opacity-50">
                {/* Country/Region */}
                {getLocale("cr")}{" "}
              </p>
              <IconInfoCircle size={15} className=" text-textPrime" />
            </div>
            <div className=" flex gap-1 items-center cursor-pointer">
              <p className="text-[14px] text-left tracking-[0.2px] leading-[140%]">
                {/* All */}
                {getLocale("add")}
              </p>
              <IconChevronRight size={15} className=" text-textPrime" />
            </div>
          </div>

          <div className=" grid grid-cols-2 m-auto gap-1 mt-2 ">
            {paymentMethods?.map((item, index) => (
              <div
                onClick={() => setSelectedPaymentMethod(item)}
                key={`payMethod-${index}`}
                className={`text-center flex justify-center px-2 py-1 ${
                  selectedPaymentMethod === item
                    ? " bg-bgGray text-textPrime"
                    : "bg-bgBlue text-textWhite "
                } rounded-md cursor-pointer`}
              >
                {item}
              </div>
            ))}
          </div>
        </div>

        <div className=" px-4 mt-5 border-t py-2">
          <label
            htmlFor="amount"
            className="text-sm mb-2 block text-left text-textPrime opacity-50"
          >
            {/* Sort By */}
            {getLocale("shortBy")}
          </label>
          <div className="flex items-center justify-between cursor-pointer">
            <label htmlFor="price">Price</label>
            <label
              className="relative flex items-center cursor-pointer"
              for="html"
            >
              <input
                name="framework"
                type="radio"
                className="peer h-5 w-5 cursor-pointer appearance-none rounded-full border border-slate-300 checked:border-slate-400 transition-all"
                id="html"
              />
              <span className="absolute bg-slate-800 w-3 h-3 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></span>
            </label>
          </div>
          <div className="flex items-center justify-between cursor-pointer">
            <label htmlFor="trades">Trades</label>
            <label
              className="relative flex items-center cursor-pointer"
              for="html"
            >
              <input
                name="framework"
                type="radio"
                className="peer h-5 w-5 cursor-pointer appearance-none rounded-full border border-slate-300 checked:border-slate-400 transition-all"
                id="html"
              />
              <span className="absolute bg-slate-800 w-3 h-3 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></span>
            </label>
          </div>
        </div>

        {/* Reset and Confirm buttons */}
        <div className="flex justify-between mt-6 px-4  gap-1">
          <button className="w-1/2 bg-bgGray text-textPrime p-3 rounded-md">
            {/* Reset */}
            {getLocale("reset")}
          </button>
          <button className="w-1/2 bg-bgBlue text-white p-3 rounded-md">
            {/* Confirm */}
            {getLocale("confirm")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Filter;
