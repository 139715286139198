import React from "react";
import logo from "../../Assets/image/Group 10.png";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";
import { useSelector } from "react-redux";

const HomePage = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-gradient-to-b min-h-[100vh] from-bgBlue to-[#1E1E1E] w-full h-full relative">
      <img
        src={logo}
        alt=""
        className="absolute left-[50%] top-[40%] transform -translate-x-[50%] -translate-y-[40%] "
      />
      <div className="absolute bottom-[100px] w-full">
        <button
          onClick={() => navigate(ROUTES.REGISTER)}
          className="w-[90%] px-2 py-3 mx-auto bg-white rounded-lg text-textDefault text-lg font-[700]"
        >
          {getLocale("register")}
        </button>
        <button
          onClick={() => navigate(ROUTES.LOGIN)}
          className="w-[90%] px-2 py-3 mx-auto bg-bgBlue text-white rounded-lg mt-4 text-lg font-[700]"
        >
          {getLocale("login")}
        </button>
      </div>
    </div>
  );
};

export default HomePage;
