import { IconX } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { CustomInput } from "../Core/input";
import { getCoinNetwork, postWithdraw } from "../../service/api";
import { Danger } from "../Core/Errors";
import { getLocale } from "../../constants/language";

function WithdrawCoin() {
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState(true);
  const [loading, setLoading] = useState(false);
  const [network, setNetwork] = useState([]);
  const [data, setData] = useState({ address: "", network: "", amount: "" });
  const [dataErr, setDataErr] = useState({
    addressErr: false,
    networkErr: false,
    amountErr: false,
  });
  const { activeWithdrawCoin, user } = useSelector((state) => state?.user);

  const getNetwrokData = () => {
    getCoinNetwork({
      page: 1,
      sizePerPage: 100,
      pagination: false,
      coin: activeWithdrawCoin?.coin?._id,
    })
      .then((res) => {
        if (res?.status) {
          setNetwork(res?.data?.result[0]?.networks);
        }
      })
      .catch((e) => {});
  };

  const handleWithdraw = () => {
    if (!data?.network) {
      setDataErr({ ...dataErr, networkErr: true });
      return;
    } else if (!data?.address) {
      setDataErr({ ...dataErr, addressErr: true });
      return;
    } else if (!data?.amount || data?.amount === 0) {
      setDataErr({ ...dataErr, amountErr: true });
      return;
    }

    let body = {
      toAddress: data?.address,
      chain: data?.network?.chainName,
      coin: activeWithdrawCoin?.coin?._id,
      userId: user?._id,
      amount: data?.amount,
    };
    setLoading(true);
    postWithdraw(body)
      ?.then((res) => {
        if (res?.status) {
          navigate(ROUTES.WALLET);
        }
      })
      .catch((e) => {
        Danger(e?.message || e?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (activeWithdrawCoin) getNetwrokData();
  }, [activeWithdrawCoin]);

  return (
    <div
      onClick={() => setDropdown(false)}
      className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col w-full"
    >
      <div className=" flex justify-center items-center relative">
        <IconX
          className="size-7 cursor-pointer absolute left-0"
          onClick={() => navigate(-1)}
        />
        <label className={`text-[#111] text-base`}>
          {/* Send  */}
          {getLocale("send")}
          {activeWithdrawCoin?.coin?.name}
        </label>
      </div>
      <div className="mt-4">
        <div className="flex items-start justify-start gap-y-1 flex-col">
          <label className={`text-[#111] text-sm opacity-70`}>Address</label>
          <CustomInput
            type="text"
            placeholder="Address"
            error={dataErr?.addressErr}
            value={data?.address}
            onChange={(e) => {
              setData({ ...data, address: e.target.value });
              setDataErr({ ...dataErr, addressErr: false });
            }}
          />
        </div>
        <div className="flex items-start justify-start gap-y-1 mt-2 flex-col">
          <label className={`text-[#111] text-sm opacity-70`}>Network</label>
          <div className="relative w-full">
            <div
              className={`bg-transparent
               w-full h-full text-left cursor-pointer ${
                 data?.network ? "text-opacity-100" : "text-opacity-60"
               } block border-2 ${
                dataErr?.network ? "border-red-500" : "border-[#E5E5E5]"
              } rounded-md text-base font-proxima-regular px-5 py-3 placeholder:font-proxima-regular placeholder:text-base focus:outline-none text-black outline-none`}
              onClick={(e) => {
                e.stopPropagation();
                setDropdown(!dropdown);
              }}
            >
              {data?.network?.chainName || "Network"}
            </div>
            <span className="rotate-180 text-[#111] absolute right-3 top-2.5">
              ^
            </span>
          </div>
        </div>
        <div className="flex items-start justify-start gap-y-1 mt-2 flex-col">
          <label className={`text-[#111] text-sm opacity-70`}>
            {/* Withdrawal Amount */}
            {getLocale("withdrawalAmount")}
          </label>
          <div className="relative w-full">
            <CustomInput
              type="number"
              placeholder="Minimum 0"
              error={dataErr?.amountErr}
              value={data?.amount}
              onChange={(e) => {
                setData({ ...data, amount: e.target.value });
                setDataErr({ ...dataErr, amountErr: false });
              }}
            />
            <div className="absolute right-3 top-4 flex items-center justify-end gap-2">
              <span className="text-[#111] text-xs">
                {activeWithdrawCoin?.coin?.symbol}
              </span>
              <span
                className="text-[#779e4d] text-xs cursor-pointer"
                onClick={() =>
                  setData({ ...data, amount: activeWithdrawCoin?.amount })
                }
              >
                {/* Max */}
                {getLocale("max")}
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-start justify-between mt-2">
          <label className={`text-[#111] text-sm opacity-70`}>Available</label>
          <label className={`text-[#111] text-sm opacity-100`}>
            {activeWithdrawCoin?.amount} {activeWithdrawCoin?.coin?.symbol}
          </label>
        </div>
        <div className="flex items-center justify-between mt-48">
          <div className="flex items-start justify-between gap-y-0.5 flex-col mt-2">
            <label className={`text-[#111] text-xs opacity-70`}>
              {/* Receive amount */}
              {getLocale("reciveAmount")}
            </label>
            <label className={`text-[#111] text-xs opacity-100`}>
              0.00 {""}
              {activeWithdrawCoin?.coin?.symbol}
            </label>
            <label className={`text-[#111] text-xs opacity-70`}>
              {getLocale("networkFee")} 0.00 {""}
              {activeWithdrawCoin?.coin?.symbol}
            </label>
          </div>
          <button
            onClick={handleWithdraw}
            disabled={loading}
            className="rounded-md bg-[#111] hover:bg-opacity-85 px-3 py-1 text-white text-base font-semibold"
          >
            {loading ? "Sending request..." : "Withdraw"}
          </button>
        </div>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          // setDropdown(!dropdown);
        }}
        className={`${
          dropdown ? "h-[80vh] min-h-[60vh]" : "h-0"
        } transition-all w-full rounded-2xl bg-slate-300 border-t-2 absolute left-0 bottom-0  flex justify-end flex-col overflow-hidden scrollbar-hidden`}
      >
        <div className=" py-5 h-full bg-[#f6f5f5] bottom-0 left-0 rounded-tl-[20px] rounded-tr-[20px] flex flex-col overflow-y-scroll scrollbar-hidden">
          <div className=" px-4">
            <h1 className="text-[22px] font-medium text-[#111111] leading-[145%] tracking-[-1px] text-left">
              {/* Choose Network */}
              {getLocale("chooseNetwork")}
            </h1>
            {network && network?.length > 0 ? (
              network?.map((obj, index) => {
                return (
                  <div
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation();
                      setDropdown(!dropdown);
                      setData({ ...data, network: obj });
                    }}
                    className={`w-full ${
                      !obj?.IsWithdrawEnable
                        ? "cursor-not-allowed hover:bg-transparent"
                        : "cursor-pointer hover:bg-white"
                    } rounded-md border border-[#111]  p-1 text-left flex items-start justify-center flex-col mt-2 px-2 relative`}
                  >
                    <label className={`text-[#111] text-base opacity-100`}>
                      {obj?.chainName}
                    </label>
                    <label className={`text-[#111] text-xs opacity-70`}>
                      {/* Fee*/} {getLocale("fee")} 0 {obj?.chainName}
                    </label>
                    <label className={`text-[#111] text-xs opacity-70`}>
                      {getLocale("minWithdraw")} 10 {obj?.chainName}
                    </label>
                    <label className={`text-[#111] text-xs opacity-70`}>
                      {getLocale("arrivalTime")} = 2 mins
                    </label>
                    {!obj?.IsWithdrawEnable && (
                      <div className="absolute top-2 right-0 p-1 bg-red-400 text-white text-xs rounded-s-md border border-[#111]">
                        {/* Suspended */}
                        {getLocale("suspended")}
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div
                // key={index}
                // onClick={(e) => {
                //   e.stopPropagation();
                //   setDropdown(!dropdown);
                //   setData({ ...data, network: obj });
                // }}
                className="w-full cursor-pointer rounded-md border border-[#111] hover:bg-white p-1 text-left flex items-start justify-center flex-col mt-2 px-2"
              >
                <label className={`text-[#111] text-base opacity-100`}>
                  {/* No network available */}
                  {getLocale("noNetAvailable")}
                </label>
                {/* <label className={`text-[#111] text-xs opacity-70`}>
                Fee 0 {obj?.chainName}
              </label>
              <label className={`text-[#111] text-xs opacity-70`}>
                Minimum Withdrawal 10 {obj?.chainName}
              </label>
              <label className={`text-[#111] text-xs opacity-70`}>
                Arrival time = 2 mins
              </label> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WithdrawCoin;
