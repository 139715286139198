import { IconChevronLeft, IconLockFilled, IconX } from "@tabler/icons-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import stepImage from "../../Assets/image/step1Complete.png";
import cardImage from "../../Assets/image/SecureAccount.png";
import { resendEmail } from "../../service/api";
import { Danger, Success } from "../Core/Errors";
import store from "../../redux/store";
import Loader from "../../components/Core/Loader";
import { ROUTES } from "../../constants/routes";
import { useSelector } from "react-redux";
import { getLocale } from "../../constants/language";

function SecuritySteps() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const handleEmailVerify = () => {
    try {
      setLoading(true);
      resendEmail({ email: store.getState().user?.user?.email })
        .then((res) => {
          if (res?.status) {
            setLoading(false);
            Success(res?.message).then((_) => {
              navigate(ROUTES.CONFIRM_TWO_FA, {
                state: { email: store.getState().user?.user?.email },
              });
            });
          } else {
            Danger(res?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
        })
        .finally((res) => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div>
      {loading && <Loader />}
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col justify-between">
        <div>
          <div className=" flex justify-between items-center">
            <IconX
              className="size-7 cursor-pointer"
              onClick={() => navigate(-1)}
            />
            <img src={stepImage} alt="step-top" />
            <p></p>
          </div>
          <div className="flex items-start justify-center w-full mt-[50px]">
            <img src={cardImage} alt="card-image" />
          </div>
          <div className=" mt-9">
            <h2 className=" text-[22px] font-[700] text-center leading-[145%] tracking-[-1px] ">
              {/* Steps to secure your account */}
              {getLocale("steps")}
            </h2>
            <div className=" mt-5">
              <div className="flex justify-between items-center mt-5 px-2 py-1 rounded-md cursor-pointer">
                <div className=" flex items-center gap-4">
                  <div className="w-7 h-7 rounded-full bg-bgBlue text-white flex justify-center items-center">
                    1
                  </div>
                  <h2 className=" text-[18px] font-medium leading-[145%] tracking-[-1px]">
                    {/* Create an account */}
                    {getLocale("step1")}
                  </h2>
                </div>
                <p>
                  {/* Completed */}
                  {getLocale("completed")}
                </p>
              </div>
              <div
                onClick={() => {
                  if (!user?.isEmailVerified) {
                    navigate(ROUTES.EMAIL_VERIFIY);
                  }
                }}
                className="flex justify-between items-center mt-5 px-2 py-1 rounded-md cursor-pointer"
              >
                <div className=" flex items-center gap-4">
                  <div
                    className={`w-7 h-7 rounded-full ${
                      user?.isEmailVerified
                        ? "bg-bgBlue text-white"
                        : "text-[#707070] bg-[#F3F3F3]"
                    } flex justify-center items-center`}
                  >
                    2
                  </div>
                  <div className=" w-[70%]">
                    <h2 className=" text-[18px] font-medium leading-[145%] tracking-[-1px] text-left">
                      {/* Email verification */}
                      {getLocale("step4")}
                    </h2>
                    <p className=" text-left text-[#707070] text-[16px] font-normal tracking-[0.02px] leading-[140%] w-full">
                      {/* Accede al link que enviamos a tu correo */}
                      {getLocale("step4Info")}
                    </p>
                  </div>
                </div>
                <p>{user?.isEmailVerified ? "Completed" : "Pending"}</p>
              </div>
              <div
                onClick={() => {
                  if (!(user?.isEmailVerified && user.isMobileVerified)) {
                    navigate(ROUTES.SECURITY);
                  }
                }}
                className="flex justify-between items-center mt-5 px-2 py-1 rounded-md cursor-pointer"
              >
                <div className=" flex items-center gap-4">
                  <div
                    className={`w-7 h-7 rounded-full ${
                      user?.isMobileVerified
                        ? "bg-bgBlue text-white"
                        : "text-[#707070] bg-[#F3F3F3]"
                    } flex justify-center items-center`}
                  >
                    3
                  </div>
                  <div>
                    <h2 className=" text-[18px] font-medium leading-[145%] tracking-[-1px] text-left">
                      {/* Secure your account */}
                      {getLocale("secAccount")}
                    </h2>
                    <p className=" text-left text-[#707070] text-[16px] font-normal tracking-[0.02px] leading-[140%]">
                      {/* Verificación 2FA */}
                      {getLocale("step2Info")}
                    </p>
                  </div>
                </div>
                <p>{user?.isMobileVerified ? "Completed" : "Pending"}</p>
              </div>
              <div className="flex justify-between items-center mt-5 px-2 py-1 rounded-md cursor-pointer">
                <div className=" flex items-center gap-4">
                  <div
                    className={`w-7 h-7 rounded-full ${
                      user?.kycStatus !== "PENDING" ||
                      user?.kycStatus !== "REJECTED"
                        ? "bg-bgBlue text-white"
                        : "text-[#707070] bg-[#F3F3F3]"
                    } flex justify-center items-center`}
                  >
                    4
                  </div>
                  <div className=" w-[70%]">
                    <h2 className=" text-[18px] font-medium leading-[145%] tracking-[-1px] text-left">
                      {/* Verify your identity */}
                      {getLocale("verifyId")}
                    </h2>
                    <p className=" text-left text-[#707070] text-[16px] font-normal tracking-[0.02px] leading-[140%] w-[100%]">
                      {/* Requerido por regulaciones financieras */}
                      {getLocale("step3Info")}
                    </p>
                  </div>
                </div>
                <p>{user?.kycStatus}</p>
              </div>
            </div>
          </div>
        </div>
        <div className=" mt-6">
          <button
            onClick={() => handleEmailVerify()}
            className="w-full p-4 bg-bgBlue text-white rounded-lg mt-2 text-base"
          >
            {/* Secure my account */}
            {getLocale("secMyAccount")}
          </button>
          <button
            onClick={() => navigate(ROUTES.PROFILE)}
            className="w-full p-4 bg-white text-[#111111] rounded-lg mt-2 text-base border border-[#CFCFCF]"
          >
            {/* Skip */}
            {getLocale("skip")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SecuritySteps;
