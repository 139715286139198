const actions = {
  SET_USER: "SET_USER",
  SET_TOKEN: "SET_TOKEN",
  SET_LOADING: "SET_LOADING",
  SET_LOGGEDIN: "SET_LOGGEDIN",
  SET_FIREBASE_TOKEN: "SET_FIREBASE_TOKEN",
  SET_SELECTED_COIN: "SET_SELECTED_COIN",
  SET_SELECTED_CURRENCY: "SET_SELECTED_CURRENCY",
  SET_P2P_PAYLOAD: "SET_P2P_PAYLOAD",
  REMOVE_P2P_PAYLOAD: "REMOVE_P2P_PAYLOAD",
  SET_P2P_FROM_STEP: "SET_P2P_FROM_STEP",
  SET_P2P_ORDER_FORM: "SET_P2P_ORDER_FORM",
  REMOVE_P2P_ORDER_FORM: "REMOVE_P2P_ORDER_FORM",
  ACTIVE_PAIR: "ACTIVE_PAIR",
  ACTIVE_COIN: "ACTIVE_COIN",
  ADD_CHAT_ID: "ADD_CHAT_ID",
  REMOVE_CHAT_ID: "REMOVE_CHAT_ID",
  ACTIVE_WITHDRAW_COIN: "ACTIVE_WITHDRAW_COIN",
  SET_LANGUAGE: "SET_LANGUAGE",
  addChatId: (data) => {
    return {
      type: actions.ADD_CHAT_ID,
      data,
    };
  },
  removeChatId: (data) => {
    return {
      type: actions.REMOVE_CHAT_ID,
      data,
    };
  },
  setP2POrder: (data) => {
    return {
      type: actions.SET_P2P_ORDER_FORM,
      data,
    };
  },
  removeP2POrder: (data) => {
    return {
      type: actions.REMOVE_P2P_ORDER_FORM,
      data,
    };
  },
  setP2PFromStep: (data) => {
    return {
      type: actions.SET_P2P_FROM_STEP,
      data,
    };
  },
  setP2PPaylaod: (data) => {
    return {
      type: actions.SET_P2P_PAYLOAD,
      data,
    };
  },
  removeP2PPaylaod: (data) => {
    return {
      type: actions.REMOVE_P2P_PAYLOAD,
      data,
    };
  },
  setSelectedCurrency: (data) => {
    return {
      type: actions.SET_SELECTED_CURRENCY,
      data,
    };
  },
  setSelectedCoin: (data) => {
    console.log("data: ", data);
    return {
      type: actions.SET_SELECTED_COIN,
      data,
    };
  },
  setUser: (data) => {
    return {
      type: actions.SET_USER,
      data,
    };
  },
  setToken: (data) => {
    return {
      type: actions.SET_TOKEN,
      data,
    };
  },
  setLoading: (data) => {
    return {
      type: actions.SET_LOADING,
      data,
    };
  },
  setLoggedIn: (data) => {
    return {
      type: actions.SET_LOGGEDIN,
      data,
    };
  },
  setActivePair: (data) => {
    return {
      type: actions.ACTIVE_PAIR,
      data,
    };
  },
  setActiveCoin: (data) => {
    return {
      type: actions.ACTIVE_COIN,
      data,
    };
  },
  setActiveWithdrawCoin: (data) => {
    return {
      type: actions.ACTIVE_WITHDRAW_COIN,
      data,
    };
  },
  setFirebaseToken: (data) => {
    return {
      type: actions.SET_FIREBASE_TOKEN,
      data,
    };
  },
  setLangauge: (data) => {
    return {
      type: actions.SET_LANGUAGE,
      data,
    };
  },
};

export default actions;
