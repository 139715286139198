import { IconShieldCode, IconUpload, IconX } from "@tabler/icons-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

function ChooseMethod() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  return (
    <div>
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col justify-between">
        <div>
          <div className=" flex justify-between items-center">
            <h2 className=" text-[20px] font-[700] text-left ">
              Document Verification
            </h2>
            <IconX
              className="size-6 cursor-pointer transition-transform duration-300 hover:rotate-90"
              onClick={() => navigate(-1)}
            />
          </div>

          <div className="mt-4">
            <label className="block text-left text-textDefault text-base font-medium pb-2">
              Choose an upload method
            </label>
            <div
              onClick={() => navigate(ROUTES.S_PHOTO_ID)}
              className={` flex justify-between items-center mt-5 cursor-pointer border border-[#d0cece] active:bg-[#ccc] hover:bg-[#d3d3d3] min-h-[50px] px-2 rounded-md`}
            >
              <div className=" flex items-center gap-2">
                <IconUpload />
                <p className=" text-[16px] font-normal space-x-[0.2px] text-[#111111] ">
                  Upload File
                </p>
              </div>
            </div>
          </div>
          <div className="absolute bottom-10 pt-6 flex items-center justify-center gap-2 border-t w-[395px] text-base h-4">
            <IconShieldCode />
            <label>Your personal information is encrypted.</label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseMethod;
