import React, { useEffect, useState } from "react";
import { IconEye, IconEyeOff, IconX } from "@tabler/icons-react";
import { CustomInput } from "../Core/input";
import { useLocation, useNavigate } from "react-router-dom";
import stepImage from "../../Assets/image/step3start.png";
import { useFormik } from "formik";
import { Danger, Success } from "../Core/Errors";
import * as yup from "yup";
import { addKycInfo, getUserProfile } from "../../service/api";
import Loader from "../Core/Loader";
import { ROUTES } from "../../constants/routes";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { countries } from "../Core/Countries";
import { getLocale } from "../../constants/language";

const validationSchema = yup.object().shape({
  firstName: yup.string().required("FirstName is required."),
  lastName: yup.string().required("LastName is required."),
  dob: yup.string().required("Dob is required."),
});

const PersonalInfo = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [nationality, setNationality] = useState(countries[155]);
  const [passwordToggle, setPasswordToggle] = useState(false);
  const email = useLocation().state?.email;
  const formik = useFormik({
    initialValues: {
      ...userInfo,
      // dob: "",
      // nationality: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      let body = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        dob: values?.dob,
        nationality: values?.nationality?.code,
      };

      try {
        addKycInfo(body)
          .then((res) => {
            if (res?.status) {
              Success("KYC Process Initiated Successfully").then((_) => {
                navigate(ROUTES.S_PHOTO_ID, { state: { email: email } });
              });
            } else {
              Danger(res?.message);
            }
          })
          .catch((error) => {
            setLoading(false);
          })
          .finally((res) => {
            setLoading(false);
          });
      } catch (error) {}
    },
  });

  const getProfile = () => {
    try {
      getUserProfile()
        .then((res) => {
          let nation = countries?.find(
            (obj) => obj?.code === res?.data?.kyc?.nationality
          );
          setNationality(nation);
          // formik?.setFieldValue("dob", res?.data?.kyc?.dob);
          setUserInfo({
            nationality: nation?.code,
            firstName: res?.data?.kyc?.firstName,
            lastName: res?.data?.kyc?.lastName,
            dob: res?.data?.kyc?.dob,
          });
        })
        .catch((error) => {});
    } catch (error) {}
  };
  useEffect(() => {
    getProfile();
  }, []);
  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className="h-full min-h-[100vh] relative bg-white font-sans p-4 flex flex-col justify-between"
      >
        <div>
          <div className=" flex justify-between items-center">
            <h2 className=" text-[22px] font-[700] text-textDefault text-left ">
              {getLocale("enterPersonalInfo")}
              {/* Personal Information */}
            </h2>
            <IconX
              className="size-6 cursor-pointer transition-transform duration-300 hover:rotate-90"
              onClick={() => navigate(-1)}
            />
          </div>
          <div className="flex items-start flex-col w-full mt-6">
            <h3 className="text-base text-left text-textDefault font-[700]">
              {getLocale("valid")}
            </h3>

            <div className="form-control w-full mt-8">
              {/* <label className="block text-left text-textDefault text-base font-medium pb-2">
              
              {getLocale("names")}
            </label> */}
              <CustomInput
                type="text"
                placeholder={getLocale("firstName")}
                name="firstName"
                id="firstName"
                value={formik?.values?.firstName || ""}
                onChange={formik.handleChange}
                error={
                  formik.errors.firstName && formik.touched.firstName
                    ? true
                    : false
                }
              />
            </div>
            <div className="form-control w-full mt-4">
              {/* <label className="block text-left text-textDefault text-base font-medium pb-2">
              
              {getLocale("lastName")}
            </label> */}
              <CustomInput
                type="text"
                placeholder={getLocale("lastName")}
                name="lastName"
                id="lastName"
                value={formik?.values?.lastName || ""}
                onChange={formik.handleChange}
                error={
                  formik.errors.lastName && formik.touched.lastName
                    ? true
                    : false
                }
              />
            </div>
            <div className="form-control w-full mt-4">
              {/* <label className="block text-left text-textDefault text-base font-medium pb-2">
             
              {getLocale("dob")}
            </label> */}
              <CustomInput
                type="date"
                label={getLocale("dob") / "DD/MM/YYYY"}
                name="dob"
                id="dob"
                value={formik?.values?.dob || ""}
                onChange={formik.handleChange}
                error={formik.errors.dob && formik.touched.dob ? true : false}
              />
            </div>
          </div>
          {loading ? (
            <button
              disabled={loading}
              className="w-[100px] float-right ml-auto p-2 bg-bgBlue text-white rounded-full mt-5 text-base"
            >
              <CircularProgress size={20} color="white" thickness={6} />
            </button>
          ) : (
            <div className="flex justify-end items-center gap-2">
              {" "}
              <button
                onClick={() => {
                  navigate(-1);
                }}
                disabled={loading}
                type="button"
                className="w-[100px] float-right p-2 bg-bgBlue text-white rounded-full mt-5 text-base font-[700]"
              >
                {getLocale("prev")}
              </button>
              <button
                // onClick={chooseIdType}
                disabled={loading}
                type="submit"
                className="w-[100px] float-right p-2 bg-bgBlue text-white rounded-full mt-5 text-base font-[700]"
              >
                {getLocale("continue")}
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default PersonalInfo;
