import { useEffect } from "react";
import "./App.css";
import Routers from "./Routes/Routers";
import { getUserProfile } from "./service/api";
import { useNavigate } from "react-router-dom";
import { TourProvider } from "@reactour/tour";
import steps, { styles } from "./components/pages/steps";

function App() {
  const navigate = useNavigate();
  // const getProfile = () => {
  //   try {
  //     getUserProfile()
  //       .then((res) => {})
  //       .catch((error) => {
  //         console.log("error: ", error.status);
  //         if (
  //           error.status === 403 ||
  //           error?.responce?.data?.message === "Invalid token or expired!"
  //         ) {
  //           navigate("/login");
  //         }
  //       });
  //   } catch (error) {

  //   }
  // };

  // useEffect(() => {
  //   getProfile();
  // }, []);
  return (
    <div className="App !bg-[#101010]">
      <TourProvider
        steps={steps}
        styles={styles}
        showNavigation={false}
        showBadge={false}
      >
        <Routers />
      </TourProvider>
    </div>
  );
}

export default App;
