const SERVER_URL =
  "https://kmbio-latest-a3ehfzcxc4h9dkau.japanwest-01.azurewebsites.net";
// "https://qnk3c0s0-8080.inc1.devtunnels.ms";
// "http://192.168.29.124:8080";
// "https://zbdh64rq-8080.inc1.devtunnels.ms";
// "http://kmbio-latest-a3ehfzcxc4h9dkau.japanwest-01.azurewebsites.net"/

const PUBLIC_LOCALE = "en";

// const PUBLIC_LOCALE = store.getState().user?.language
module.exports = {
  SERVER_URL,
  PUBLIC_LOCALE,
};
