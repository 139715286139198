import React from "react";

import { useNavigate } from "react-router-dom";
import { IconChevronLeft } from "@tabler/icons-react";
import stepImage from "../../Assets/image/onbrdstep.png";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";


const Onboarding = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-gradient-to-b min-h-[100vh] from-bgBlue to-[#1E1E1E] w-full h-full px-4 py-12 flex flex-col justify-between">
      <div>
        <div className=" flex justify-between items-center">
          <IconChevronLeft
            className="size-7 cursor-pointer text-white"
            onClick={() => navigate(ROUTES.REGISTER)}
          />
          <img src={stepImage} alt="step-top" />
          <p></p>
        </div>
      </div>
      <div className=" mt-[100px]">
        <h2 className=" text-[32px] text-left text-white space-x-[-1px] font-medium leading-[100%] ">
          {/* Ready to start a new financial journey? */}
          {getLocale("onboarding")}
        </h2>
      </div>
      <button
        onClick={() => navigate(ROUTES.ONBOARDING_1)}
        className="w-full p-4 bg-white text-[#111111] tracking-normal leading-[140%] rounded-lg mt-2 text-base"
      >
        {/* Start with KMBIO */}
        {getLocale("startWith")}
      </button>
    </div>
  );
};

export default Onboarding;
