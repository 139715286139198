import React, { useState } from "react";
import {
  IconChevronLeft,
  IconChevronRight,
  IconEye,
  IconEyeOff,
  IconLockFilled,
  IconX,
} from "@tabler/icons-react";
import { CustomInput } from "../Core/input";
// import { getLocale } from "../../constants/language";

import { useNavigate } from "react-router-dom";
import stepImage from "../../Assets/image/step3start.png";
import { useFormik } from "formik";
import { Danger, Success } from "../Core/Errors";
import * as yup from "yup";
import { addKycAddress } from "../../service/api";
import Loader from "../Core/Loader";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";

const validationSchema = yup.object().shape({
  addressLine1: yup.string().required("address Line 1 is required."),
  addressLine2: yup.string().required("address Line 2 is required."),
  city: yup.string().required("City is required."),
  state: yup.string().required("State is required."),
  pinCode: yup.string().required("Pincode is required."),
});
const Address = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [manual, setManual] = useState(false);
  const formik = useFormik({
    initialValues: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      pinCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      try {
        addKycAddress({ address: values })
          .then((res) => {
            if (res?.status) {
              Success(res?.message).then((_) => {
                navigate(ROUTES.PURPOSE);
              });
            } else {
              Danger(res?.message);
            }
          })
          .catch((error) => {
            setLoading(false);
          })
          .finally((res) => {
            setLoading(false);
          });
      } catch (error) {}
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="h-full min-h-[100vh] relative bg-white font-sans py-12 flex flex-col justify-between"
    >
      {loading && <Loader />}
      <div>
        <div className=" flex justify-between items-center px-4">
          <IconChevronLeft
            className="size-7 cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <img src={stepImage} alt="step-top" />
          <p></p>
        </div>

        <div className="flex items-start flex-col w-full mt-6 border-b border-b-[#F3F3F3]">
          <div className="px-4">
            <h3 className="text-2xl font-medium text-left ">
              {/* Why are you using K-MBIO HX? */}
              {getLocale("whyKMBIO")}
            </h3>
            <p className="text-[#707070] text-[16px] space-x-[0.02px] text-left">
              {/* This question will help us greatly in improving our service. */}
              {getLocale("whyKMBIOInfo")}
            </p>
          </div>
          {manual ? (
            <div className="form-control w-full mt-[50px] px-4">
              <div>
                <label className="block text-left text-[#111111] text-base font-medium pb-2">
                  {/* Address Line 1 */}
                  {getLocale("addressLine1")}
                </label>
                <CustomInput
                  placeholder="Address One"
                  type="text"
                  name="addressLine1"
                  id="addressLine1"
                  value={formik?.values?.addressLine1}
                  onChange={formik.handleChange}
                  error={
                    formik.errors.addressLine1 && formik.touched.addressLine1
                      ? true
                      : false
                  }
                />
              </div>
              <div className=" mt-3">
                <label className="block text-left text-[#111111] text-base font-medium pb-2">
                  {/* Address Line 2 */}
                  {getLocale("addressLine2")}
                </label>
                <CustomInput
                  placeholder="Address Two"
                  type="text"
                  name="addressLine2"
                  id="addressLine2"
                  value={formik?.values?.addressLine2}
                  onChange={formik.handleChange}
                  error={
                    formik.errors.addressLine2 && formik.touched.addressLine2
                      ? true
                      : false
                  }
                />
              </div>
              <div className=" mt-3">
                <label className="block text-left text-[#111111] text-base font-medium pb-2">
                  {/* City */}
                  {getLocale("city")}
                </label>
                <CustomInput
                  placeholder="City"
                  type="text"
                  name="city"
                  id="city"
                  value={formik?.values?.city}
                  onChange={formik.handleChange}
                  error={
                    formik.errors.city && formik.touched.city ? true : false
                  }
                />
              </div>
              <div className=" mt-3">
                <label className="block text-left text-[#111111] text-base font-medium pb-2">
                  {/* State */}
                  {getLocale("state")}
                </label>
                <CustomInput
                  placeholder="State"
                  type="text"
                  name="state"
                  id="state"
                  value={formik?.values?.state}
                  onChange={formik.handleChange}
                  error={
                    formik.errors.state && formik.touched.state ? true : false
                  }
                />
              </div>
              <div className=" mt-3">
                <label className="block text-left text-[#111111] text-base font-medium pb-2">
                  {/* Pin Code */}
                  {getLocale("pincode")}
                </label>
                <CustomInput
                  placeholder="Pin Code"
                  type="text"
                  name="pinCode"
                  id="pinCode"
                  value={formik?.values?.pinCode}
                  onChange={formik.handleChange}
                  error={
                    formik.errors.pinCode && formik.touched.pinCode
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          ) : (
            <div className="form-control w-full mt-[50px] px-4">
              <label className="block text-left text-[#2752E7] text-base font-medium pb-2">
                {/* Search for your address */}
                {getLocale("searchAddress")}
              </label>
              <CustomInput placeholder="Search Address" />
            </div>
          )}
          <div className=" px-4 w-full mb-5">
            {!manual ? (
              <div
                onClick={() => setManual(true)}
                className=" mt-[40px] flex justify-between items-center w-full py-2 active:bg-[#ccc] cursor-pointer rounded-md"
              >
                <p className=" text-[16px] leading-[140%] tracking-[0.2px] font-normal text-[#111111]  pl-2 ">
                  {/* Enter address manually */}
                  {getLocale("manual")}
                </p>
                <IconChevronRight className="size-7 cursor-pointer" />
              </div>
            ) : (
              <div
                onClick={() => {
                  formik.resetForm();
                  setManual(false);
                }}
                className=" mt-[40px] flex justify-between items-center w-full py-2 active:bg-[#ccc] cursor-pointer rounded-md"
              >
                <p className=" text-[16px] leading-[140%] tracking-[0.2px] font-normal text-[#111111]  pl-2 ">
                  {/* Search address */}
                  {getLocale("searchAddress")}
                </p>
                <IconChevronRight className="size-7 cursor-pointer" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="px-4">
        <div>
          <div className=" flex items-center gap-4">
            <IconLockFilled size={50} />
            <p className=" text-[#707070] text-left mt-4">
              {/* We use 128-bit encryption for added security, and this information
              is used solely for identity verification purposes. */}
              {getLocale("personalInfo")}
            </p>
          </div>
        </div>
        <button
          type="submit"
          className="w-full p-4 bg-bgBlue text-white rounded-lg mt-10 text-base"
        >
          {/* Continue */}
          {getLocale("continue")}
        </button>
      </div>
    </form>
  );
};

export default Address;
