import React, { useState } from "react";
import { IconX } from "@tabler/icons-react";
import { CustomInput } from "../Core/input";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { getLocale } from "../../constants/language";

import * as yup from "yup";
import { addEmail, resendEmail, verifyEmail } from "../../service/api";
import { CircularProgress } from "@mui/material";
import { Danger, Success } from "../Core/Errors";
import { ROUTES } from "../../constants/routes";

const validationSchema = yup.object().shape({
  email: yup.string().required("Phone Number is required."),
});
const VerifyEmail = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try {
        setLoading(true);
        addEmail(values)
          .then((res) => {
            if (res?.status) {
              setLoading(false);
              const redirect =
                search === "?forgot"
                  ? `${ROUTES.CONFIRM_TWO_FA}?forgot`
                  : `${ROUTES.CONFIRM_TWO_FA}`;
              Success(res?.message).then((_) => {
                navigate(redirect, {
                  state: { email: values.email, isForgot: true },
                });
              });
            } else {
              Danger(res?.message);
            }
          })
          .catch((error) => {
            Danger(error?.response?.data?.message);

            setLoading(false);
          })
          .finally((res) => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    },
  });
  return (
    <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12">
      <IconX className="size-7 cursor-pointer" onClick={() => navigate(-1)} />

      <form className="mt-5" onSubmit={formik.handleSubmit}>
        <div className="flex items-start flex-col w-full mt-6">
          <h3 className="text-2xl font-medium text-textDefault">
            Verify Your Email First
          </h3>
          <div className="form-control w-full mt-8">
            {/* <label className="block text-left text-textDefault text-base font-medium pb-2">
            
              {getLocale("email")}
            </label> */}
            <CustomInput
              placeholder={getLocale("email")}
              name="email"
              id="email"
              type="email"
              value={formik?.values?.email}
              onChange={formik.handleChange}
              error={formik.errors.email && formik.touched.email ? true : false}
            />
          </div>
          {loading ? (
            <button
              disabled={loading}
              className="w-full p-4 bg-bgBlue text-white rounded-lg mt-10 text-base"
            >
              <CircularProgress size={20} color="white" thickness={6} />{" "}
            </button>
          ) : (
            <button
              type="submit"
              disabled={loading}
              className="w-full p-4 bg-bgBlue text-white rounded-lg mt-10 text-base"
            >
              {/* Verify */}
              {getLocale("verify")}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default VerifyEmail;
