import React, { useEffect, useState } from "react";
import {
  IconChevronDown,
  IconChevronUp,
  IconEye,
  IconEyeOff,
  IconWorld,
} from "@tabler/icons-react";
import { CustomInput } from "../Core/input";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  passwordCreate,
  userFireBaseToken,
  userRegEmail,
  userRegMobile,
  userSendCode,
} from "../../service/api";
import { CircularProgress } from "@mui/material";
import { Danger, Success } from "../Core/Errors";
import { ROUTES } from "../../constants/routes";
import actions from "../../redux/user/actions";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import { getLocale } from "../../constants/language";
import logo from "../../Assets/image/new-logo.png";
import { useTranslation } from "react-i18next";
import OTPInput from "react-otp-input";

const validationSchemaEmail = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required."),
  isCheck: yup
    .bool()
    .oneOf([true], "You must accept the terms and conditions."),
});

const validationSchemaPhone = yup.object().shape({
  phoneNumber: yup
    .string()
    .required("Mobile Number is required.")
    .matches(/^\+\d+$/, "Mobile Number must be in international format."),
  isCheck: yup
    .bool()
    .oneOf([true], "You must accept the terms and conditions."),
});

const Register = () => {
  const { firebaseToken, language } = useSelector((state) => state?.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [referral, setReferral] = useState(false);
  const [isEmail, setIsEmail] = useState(true);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const { i18n } = useTranslation();
  const [timeLeft, setTimeLeft] = useState(30);
  const [isSending, setIsSending] = useState(false);

  const FireBaseToken = () => {
    userFireBaseToken({ token: firebaseToken })
      .then((res) => {})
      .catch((e) => {});
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      referral: "",
      phoneNumber: "",
      nationality: "",
      isCheck: false,
    },
    validationSchema: isEmail ? validationSchemaEmail : validationSchemaPhone,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (!isEmail && !isValidPhoneNumber(values?.phoneNumber)) {
        formik.setErrors({ phoneNumber: "Please enter a valid phone number." });
        return;
      } else {
        formik.setErrors({ phoneNumber: "" });
      }
      try {
        let body = {};
        if (isEmail) {
          body.email = values?.email;
        } else {
          body.nationality =
            values?.nationality === 52 ? "MX" : values?.nationality;
          body.phoneNumber = values?.phoneNumber;
        }
        setLoading(true);

        try {
          const res = await userSendCode(body);
          if (res?.status) {
            Success(
              `Verification code sent to ${
                formik?.values?.phoneNumber || formik?.values?.email
              }`
            );
            setIsSending(true);
            setTimeLeft(30);
            setStep(2);
          } else {
            Danger(res?.message);
          }
        } catch (error) {
          Danger(error?.response?.data?.message || error?.message);
        }
      } catch (error) {
        Danger(error?.response?.data?.message || error?.message);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    let timer;
    if (timeLeft > 0 && isSending) {
      timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
    }

    if (timeLeft === 0) {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [timeLeft, isSending]);

  const verifyOtpFunc = () => {
    if (!otp || otp?.length !== 6) {
      setOtpErr(true);
      return;
    }
    let body = {
      code: otp,
    };
    if (isEmail) {
      body.email = formik?.values?.email;
    } else {
      body.mobile = formik?.values?.phoneNumber;
    }
    setLoading(true);
    const API = isEmail ? userRegEmail : userRegMobile;

    // const API = isEmail ? verifyEmail : verifyMobile;
    API(body)
      ?.then((res) => {
        if (res?.status) {
          // formik.resetForm();
          setStep(3);
        }
      })
      .catch((error) => {
        Danger(error?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const createUser = () => {
    // const passwordRegex =
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{6,}$/;

    // if (!password) {
    //   setPasswordErr(true);
    //   return;
    // } else if (!passwordRegex.test(password)) {
    //   Danger(
    //     "Password must be at least 6 characters long, include one uppercase letter, one lowercase letter, one number, and one symbol."
    //   );
    //   setPasswordErr(true);
    //   return;
    // }

    let body = {
      password: password,
    };
    if (isEmail) {
      body.email = formik?.values?.email;
    } else {
      body.mobile = formik?.values?.phoneNumber;
      body.nationality = formik?.values?.nationality;
    }
    if (formik?.values?.referral) {
      body.referral = formik?.values?.referral;
    }
    setLoading(true);
    passwordCreate(body)
      ?.then((res) => {
        if (res?.status) {
          sessionStorage.setItem("token", res?.data?.token);

          dispatch(actions.setUser(res?.data?.user));
          dispatch(actions.setToken(res?.data?.token));
          if (firebaseToken) {
            FireBaseToken();
          }
          formik.resetForm();
          navigate(`${ROUTES.LANDING}?kyc`);
        }
      })
      .catch((error) => {
        Danger(error?.message);
      })
      .finally(() => setLoading(false));
  };

  const resendFunc = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      let body = {};
      if (isEmail) {
        body.email = formik?.values?.email;
      } else {
        body.nationality =
          formik?.values?.nationality === 52
            ? "MX"
            : formik?.values?.nationality;
        body.phoneNumber = formik?.values?.phoneNumber;
      }

      try {
        const res = await userSendCode(body);
        if (res?.status) {
          Success(
            `Verification code sent to ${
              formik?.values?.phoneNumber || formik?.values?.email
            }`
          );
          setIsSending(true);
          setTimeLeft(30);
          setStep(2);
        } else {
          Danger(res?.message);
        }
      } catch (error) {
        Danger(error?.response?.data?.message || error?.message);
      }
    } catch (error) {
      Danger(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const getRequirementMessage = (type) => {
    if (language === "en") {
      switch (type) {
        case "lowercase":
          return "At least one lowercase letter.";
        case "uppercase":
          return "At least one uppercase letter.";
        case "number":
          return "At least one number.";
        case "symbol":
          return "At least one symbol.";
        case "length":
          return "At least 6 characters.";
        default:
          return "";
      }
    } else if (language === "sp") {
      switch (type) {
        case "lowercase":
          return "Al menos una letra minúscula.";
        case "uppercase":
          return "Al menos una letra mayúscula.";
        case "number":
          return "Al menos un número.";
        case "symbol":
          return "Al menos un símbolo.";
        case "length":
          return "Al menos 6 caracteres.";
        default:
          return "";
      }
    }
    return "";
  };
  const checkPasswordRequirements = (password) => {
    return {
      hasLowercase: /[a-z]/.test(password),
      hasUppercase: /[A-Z]/.test(password),
      hasNumber: /\d/.test(password),
      hasSymbol: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      isLengthValid: password.length >= 6,
    };
  };

  const requirements = checkPasswordRequirements(password);
  const isPasswordValid = Object.values(requirements).every((requirement) => requirement === true);
  return (
    <div className="h-full min-h-[100vh] relative bg-white font-sans p-4">
      <div className="flex items-center justify-between gap-2 p-2 border-b border-[#bcbcbc]">
        <img src={logo} alt="" className="w-[160px] h-[45px" />
        {/* <div
          className="bg-[#e8e7e7]  rounded-full p-2 cursor-pointer"
          onClick={() => {
            changeLanguage(language === "sp" ? "en" : "es");
            dispatch(actions.setLangauge(language === "sp" ? "en" : "sp"));
          }}
        >
          <IconWorld />
        </div> */}
        <div className="flex items-center justify-center bg-[#101010] opacity-95 w-fit rounded-full p-1">
          <div
            onClick={() => {
              changeLanguage(language === "sp" ? "en" : "es");
              dispatch(actions.setLangauge(language === "sp" ? "en" : "sp"));
            }}
            className={`${
              language === "en"
                ? "bg-white text-black"
                : "bg-transparent text-white"
            } text-sm font-bold p-1 rounded-full cursor-pointer w-[30px]`}
          >
            EN
          </div>
          <div
            onClick={() => {
              changeLanguage(language === "sp" ? "en" : "es");
              dispatch(actions.setLangauge(language === "sp" ? "en" : "sp"));
            }}
            className={`${
              language === "sp"
                ? "bg-white text-black"
                : "bg-transparent text-white"
            } text-sm font-bold p-1 rounded-full cursor-pointer w-[30px]`}
          >
            ES
          </div>
        </div>
      </div>
      <div className="hidden justify-between items-center my-2">
        {/* <IconX
          className="size-7 cursor-pointer"
          onClick={() => {
            if (step === 1) {
              navigate(ROUTES.HOME);
            } else if (step === 2) {
              setStep(1);
            } else {
              setStep(2);
            }
          }}
        /> */}
        <p></p>
        <div className="flex items-center justify-center gap-2">
          <div
            className={`rounded-md h-1 w-12 ${
              step >= 1 ? "bg-[#373641]" : "bg-[#f3f3f3]"
            }`}
          ></div>
          <div
            className={`rounded-md h-1 w-12 ${
              step >= 2 ? "bg-[#373641]" : "bg-[#f3f3f3]"
            }`}
          ></div>
          <div
            className={`rounded-md h-1 w-12 ${
              step >= 3 ? "bg-[#373641]" : "bg-[#f3f3f3]"
            }`}
          ></div>
        </div>
        {/* <img src={stepImage} alt="step-top" /> */}
        <p></p>
      </div>
      {step === 1 ? (
        <form className="mt-3" onSubmit={formik.handleSubmit}>
          <div className="flex items-start flex-col w-full mt-6">
            <h2 className=" text-[22px] font-[700] text-textDefault text-left ">
              {getLocale("create")}
            </h2>
            {/* <div className="bg-[#010101] rounded-lg w-full flex justify-center items-center gap-2 mt-1 p-1">
              <div
                onClick={() => {
                  setIsEmail(true);
                  formik.resetForm();
                }}
                className={`${
                  isEmail ? "bg-slate-800" : "bg-transparent"
                } rounded-lg w-full p-2 text-white cursor-pointer`}
              >
                Register with Email
              </div>
              <div
                onClick={() => {
                  setIsEmail(false);
                  formik.resetForm();
                }}
                className={`${
                  !isEmail ? "bg-slate-800" : "bg-transparent"
                } rounded-lg w-full p-2 text-white cursor-pointer`}
              >
                Register with Mobile
              </div>
            </div> */}
            {/* {!isEmail ? (
              <div className="form-control w-full mt-4">
                <label className="block text-left text-textDefault text-base font-medium pb-2">
                  {getLocale("phoneNumber")}
                </label>
                <PhoneInput
                  style={{ marginTop: "0" }}
                  international
                  className={`${
                    formik.errors.phoneNumber && formik.touched.phoneNumber
                      ? "!border-2 !border-red-500"
                      : "border!border-[#e5e5e5]"
                  }`}
                  placeholder={`${getLocale("phoneNumber")}}`}
                  defaultCountry="MX"
                  name="phoneNumber"
                  id="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={(e) => {
                    if (e) {
                      const parsed = parsePhoneNumber(e);
                      formik.setFieldValue("nationality", parsed?.country);
                      formik.setFieldValue("phoneNumber", e);
                    }
                  }}
                />
              </div>
            ) : (
              <div className="form-control w-full mt-4">
                <label className="block text-left text-textDefault text-base font-medium pb-2">
                  {getLocale("email")}
                </label>
                <CustomInput
                  type="email"
                  placeholder={`${getLocale("email")}`}
                  name="email"
                  id="email"
                  value={formik.values.email}
                  onChange={(e) => {
                    formik.setFieldValue("email", e.target.value);
                  }}
                  error={
                    formik.errors.email && formik.touched.email ? true : false
                  }
                />
              </div>
            )} */}
            <div
              className="form-control w-full mt-4"
              style={{ position: "relative" }}
            >
              <label className="block text-left text-textDefault text-base font-medium pb-2">
                {isEmail ? null : getLocale("phoneNumber")}
              </label>
              {isEmail ? (
                <>
                  <div style={{ position: "relative" }}>
                    <CustomInput
                      type="email"
                      placeholder={`${getLocale("email")}/${getLocale(
                        "phoneNumber"
                      )}`}
                      name="email"
                      id="email"
                      value={formik.values.email}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue("email", value);
                        if (/^\d{3,}$/.test(value)) {
                          formik.setFieldValue("phoneNumber", "+52" + value);
                          setIsEmail(false);
                          formik.setFieldValue("nationality", +52);
                          setTimeout(() => {
                            let ele = document.getElementById("phoneNumber");

                            if (ele) {
                              ele.focus();
                            }
                          }, 500);
                        }
                      }}
                      error={
                        formik.errors.email && formik.touched.email
                          ? true
                          : false
                      }
                      className="input-field"
                    />
                    {(formik?.values?.email || formik?.values?.phoneNumber) && (
                      <button
                        onClick={() => {
                          setIsEmail(true);
                          formik.setFieldValue("email", "");
                          formik.setFieldValue("phoneNumber", "");
                        }}
                        type="button"
                        className="bg-[#d3d1d1] p-[2px] rounded-full absolute right-3 top-1/2 -translate-y-1/2 border-none cursor-pointer text-white size-[22px] font-[700] flex items-center justify-center pb-[5px]"
                      >
                        x
                      </button>
                    )}
                  </div>
                  {formik.errors.email && formik.touched.email && (
                    <p className="text-red-500 text-xs mt-1 w-full text-left font-[500]">
                      {formik?.errors?.email}
                    </p>
                  )}
                </>
              ) : (
                <>
                  <div style={{ position: "relative" }}>
                    <PhoneInput
                      style={{ marginTop: "0" }}
                      international
                      isValidPhoneNumber
                      className={`${
                        formik.errors.phoneNumber && formik.touched.phoneNumber
                          ? "!border-2 !border-red-500"
                          : "border!border-[#e5e5e5]"
                      } input-field`}
                      placeholder={`${getLocale("phoneNumber")}`}
                      defaultCountry="MX"
                      name="phoneNumber"
                      id="phoneNumber"
                      value={formik.values.phoneNumber}
                      onChange={(e) => {
                        if (e) {
                          const value = e.replace(/\s/g, "");
                          const parsed = parsePhoneNumber(value);

                          // if (!parsed) {
                          //   formik.setFieldValue("email", value);
                          //   setIsEmail(true);
                          //   return
                          // }
                          if (!/[^0-9]/.test(value)) {
                            formik.setFieldValue("email", value);
                            setIsEmail(true);
                          } else {
                            formik.setFieldValue("phoneNumber", e);
                            formik.setFieldValue(
                              "nationality",
                              parsed?.country || null
                            );
                          }
                        }
                      }}
                    />
                    {(formik?.values?.email || formik?.values?.phoneNumber) && (
                      <button
                        onClick={() => {
                          setIsEmail(true);
                          formik.setFieldValue("email", "");
                          formik.setFieldValue("phoneNumber", "");
                        }}
                        type="button"
                        className="bg-[#d3d1d1] p-[2px] rounded-full absolute right-3 top-1/2 -translate-y-1/2 border-none cursor-pointer text-white size-[22px] font-[700] flex items-center justify-center pb-[5px]"
                      >
                        x
                      </button>
                    )}
                  </div>
                  {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                    <p className="text-red-500 text-xs mt-1 w-full text-left font-[500]">
                      {formik?.errors?.phoneNumber}
                    </p>
                  )}
                </>
              )}
            </div>

            <div className="form-control w-full mt-4">
              <label
                onClick={() => setReferral(!referral)}
                className="flex items-center justify-start cursor-pointer gap-1 text-left text-textDefault text-sm font-medium opacity-60 pb-2"
              >
                {/* {getLocale("phoneNumber")} */}
                {getLocale("Referral")}
                {referral ? <IconChevronUp /> : <IconChevronDown />}
              </label>
              {referral && (
                <CustomInput
                  type="text"
                  placeholder={getLocale("Referralcode")}
                  name="referral"
                  id="referral"
                  value={formik.values.referral}
                  onChange={(e) => {
                    formik.setFieldValue("referral", e.target.value);
                  }}
                />
              )}
            </div>

            <div className="flex justify-center items-start text-pretty text-left gap-2 w-full mt-12 text-textDefault text-sm">
              <div className="relative flex items-center cursor-pointer mb-4">
                {/* Hidden Checkbox */}
                <input
                  type="checkbox"
                  id="custom-checkbox"
                  name="isCheck"
                  className="absolute opacity-0 cursor-pointer peer h-0 w-0"
                  checked={formik.values.isCheck}
                  onChange={(e) => {
                    formik.setFieldValue("isCheck", e.target.checked);
                  }}
                  onBlur={formik.handleBlur}
                />

                {/* Custom Checkbox */}
                <span
                  onClick={(e) => {
                    formik.setFieldValue("isCheck", !formik?.values?.isCheck);
                  }}
                  className={`block relative h-[1.3em] w-[1.3em] bg-black rounded-full transition-all duration-300 border border-[#000]
            ${formik.values.isCheck ? "bg-[#000] " : "bg-transparent "} 
            after:absolute after:top-[0.25em] after:left-[0.45em] 
            after:w-[0.35em] after:h-[0.59em] after:border 
            after:border-white after:border-t-0 after:border-l-0 
            after:rotate-45 after:content-[''] ${
              formik.values.isCheck ? "after:block" : "after:hidden"
            }`}
                ></span>
              </div>
              <span>
                {getLocale("confirmTC")}{" "}
                <span className="text-black underline font-[600] cursor-pointer">
                  {getLocale("TC")}
                </span>{" "}
                and{" "}
                <span className="text-black underline font-[600] cursor-pointer">
                  {getLocale("privacyPolicies")}
                </span>
              </span>
            </div>
            {formik.errors.isCheck && formik.touched.isCheck && (
              <p className="text-red-500 text-sm mt-1">
                {formik.errors.isCheck}
              </p>
            )}
            {loading ? (
              <button
                disabled={loading}
                className="w-full p-2 bg-bgBlue text-white rounded-full mt-5 text-base"
              >
                <CircularProgress size={20} color="white" thickness={6} />
              </button>
            ) : (
              <button
                type="submit"
                disabled={loading}
                className="w-full p-2 bg-bgBlue text-white rounded-full mt-5 text-base font-[700]"
              >
                {getLocale("create")}
              </button>
            )}

            <label className="text-[#000000] block text-sm text-left mt-6">
              {getLocale("already")}{" "}
              <span
                onClick={() => navigate(ROUTES.LOGIN)}
                className="font-semibold underline cursor-pointer"
              >
                {getLocale("login")}
              </span>
            </label>
          </div>
        </form>
      ) : step === 2 ? (
        <div className="flex items-start flex-col w-full mt-6">
          <h2 className=" text-[22px] font-[700] text-textDefault text-left ">
            {getLocale("enter6")}{" "}
            {isEmail ? formik?.values?.email : formik?.values?.phoneNumber}
          </h2>
          <h3 className="text-base font-medium text-textDefault opacity-60 my-2">
            {/* For security, we need to verify it's you */}
            {getLocale("securityfor")}
          </h3>
          <div className="form-control w-full mt-2">
            <label className="block text-left text-textDefault text-base font-medium pb-1">
              {/* {getLocale("phoneNumber")} */}
            </label>

            <div className="mt-0">
              <OTPInput
                numInputs={6}
                renderSeparator={
                  <p className="text-sm font-proxima-regular text-black px-2">
                    -
                  </p>
                }
                renderInput={(props) => (
                  <input
                    {...props}
                    className={`!w-full h-11 bg-white border ${
                      otpErr ? "border-red-500" : "border-borderGrey"
                    } rounded-md text-lg font-[600] text-black`}
                  />
                )}
                containerStyle="w-full"
                value={otp}
                onChange={(value) => {
                  setOtp(value);
                  setOtpErr(false);
                }}
              />
            </div>
          </div>
          <label className="text-[#7b7a7a] block text-sm text-right mt-1 w-full">
            <span
              onClick={resendFunc}
              className={`font-semibold text-right cursor-pointer ${
                timeLeft === 0 ? "opacity-0" : "opacity-100"
              }`}
            >
              {timeLeft}s
            </span>
          </label>
          <label className="text-[#000000] opacity-60 block text-xs text-left mt-6">
            <span
              onClick={() => {
                setStep(1);
                formik.setFieldValue("email", "");
                formik.setFieldValue("phoneNumber", "");
                formik.resetForm();
              }}
              className={`font-semibold cursor-pointer `}
            >
              {getLocale("change")}
            </span>
          </label>
          <label className="text-[#000000] block text-sm text-left mt-6">
            <span
              onClick={resendFunc}
              className={`font-semibold underline cursor-pointer ${
                timeLeft === 0 ? "opacity-100" : "opacity-60"
              }`}
            >
              {getLocale("resend")}
            </span>
          </label>
          {loading ? (
            <button
              disabled={loading}
              className="w-full p-2 bg-bgBlue text-white rounded-full mt-5 text-base"
            >
              <CircularProgress size={20} color="white" thickness={6} />
            </button>
          ) : (
            <button
              onClick={verifyOtpFunc}
              disabled={loading}
              className="w-full p-2 bg-bgBlue text-white rounded-full mt-5 text-base font-[700]"
            >
              {getLocale("verify")}
            </button>
          )}
        </div>
      ) : (
        <div className="form-control w-full mt-4">
          <h2 className=" text-[22px] font-[700] text-textDefault text-left ">
            {getLocale("createOwn")}
            {/* Create your own password */}
          </h2>
          <label className="block text-left text-textDefault text-sm opacity-60 font-medium pb-2">
            {getLocale("almostThere")}
          </label>
          <div className="relative mt-2">
            <CustomInput
              type={passwordToggle ? "text" : "password"}
              placeholder={getLocale("password")}
              className="w-full pr-10"
              name="password"
              id="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordErr(false);
              }}
              error={passwordErr ? true : false}
            />
            {!passwordToggle ? (
              <IconEye
                className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer opacity-50"
                onClick={() => setPasswordToggle(!passwordToggle)}
              />
            ) : (
              <IconEyeOff
                className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer opacity-50"
                onClick={() => setPasswordToggle(!passwordToggle)}
              />
            )}
          </div>

          <div className="mt-2 space-y-1 text-sm text-gray-500">
            <div
              className={`flex items-center ${
                requirements.hasLowercase ? "text-green-500" : "text-red-500"
              }`}
            >
              {requirements.hasLowercase ? "✔" : "❌"}{" "}
              {getRequirementMessage("lowercase")}
            </div>
            <div
              className={`flex items-center ${
                requirements.hasUppercase ? "text-green-500" : "text-red-500"
              }`}
            >
              {requirements.hasUppercase ? "✔" : "❌"}{" "}
              {getRequirementMessage("uppercase")}
            </div>
            <div
              className={`flex items-center ${
                requirements.hasNumber ? "text-green-500" : "text-red-500"
              }`}
            >
              {requirements.hasNumber ? "✔" : "❌"}{" "}
              {getRequirementMessage("number")}
            </div>
            <div
              className={`flex items-center ${
                requirements.hasSymbol ? "text-green-500" : "text-red-500"
              }`}
            >
              {requirements.hasSymbol ? "✔" : "❌"}{" "}
              {getRequirementMessage("symbol")}
            </div>
            <div
              className={`flex items-center ${
                requirements.isLengthValid ? "text-green-500" : "text-red-500"
              }`}
            >
              {requirements.isLengthValid ? "✔" : "❌"}{" "}
              {getRequirementMessage("length")}
            </div>
          </div>
          <ul className="mt-4 mb-2 text-[#7b7a7a] text-sm text-left list-disc list-inside space-y-2">
            <li>{getLocale("int1")}</li>
            <li>{getLocale("int2")}</li>
            <li>{getLocale("int3")} </li>
          </ul>
          {loading ? (
            <button
              disabled={loading}
              className="w-full p-2 bg-bgBlue text-white rounded-full mt-5 text-base"
            >
              <CircularProgress size={20} color="white" thickness={6} />
            </button>
          ) : (
            <button
              onClick={createUser}
              disabled={!isPasswordValid}
              className={`w-full p-2 bg-bgBlue ${!isPasswordValid?"opacity-80":"opacity-100"} text-white rounded-full mt-5 text-base font-[700]`}
            >
              {getLocale("confirm")}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Register;
