import React, { useEffect, useState } from "react";
import {
  getAllCoins,
  getAllCurrency,
  getAllPayMethodByUser,
} from "../../../service/api";
import PopUp from "./PopUp";
import { IconCheck, IconSearch } from "@tabler/icons-react";
import { Danger } from "../../Core/Errors";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/user/actions";
import { ROUTES } from "../../../constants/routes";
import { getLocale } from "../../../constants/language";

function SelectPayMethods({ setCurrFilter, currFilter, data }) {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { p2pAddPayload } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleSelectPaymentMethod = (item) => {
    const finded = p2pAddPayload?.paymentMethods?.find(
      (method) => method === item?._id
    );
    if (finded) {
      const filtered = p2pAddPayload?.paymentMethods?.filter(
        (met) => met !== item?._id
      );
      dispatch(
        actions.setP2PPaylaod({ ...p2pAddPayload, paymentMethods: filtered })
      );
    } else {
      dispatch(
        actions.setP2PPaylaod({
          ...p2pAddPayload,
          paymentMethods: [...p2pAddPayload?.paymentMethods, item?._id],
        })
      );
    }
  };
  return (
    <PopUp
      open={currFilter}
      setOpen={setCurrFilter}
      title={"All Payment Methods"}
    >
      <div className=" px-4">
        <div className=" flex justify-between items-center w-full ">
          <div className=" w-[80%] bg-bgGray items-center rounded-md h-[30px] flex justify-between gap-1 pl-1">
            <IconSearch size={20} />
            <input
              type="text"
              className=" w-full h-full border-none outline-none placeholder:opacity-[0.5] bg-transparent "
              placeholder="Search payment method"
            />
          </div>
          <p
            onClick={() => {
              setCurrFilter(false);
              handleSelectPaymentMethod({ _id: -1 });
            }}
            className=" text-textPrime cursor-pointer text-[16px] tracking-[1.02px] leading-[140%]"
          >
            {/* Cancel */}
            {getLocale("cancel")}
          </p>
        </div>
        <div className=" max-h-[73vh] overflow-y-auto pb-12 no-scrollbar">
          <p className="font- opacity-50 text-left mt-5 leading-[145%] tracking-[1px] text-[16px] text-[#111111]">
            {/* Select a payment method */}
            {getLocale("selectPaymentMethod")}
          </p>
          {data?.length > 0 ? (
            data?.map((item, index) => (
              <P2PPaymentCard
                key={`paymethods-${index}`}
                item={item}
                handleSelectPaymentMethod={(val) =>
                  handleSelectPaymentMethod(val)
                }
              />
            ))
          ) : (
            <button
              onClick={() => navigate(ROUTES.RECOMENDED)}
              className=" w-[95%] bottom-0 bg-bgBlue text-textWhite py-2 rounded-md tracking-[0.2px] leading-[140%] mb-2"
            >
              {/* All Payment Methods */}
              {getLocale("allPaymentMethod")}
            </button>
          )}
        </div>
        <div
          onClick={() => setCurrFilter(false)}
          className=" px-4 w-full absolute bottom-0 left-0 justify-center flex items-center "
        >
          <button className=" w-[95%]  text-clip absolute bottom-0 bg-bgBlue text-textWhite py-2 rounded-md tracking-[0.2px] leading-[140%] mb-2">
            {/* Add */}
            {getLocale("add")}
          </button>
        </div>
      </div>
    </PopUp>
  );
}

export default SelectPayMethods;

const P2PPaymentCard = ({ item, handleSelectPaymentMethod }) => {
  const { p2pAddPayload } = useSelector((state) => state.user);
  return (
    <div
      onClick={() => handleSelectPaymentMethod(item)}
      className="rounded-md bg-opacity-10 w-full cursor-pointer mt-2 flex py-4 mx-auto active:bg-[#ccc] border justify-between items-center px-2"
    >
      <div>
        <div className="text-[#111] text-left text-base mr-auto flex h-full items-center">
          {/* <div className={`${item.color} w-[2px] h-3`}></div> */}
          <p className=" uppercase text-[12px] font-semibold">{item.type}</p>
        </div>
        {Object.entries(item?.details ?? {}).map(([key, value], index) => (
          <div className="text-left">
            <p className=" text-[12px] text-textPrime leading-[140%] tracking-[0.02px]">
              {value}
            </p>
          </div>
        ))}
      </div>

      <div className="text-right text-base ml-auto">
        {p2pAddPayload?.paymentMethods?.includes(item?._id) && (
          <IconCheck size={20} className=" text-textPrime" />
        )}
      </div>
    </div>
  );
};
