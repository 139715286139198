import { IconArrowNarrowLeft } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { data, trades } from "./SampleData";
import { ROUTES } from "../../constants/routes";
import { PiApproximateEquals } from "react-icons/pi";
import {
  getCoinOrderData,
  getTradingPair,
  getWalletTr,
} from "../../service/api";
import actions from "../../redux/user/actions";
import { getLocale } from "../../constants/language";

function CoinHistory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tab, setTab] = useState("buySell");
  console.log("%c Line:20 🍪 tab", "color:#7f2b82", tab);
  const [pairData, setPairData] = useState([]);
  const [coinData, setCoinData] = useState([]);
  const { activeCoin, user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  console.log("%c Line:24 🍊 loading", "color:#6ec1c2", loading);

  const getPairData = () => {
    setLoading(true);
    getTradingPair({
      page: 1,
      sizePerPage: 100,
      pagination: false,
      fromCoin: activeCoin?.data?.coin?._id,
    })
      ?.then((res) => {
        if (res?.status) {
          setPairData(res?.data?.result);
        }
      })
      .finally(() => setLoading(false));
  };

  const getCoinData = () => {
    setLoading(true);
    getCoinOrderData({
      page: 1,
      pagination: true,
      sizePerPage: 120,
      coin: activeCoin?.data?.coin?._id,
      // coin: "670e9bf1d96301f59e3ffb78",
    })
      ?.then((res) => {
        if (res?.status) {
          setCoinData(res?.data?.result);
        }
      })
      .finally(() => setLoading(false));
  };

  const getTransfer = () => {
    setLoading(true);
    getCoinOrderData({
      page: 1,
      pagination: true,
      sizePerPage: 120,
      coin: activeCoin?.data?.coin?._id,
      // coin: "670e9bf1d96301f59e3ffb78",
    })
      ?.then((res) => {
        console.log("%c Line:62 🍌 res", "color:#ed9ec7", res);
        if (res?.status) {
          // setCoinData(res?.data?.result);
        }
      })
      .finally(() => setLoading(false));
  };

  const walletDataTransfer = () => {
    setLoading(true);

    getWalletTr({
      coin: activeCoin?.data?.coin?._id,
    })
      ?.then((res) => {
        if (res?.status) {
          setCoinData(res?.data);
        }
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    setCoinData([]);
    if (tab === "buySell") {
      getCoinData();
    } else {
      walletDataTransfer();
      // getTransfer();
    }
  }, [tab]);

  useEffect(() => {
    getPairData();
  }, []);

  // lastPrice,lastTradeDate

  const HistoryTile = (props) => {
    return (
      <div className="border-b border-gray-400 py-1 flex items-center justify-between">
        <div>
          <p
            className={`text-xs font-normal text-[#111] leading-[140%] tracking-[0.2px] text-left`}
          >
            {props?.data?.orderId?.orderType === "BUY"
              ? activeCoin?.data?.coin?._id === props?.data?.fromCoin?._id
                ? "Buy"
                : "Sell"
              : activeCoin?.data?.coin?._id === props?.data?.toCoin?._id
              ? "Buy"
              : "Sell"}
          </p>
          <p
            className={`text-[10px] hidden opacity-55 font-normal text-[#111] leading-[140%] tracking-[0.2px] text-left`}
          >
            {props.index % 2 ? "Spot" : "Funding"}
          </p>
          <p
            className={`text-[10px] opacity-55 font-normal text-[#111] leading-[140%] tracking-[0.2px] text-left`}
          >
            {moment(
              props?.data?.buyOrderId?.createdAt ||
                props?.data?.sellOrderId?.createdAt
            ).format("YYYY-MM-DD hh:mm:ss ")}
          </p>
        </div>
        {props?.data?.orderId?.orderType === "BUY" ? (
          activeCoin?.data?.coin?._id === props?.data?.fromCoin?._id ? (
            <p
              className={`text-xs font-normal text-green-500 leading-[140%] tracking-[0.2px] text-left`}
            >
              +{props?.data?.quantity} {props?.data?.fromCoin?.symbol}
            </p>
          ) : (
            <p
              className={`text-xs font-normal text-red-500 leading-[140%] tracking-[0.2px] text-left`}
            >
              -{props?.data?.amount} {props?.data?.toCoin?.symbol}
            </p>
          )
        ) : activeCoin?.data?.coin?._id === props?.data?.toCoin?._id ? (
          <p
            className={`text-xs font-normal text-green-500 leading-[140%] tracking-[0.2px] text-left`}
          >
            +{props?.data?.amount} {props?.data?.toCoin?.symbol}
          </p>
        ) : (
          <p
            className={`text-xs font-normal text-red-500 leading-[140%] tracking-[0.2px] text-left`}
          >
            -{props?.data?.quantity} {props?.data?.fromCoin?.symbol}
          </p>
        )}
      </div>
    );
  };

  const HistoryTileSkeleton = () => {
    return (
      <div className="border-b border-gray-400 py-1 flex items-center justify-between animate-pulse">
        <div>
          <div className="bg-gray-300 h-4 w-12 rounded mb-1"></div>
          <div className="bg-gray-300 h-2 w-14 rounded mb-1"></div>
          <div className="bg-gray-300 h-2 w-20 rounded"></div>
        </div>
        <div className="bg-gray-300 h-4 w-10 rounded"></div>
      </div>
    );
  };

  return (
    <div>
      <div className="h-full min-h-[100vh] bg-white font-sans px-4 py-12 flex flex-col relative">
        <div className=" flex justify-center items-center relative">
          <IconArrowNarrowLeft
            className="size-7 cursor-pointer absolute left-1"
            onClick={() => navigate(-1)}
          />
          <h1 className="font-medium leading-[140%] tracking-[-1px] ">
            {activeCoin?.data?.coin?.name}
          </h1>
          {/* <IconStarFilled size={15} /> */}
        </div>
        <div className="mt-2 px-2">
          <div className="text-[14px] font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
            {activeCoin?.wallet} balance
          </div>
          <div className="text-[14px] flex items-center justify-start gap-2 font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
            <span className="text-lg">{activeCoin?.data?.amount}</span>{" "}
            <PiApproximateEquals />
            <span className="text-xs opacity-60">
              {" "}
              ${activeCoin?.data?.amount * +activeCoin?.data?.coin?.price}
            </span>
          </div>
        </div>

        <div className="mt-2 border-t border-gray-800 py-2 text-left">
          <span className="text-[16px] font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
            {/* Spot */}
            {getLocale("spot")}
          </span>
          <div className="flex items-center justify-start gap-2 my-4 overflow-x-scroll no-scrollbar overflow-y-hidden scrollbar-hidden">
            {pairData &&
              pairData?.length > 0 &&
              pairData?.map((obj, index) => {
                return (
                  <div
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(actions.setActivePair(obj));
                      navigate(ROUTES.CRYPTO_INFO);
                    }}
                    className="border border-gray-800 rounded-lg px-3 py-2 cursor-pointer hover:bg-black hover:bg-opacity-5 w-60"
                  >
                    <div className="text-base font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                      {obj?.fromCoin?.symbol}
                      <span className="text-xs opacity-70">
                        /{obj?.toCoin?.symbol}
                      </span>
                    </div>
                    <p className="text-base font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left mt-3">
                      {obj?.currentPrice}
                    </p>
                    <p
                      className={`text-xs font-normal ${
                        index % 2 ? "text-red-500" : "text-green-500"
                      } leading-[140%] tracking-[0.2px] text-left`}
                    >
                      +0.88%
                    </p>
                  </div>
                );
              })}
          </div>
        </div>

        <span className="text-[16px] font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
          {/* History */}
          {getLocale("history")}
        </span>
        <div className="flex items-center justify-start gap-3 my-2 border-b border-gray-800 text-sm mt-4">
          {/* <div
            onClick={() => setTab("All")}
            className={`border-b-2 pb-1 ${
              tab === "All"
                ? "font-semibold border-gray-800"
                : "font-medium border-transparent"
            } cursor-pointer`}
          >
            All
          </div> */}
          <div
            onClick={() => setTab("buySell")}
            className={`border-b-2 pb-1 ${
              tab === "buySell"
                ? "font-semibold border-gray-800"
                : "font-medium border-transparent"
            } cursor-pointer`}
          >
            {/* Buy & Sell */}
            {getLocale("buyAndSell")}
          </div>
          <div
            onClick={() => setTab("convert")}
            className={`border-b-2 pb-1 ${
              tab === "convert"
                ? "font-semibold border-gray-800"
                : "font-medium border-transparent"
            } cursor-pointer`}
          >
            Convert
          </div>
        </div>

        {tab === "All" &&
          Array.from({ length: 5 }).map((obj, index) => {
            return (
              <div key={index}>
                <HistoryTile index={index} />
              </div>
            );
          })}

        {loading ? (
          Array.from({ length: 5 }).map((obj, index) => {
            return (
              <div key={index}>
                <HistoryTileSkeleton index={index} />
              </div>
            );
          })
        ) : tab === "buySell" ? (
          coinData && coinData?.length > 0 ? (
            coinData?.map((obj, index) => {
              return (
                <div key={index}>
                  <HistoryTile data={obj} index={index} />
                </div>
              );
            })
          ) : (
            <div className="bg-transparent border border-gray-600 rounded-lg p-2 w-full mx-auto text-black font-medium text-base mt-2">
              {/* No any trades found */}
              {getLocale("noTrades")}
            </div>
          )
        ) : coinData && coinData?.length > 0 ? (
          coinData?.map((obj, index) => {
            return (
              <div className="border-b border-gray-400 py-1 flex items-center justify-between">
                <div>
                  <p
                    className={`text-[10px] font-normal opacity-55 text-[#111] leading-[140%] tracking-[0.2px] text-left`}
                  >
                    {/* Transfer */}
                    {getLocale("transfer")}
                  </p>
                  <p
                    className={`text-xs font-normal text-[#111] leading-[140%] tracking-[0.2px] text-left`}
                  >
                    {obj?.toWalletType}
                  </p>
                  <p
                    className={`text-[10px] opacity-55 font-normal text-[#111] leading-[140%] tracking-[0.2px] text-left`}
                  >
                    {moment(obj?.createdAt).format("YYYY-MM-DD hh:mm:ss ")}
                  </p>
                </div>

                <p
                  className={`text-xs font-normal text-green-500 leading-[140%] tracking-[0.2px] text-left`}
                >
                  +{obj?.amount}
                </p>
              </div>
            );
          })
        ) : (
          <div className="bg-transparent border border-gray-600 rounded-lg p-2 w-full mx-auto text-black font-medium text-base mt-2">
            {/* No any trades found */}
            {getLocale("noTrades")}
          </div>
        )}
      </div>
    </div>
  );
}

export default CoinHistory;
