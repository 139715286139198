import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "@firebase/messaging";
import store from "./redux/store";
import actions from "./redux/user/actions";

const firebaseConfig = {
  apiKey: "AIzaSyC5JUyCxCd_xtuZk4-g2Dmp3pcGrGx5pmI",
  authDomain: "http://kmbio-bec84.firebaseapp.com",
  projectId: "kmbio-bec84",
  storageBucket: "http://kmbio-bec84.appspot.com",
  messagingSenderId: "842730675727",
  appId: "1:842730675727:web:7da113637b1dd12c86cd7a",
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BNYH850146Cb8wOrvwltvTwAbhc80Jes_yM6cPPofo07vASKyrhyLTzt7kpIgLJNYvBnmG-N9u_E7_Qj4qQ2dp8",
    });
    console.log("%c Line:28 🥥 token", "color:#33a5ff", token);
    if (token) {
      store.dispatch(actions.setFirebaseToken(token));
    }
  }
};

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log(
        "Service Worker registration successful with scope: ",
        registration.scope
      );
    })
    .catch((err) => {
      console.log("Service Worker registration failed: ", err);
    });
}
