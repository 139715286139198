import {
  IconArrowNarrowLeft,
  IconCaretDownFilled,
  IconInfoCircle,
  IconX,
} from "@tabler/icons-react";
// import { getLocale } from "../../constants/language";

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAllPayMethodByUser,
  getP2PById,
  getWallet,
  placeP2POrder,
} from "../../../service/api";
import { Danger, Success, Warning } from "../../Core/Errors";
import Loader from "../../Core/Loader";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/user/actions";
import ChoosePayMethod from "./ChoosePayMethod";
import Confrim from "./Confrim";
import { validateP2Order, validatePayoad } from "./P2PError";
import { ROUTES } from "../../../constants/routes";
import { getLocale } from "../../../constants/language";

function BuyP2P() {
  const { P2PorderPayload } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [error, setError] = useState({
    paymentMethods: "",
    totalAmountCoin: "",
  });
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [currFilter, setCurrFilter] = useState(false);
  const [isConfrim, setIsConfrim] = useState(false);
  const [byWhich, setByWhich] = useState(true);
  const [data, setData] = useState([]);
  const ID = useLocation().state?.ID;
  const [fields, setFields] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [fundingAmout, setFundingAmout] = useState("");

  const getAllPaymethods = () => {
    try {
      setLoader(true);
      getAllPayMethodByUser(pageNumber, true, sizePerPage)
        .then((res) => {
          setData(res?.data?.result);
          setLoader(false);
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoader(false);
        });
    } catch (error) {
      Danger(error?.response?.data?.message);
      setLoader(false);
    }
  };

  // useEffect(() => {
  //   getAllPaymethods();
  // }, []);

  const getP2PByid = () => {
    try {
      setLoader(true);
      getP2PById(ID)
        .then((res) => {
          setFields(res?.data);
          if (res?.data && res?.data?.type === "SELL") {
            setData(res?.data?.paymentMethods);
          } else {
            getAllPaymethods();
          }
          setLoader(false);
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoader(false);
        });
    } catch (error) {
      Danger(error?.response?.data?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (ID) getP2PByid();
  }, [ID]);

  const placeOrder = () => {
    if (loader) {
      return;
    }
    const error = validateP2Order(P2PorderPayload);
    if (error?.isValid) {
      try {
        setLoader(true);
        try {
          const payload = {
            ...P2PorderPayload,
            totalAmountCoin: byWhich
              ? Number(
                  Number(P2PorderPayload?.totalAmountCoin) /
                    Number(fields?.price)
                )
              : Number(P2PorderPayload?.totalAmountCoin),
            totalPaymentInFait: byWhich
              ? Number(P2PorderPayload?.totalAmountCoin)
              : Number(P2PorderPayload?.totalAmountCoin) *
                Number(fields?.price),
          };
          placeP2POrder({ ...payload })
            .then((res) => {
              Success(res?.message).then((_) => {
                navigate(ROUTES.VERIFYORDER, {
                  state: { response: res?.data, ID: ID, fields: fields },
                });
              });
              setLoader(false);
            })
            .catch((error) => {
              Danger(error?.response?.data?.message);
              setLoader(false);
            });
        } catch (error) {
          Danger(error?.response?.data?.message);
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
      }
    } else {
      const errormessage = Object.entries(error?.errors)
        .map(([field, message]) => `${field}:${" "} ${message}`)
        .join("\n");

      Warning(errormessage);
    }
  };

  const getAssetsById = () => {
    try {
      getWallet({ walletType: "FUNDING", coinId: fields?.coin?._id })
        .then((res) => {
          setFundingAmout(res.data[0]?.data[0]?.amount);
          setLoader(false);
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoader(false);
        });
    } catch (error) {
      Danger(error?.response?.data?.message);
      setLoader(false);
    }
  };
  useEffect(() => {
    if (fields?.coin?._id) {
      getAssetsById();
    }
  }, [fields?.coin?._id]);
  return (
    <div>
      {loader && <Loader />}
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col ">
        <div>
          <div className=" flex justify-between items-center">
            <IconArrowNarrowLeft
              className="size-7 cursor-pointer"
              onClick={() => {
                dispatch(actions.removeP2POrder(null));
                navigate(-1);
              }}
            />
            <h1 className="font-medium leading-[145%] tracking-[-1px] text-[18px] text-textPrime">
              {fields?.type == "BUY" ? "SELL" : "BUY"} {fields?.coin?.symbol}
            </h1>
            <p></p>
          </div>
        </div>

        <div className=" ">
          <p className="font-medium leading-[145%] tracking-[-1px] text-[16px] text-textPrime text-left">
            <span className=" opacity-50"> Price </span>
            <span className=" opacity-100 font-bold ml-0.5 ">
              {" "}
              ₹{fields?.price}
            </span>
          </p>
          <div className=" rounded-md border px-2 py-2">
            <div className=" flex items-center gap-4">
              <p
                onClick={() => setByWhich(true)}
                className={` transition-all relative cursor-pointer font-medium leading-[145%] tracking-[-1px] text-[16px] text-textPrime text-left ${
                  byWhich
                    ? " opacity-100 after:absolute after:w-full after:h-1 after:bg-bgBlue after:bottom-[-2px] after:left-0"
                    : " opacity-50"
                }`}
              >
                {/* By Fiat */}
                {getLocale("byFiat")}
              </p>
              <p
                onClick={() => setByWhich(false)}
                className={` transition-all relative cursor-pointer font-medium leading-[145%] tracking-[-1px] text-[16px] text-textPrime text-left ${
                  byWhich
                    ? " opacity-50"
                    : " opacity-100 after:absolute after:w-full after:h-1 after:bg-bgBlue after:bottom-[-2px] after:left-0"
                }`}
              >
                {/* By Crypto */}
                {getLocale("byCrypto")}
              </p>
            </div>
            <div>
              <div className=" mt-3">
                <div className="mb-1 w-full">
                  <label className="block text-sm font-medium mb-1 text-left">
                    {/* Total amount */}
                    {getLocale("totalAmount")}
                  </label>
                  <div
                    className={`w-full  border ${
                      error?.totalAmountCoin
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-lg p-2  h-[45px] flex justify-between items-center `}
                  >
                    <input
                      value={P2PorderPayload?.totalAmountCoin}
                      onChange={(e) => {
                        dispatch(
                          actions.setP2POrder({
                            ...P2PorderPayload,
                            totalAmountCoin: e.target.value,
                          })
                        );
                      }}
                      className=" w-[70%] h-full outline-none border-none text-left "
                      placeholder={byWhich ? "Enter amount" : "Enter quantity"}
                      type="number"
                      name=""
                      id=""
                    />

                    <p className=" flex items-center gap-1 ">
                      {!byWhich ? fields?.coin?.symbol : ""} MAX
                    </p>
                  </div>
                </div>
                <div className=" flex justify-between items-start ">
                  <div>
                    {error?.totalAmountCoin && (
                      <p className=" text-[10px] text-red-500 text-left">
                        {error?.totalAmountCoin}
                      </p>
                    )}
                    <p
                      className={`tracking-[-0.2px] leading-[145%] text-[10px] text-left ${
                        error.totalAmountCoin
                          ? "text-red-500"
                          : "text-textPrime "
                      }`}
                    >
                      <span className=" opacity-50">Limit</span>{" "}
                      <span className=" opacity-100">
                        <NumericFormat
                          decimalScale={2}
                          value={
                            byWhich
                              ? fields?.minLimit
                              : fields?.minLimit / fields?.price
                          }
                          displayType="text"
                          thousandSeparator
                        />{" "}
                        {`${
                          byWhich
                            ? fields?.fiatCurrencyDetails?.name
                            : fields?.coin?.symbol
                        } `}
                        -{" "}
                        <NumericFormat
                          decimalScale={2}
                          value={
                            byWhich
                              ? fields?.maxLimit
                              : fields?.maxLimit / fields?.price
                          }
                          displayType="text"
                          thousandSeparator
                        />{" "}
                        {`${
                          byWhich
                            ? fields?.fiatCurrencyDetails?.name
                            : fields?.coin?.symbol
                        } `}
                      </span>
                    </p>
                  </div>
                  {P2PorderPayload?.type === "SELL" && (
                    <p className=" text-[10px] text-green-500 text-left">
                      = {` `}
                      <NumericFormat
                        style={{}}
                        className=" text-[10px]"
                        decimalScale={2}
                        value={fundingAmout}
                        displayType="text"
                        thousandSeparator
                      />
                    </p>
                  )}
                </div>
              </div>
              <div className=" flex justify-between items-center mt-3">
                <p className="text-textPrime tracking-[-0.2px] leading-[145%] text-[14px] text-left opacity-50">
                  {/* You  */}
                  {getLocale("you")}
                  {!byWhich ? "Receive" : "Pay"}
                </p>
                <p className="text-textPrime tracking-[-0.2px] leading-[145%] text-[14px]  text-right opacity-100 flex items-center gap-1">
                  <span>
                    <NumericFormat
                      decimalScale={2}
                      displayType="text"
                      value={
                        byWhich
                          ? P2PorderPayload?.totalAmountCoin / fields?.price
                          : P2PorderPayload?.totalAmountCoin * fields?.price
                      }
                    />
                  </span>
                  -
                  <span>
                    {!byWhich
                      ? fields?.fiatCurrencyDetails?.name
                      : fields?.coin?.symbol}
                  </span>
                </p>
              </div>
              <div className=" flex justify-between items-center mt-3">
                <p className="text-textPrime tracking-[-0.2px] leading-[145%] text-[14px] text-left opacity-50">
                  {/* Processing Fee */}
                  {getLocale("processingFee")}
                </p>
                <p className="text-textPrime tracking-[-0.2px] leading-[145%] text-[14px] text-left opacity-100 flex items-center gap-1">
                  <span></span>-
                  <span>
                    {!byWhich
                      ? fields?.fiatCurrencyDetails?.name
                      : fields?.coin?.symbol}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className=" rounded-md border px-2 py-2 mt-4">
            <div>
              <div className=" mt-3">
                <div className="mb-1 w-full">
                  <label className="block text-sm font-medium mb-1 text-left">
                    {/* Add Payment Method */}
                    {getLocale("addPaymentMethod")}
                  </label>
                  <div
                    onClick={() => setCurrFilter(true)}
                    className={`w-full cursor-pointer  border ${
                      error?.paymentMethods
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-lg p-2  h-[45px] flex justify-between items-center relative `}
                  >
                    {P2PorderPayload?.paymentMethods ? (
                      <span className=" ">
                        {
                          data?.find(
                            (item) =>
                              item?._id == P2PorderPayload?.paymentMethods
                          )?.type
                        }
                      </span>
                    ) : (
                      <span className=" ">
                        {/* Add Payment Method */}
                        {getLocale("addPaymentMethod")}
                      </span>
                    )}
                    <IconCaretDownFilled />
                  </div>
                </div>
                {error?.paymentMethods && (
                  <p className=" text-[10px] text-red-500 text-left">
                    {error?.paymentMethods}
                  </p>
                )}
              </div>
              <div className=" flex justify-between items-center mt-3">
                <p className="text-textPrime tracking-[-0.2px] leading-[145%] text-[14px] text-left opacity-50">
                  {/* Payment Time Limit */}
                  {getLocale("paymentTimeLimit")}
                </p>
                <p className="text-textPrime tracking-[-0.2px] leading-[145%] text-[14px] text-left opacity-100 flex items-center gap-1">
                  {`${fields?.paymentTimeLimitMinutes} min`}
                </p>
              </div>
              <div className=" flex justify-between items-center mt-3">
                <p className=" flex items-center gap-1 text-textPrime tracking-[-0.2px] leading-[145%] text-[14px] text-left opacity-50">
                  {/* Additional Verification */}
                  {getLocale("additionalVerify")}
                  <IconInfoCircle size={20} />
                </p>
                <p className="text-textPrime tracking-[-0.2px] leading-[145%] text-[14px] text-left opacity-100 flex items-center gap-1">
                  Required
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className=" absolute bottom-[10px] left-0 w-full flex justify-center px-4  ">
          <button
            onClick={() => {
              const error = validatePayoad(
                P2PorderPayload,
                fields,
                byWhich,
                fundingAmout,
                fields?.type === "SELL"
              );
              setError({ ...error.errors });
              if (error.isvalid) {
                setIsConfrim(true);
              }
            }}
            className="  w-full rounded-md m-auto py-5 text-center flex items-center justify-center gap-1 text-textWhite bottom-[0px] left-0  bg-bgBlue"
          >
            <span>
              {/* Place Order */}
              {getLocale("placeOrder")}
            </span>
          </button>
        </div>
      </div>
      <ChoosePayMethod
        data={data}
        currFilter={currFilter}
        setCurrFilter={setCurrFilter}
      />
      <Confrim
        placeOrder={() => placeOrder()}
        currFilter={isConfrim}
        setCurrFilter={setIsConfrim}
      />
    </div>
  );
}

export default BuyP2P;
