import React, { useState } from "react";
import {
  IconEye,
  IconEyeOff,
  IconLockFilled,
  IconX,
} from "@tabler/icons-react";
import { CustomInput } from "../Core/input";
import DropDown from "../Core/DropDown";
import { useLocation, useNavigate } from "react-router-dom";
import stepImage from "../../Assets/image/step2Complete.png";
import { Autocomplete, TextField } from "@mui/material";
import { countries } from "../Core/Countries";
import { addKycNation } from "../../service/api";
import { Danger, Success } from "../Core/Errors";
import Loader from "../Core/Loader";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";
// import { getLocale } from "../../constants/language";

const Citizenship = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [nationality, setNationality] = useState(countries[2]);
  const [error, setError] = useState(false);
  const email = useLocation().state?.email;
  console.log("nationality: ", nationality);
  const handleSubmit = () => {
    if (!nationality) {
      setError(true);
      return;
    }
    try {
      setLoading(true);
      addKycNation({ nationality: nationality })
        .then((resp) => {
          console.log("resp: ", resp);
          if (resp.status) {
            Success(resp?.message).then((_) => {
              navigate(ROUTES.ADDRESS, { state: { email: email } });
            });
          }
        })
        .catch((error) => {
          console.log("error: ", error?.response?.data?.message);
          Danger(error?.response?.data?.message);
          setLoading(false);
        })
        .finally((res) => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col justify-between">
      {loading && <Loader />}
      <div>
        <div className=" flex justify-between items-center">
          <IconX
            className="size-7 cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <img src={stepImage} alt="step-top" />
          <p></p>
        </div>

        <div className="flex items-start flex-col w-full mt-6">
          <div>
            <h3 className="text-2xl font-medium text-textDefault">
              {/* Enter your nationality */}
              {getLocale("enterNationality")}
            </h3>
            <p className="text-[#707070] text-[16px] space-x-[0.02px] text-left">
              {/* Please select your nationality */}
              {getLocale("nationalityInfo")}
            </p>
          </div>
          <div className="form-control w-full mt-[50px]">
            <label className="block text-left text-textDefault text-base font-medium pb-2">
              {/* Nacionality */}
              {getLocale("nationality")}
            </label>
            <Autocomplete
              sx={{
                ".MuiOutlinedInput-root": {
                  borderColor: error ? "red" : "",
                  borderWidth: 1,
                },
              }}
              value={nationality}
              options={countries}
              disableCloseOnSelect
              onChange={(e, value) => {
                setError(false);
                setNationality(value?.code ? value?.code : "");
              }}
              getOptionLabel={(option) =>
                `${option.label} (${option.code}) +${option.phone}`
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="Nationality" />
              )}
            />
          </div>
        </div>
      </div>
      <div>
        <div>
          <div className=" flex items-center gap-4">
            <IconLockFilled size={50} />
            <p className=" text-[#707070] text-left mt-4">
              {/* We use 128-bit encryption for added security, and this information
              is used solely for identity verification purposes. */}
              {getLocale("personalInfo")}
            </p>
          </div>
        </div>
        <button
          onClick={handleSubmit}
          className="w-full p-4 bg-bgBlue text-white rounded-lg mt-10 text-base"
        >
          {/* Continue */}
          {getLocale("continue")}
        </button>
      </div>
    </div>
  );
};

export default Citizenship;
