import React, { useState } from "react";
import { Alert, IconButton, Stack } from "@mui/material";
import { IconX } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";

function KYCAlert() {
  const [open, setOpen] = useState(true);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
  };

  const navigateKyc = () => {
    if (user?.kycStatus === "REJECTED") {
      navigate(ROUTES.SECURITY_ID);
    } else {
      if (user?.kycLevel === 1) {
        navigate(ROUTES.SECURITY_ID);
      } else if (user?.kycLevel === 2) {
        navigate(ROUTES.PERSONAL_INFO);
      } else if (user?.kycLevel === 3) {
        navigate(ROUTES.S_PHOTO_ID);
      }
    }
  };

  // APPROVED = "APPROVED",
  // PENDING = "PENDING",
  // REJECTED = "REJECTED",
  // IN_PROCESS = "IN-PROCESS"

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      {open && (
        <Alert
          severity="warning"
          sx={{ alignItems: "center" }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <IconX onClick={() => setOpen(false)} />
            </IconButton>
          }
          icon={false}
        >
          {getLocale("yourkyc")}{" "}
          {user?.kycStatus === "PENDING"
            ? "Pending"
            : user?.kycStatus === "IN-PROCESS"
            ? "Incomplete"
            : user?.kycStatus === "REJECTED"
            ? "Rejected"
            : "Completed"}
          .&nbsp;
          <span
            className="font-[700] cursor-pointer underline"
            onClick={navigateKyc}
          >
            {user?.kycStatus === "REJECTED"
              ? getLocale("rekyc")
              : getLocale("kyc")}
          </span>
        </Alert>
      )}
    </Stack>
  );
}

export default KYCAlert;
