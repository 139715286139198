import React, { useState } from "react";
import PopUp from "./PopUp";
import { IconChevronLeft, IconSend2 } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/user/actions";
import { getLocale } from "../../../constants/language";

function ChatModal({
  setCurrFilter,
  currFilter,
  addMessage,
  messages,
  getMessage,
}) {
  const [message, setMessage] = useState("");
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  return (
    <PopUp
      reload={getMessage}
      open={currFilter}
      height={100}
      setOpen={setCurrFilter}
      title={"Chat"}
    >
      <div className="border-t">
        <div className=" mt-2 px-4 text-left text-textPrime opacity-[0.7]">
          <p>
            {/* Chat here */}
            {getLocale("chatHere")}
          </p>
        </div>

        <div className=" flex flex-col justify-between h-full relative min-h-[88vh]">
          <div className=" w-full mt-4 space-y-4 max-h-[80vh] py-10 overflow-y-auto no-scrollbar">
            {messages?.map((msg, index) => {
              const isCurrentUser = msg.sender === user?._id;
              return (
                <div
                  key={index}
                  className={`flex w-full items-start px-2 ${
                    isCurrentUser
                      ? "justify-end text-right"
                      : "justify-start text-left"
                  } space-x-2 h-auto`}
                >
                  <div
                    className={` px-2 py-1 h-auto rounded-md max-w-xs bg-gray-200 text-black text-right`}
                  >
                    <p
                      className={`" text-wrap ${
                        isCurrentUser ? " text-right" : "text-left"
                      } h-full break-words `}
                    >
                      {msg.message}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className=" flex items-center gap-1 px-1 ">
            <div className=" w-10 h-[50px] flex items-center justify-center cursor-pointer shadow-md">
              <IconChevronLeft
                title="Back"
                size={30}
                onClick={() => {
                  setCurrFilter(false);
                  dispatch(actions.removeChatId(null));
                }}
              />
            </div>
            <div className=" w-full h-[50px] pr-2 border flex bg-bgWhite gap-2 items-center justify-between rounded-md overflow-hidden ">
              <input
                onKeyDown={(e) => {
                  if (e.key === "Enter" && message?.length > 0) {
                    addMessage(message);
                    setMessage("");
                  }
                }}
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                type="text"
                className=" w-full h-full border-none outline-none pl-2"
              />
              <IconSend2
                size={30}
                className=" cursor-pointer"
                onClick={() => {
                  if (message?.length > 0) {
                    addMessage(message);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </PopUp>
  );
}

export default ChatModal;
