import { IconChevronLeft, IconLockFilled, IconX } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import stepImage from "../../Assets/image/step2Complete.png";
import * as yup from "yup";
import { useFormik } from "formik";
import { resendEmail, verifyEmail } from "../../service/api";
import { Danger, Success } from "../Core/Errors";
import { getLocale } from "../../constants/language";

import { CustomInput } from "../Core/input";
import Loader from "../Core/Loader";
import { ROUTES } from "../../constants/routes";
const validationSchema = yup.object().shape({
  code: yup.number().required("Code is required.").min(6),
});
function ConfirmTwoFa() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [loading, setLoading] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const email = useLocation().state?.email;
  const isFrogot = Boolean(useLocation().state?.isForgot);
  // console.log('isFrogot: ', isFrogot);
  const formik = useFormik({
    initialValues: {
      code: "",
      email: email,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const body = {
        code: values?.code.toString(),
        email: values?.email,
      };
      setLoading(true);
      try {
        verifyEmail(body)
          .then((res) => {
            console.log("responce: ", res);
            if (res?.status) {
              Success("Verification Successfull").then((_) => {
                if (isFrogot) {
                  if (search === "?forgot") {
                    navigate(ROUTES.FORGOT_PASSWORD, {
                      state: { email: email },
                    });
                  } else {
                    navigate(ROUTES.PROFILE);
                  }
                } else {
                  navigate(ROUTES.TWO_FA, { state: { email: email } });
                }
              });
            } else {
              Danger(res?.message);
            }
          })
          .catch((error) => {
            console.log("error: ", error);
            Danger(error?.response?.data?.message);
            setLoading(false);
          })
          .finally((res) => {
            console.log("resfinal: ", res);

            setLoading(false);
          });
      } catch (error) {
        console.log("errorcatch: ", error);
      }
    },
  });

  const handleResendCode = () => {
    try {
      setLoading(true);
      resendEmail({ email: email })
        .then((resp) => {
          console.log("resp: ", resp);
          if (resp.status) {
            if (!isFirst) {
              Success(resp?.message);
            }
          }
        })
        .catch((error) => {
          setLoading(false);
        })
        .finally((res) => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (search === "?forgot") handleResendCode();
  }, [search]);

  return (
    <div>
      {loading && <Loader />}
      <form
        onSubmit={formik.handleSubmit}
        className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col justify-between"
      >
        <div>
          <div className=" flex justify-between items-center">
            <IconX
              className="size-7 cursor-pointer"
              onClick={() => navigate(-1)}
            />
            <img src={stepImage} alt="step-top" />
            <p></p>
          </div>
          <div className=" mt-9 flex justify-center flex-col w-full ">
            <h2 className=" text-[22px] font-medium text-left leading-[145%] tracking-[-1px] ">
              {/* Enter the verification code */}
              {getLocale("verifycode")}
            </h2>
            <p className="text-left text-[#707070] text-[16px] font-normal tracking-[0.02px] mt-1 leading-[140%]">
              {/* Enter the 6-digit code we just sent to your email. */}
              {getLocale("verifycodeInfo")}
            </p>

            <div className=" flex justify-start flex-col items-start mt-5">
              <label
                htmlFor=""
                className=" text-left text-[#2752E7] leading-[140%] tracking-normal font-medium mb-1"
              >
                {/* Code */}
                {getLocale("code")}
              </label>
              <CustomInput
                pattern={"[0-9]*"}
                placeholder="CODE"
                type="number"
                name="code"
                id="code"
                value={formik?.values?.code}
                onChange={formik.handleChange}
                error={formik.errors.code && formik.touched.code ? true : false}
              />
            </div>
          </div>
        </div>
        <div className=" mt-6">
          <button
            type="submit"
            //  onClick={() => navigate("/home")}
            disabled={loading}
            className="w-full p-4 bg-bgBlue text-white rounded-lg mt-2 text-base"
          >
            {/* Continue */}
            {getLocale("continue")}
          </button>
          <button
            onClick={() => {
              setIsFirst(false);
              handleResendCode();
            }}
            disabled={loading}
            className="w-full p-4 bg-white text-[#111111] rounded-lg mt-2 text-base border border-[#CFCFCF]"
          >
            {/* I didn’t recieve any code */}
            {getLocale("didntCode")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ConfirmTwoFa;
