export const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  LANDING: "/home",
  SECURITY_INFO: "/securityInfo",
  SECURITY_ID: "/securityId",
  S_PHOTO_ID: "/sPhotoId",
  FACE_ID: "/faceId",
  PERSONAL_INFO: "/personalInfo",
  CITIZENSHIP: "/citizenship",
  ADDRESS: "/address",
  PURPOSE: "/purpose",
  WALLET: "/wallet",
  DEPOSIT: "/deposit",
  WITHDRAW: "/withdraw",
  EXPECTATIONS: "/expectations",
  SUCCESS_REGISTER: "/successRegister",
  ONBOARDING: "/onboarding",
  ONBOARDING_1: "/onboarding1",
  NOTIFICATIONS: "/notification",
  REFER: "/refer",
  CONVERT: "/convert",
  RECEIVE: "/recieve",
  MARKET: "/market",
  SECURITY: "/security",
  SECURITY_STEPS: "/securitySteps",
  SECURITY_EMAIL: "/securityEmail",
  TWO_FA: "/twoFA",
  CONFIRM_TWO_FA: "/confirmTwoFa",
  CONFIRM_TWO_FA_MOB: "/confirmTwoFaMob",
  CONFIRM_TWO_FA_MOB_KYC: "/confirmTwoFaMobKYC",
  GENERAL: "/general",
  TRANSACTION: "/transaction",
  COIN: "/coin",
  COINHISTORY: "/Coin-History",
  PAIRCOIN: "/PairOrder",
  CRYPTO_INFO: "/cryptoInfo",
  PROFILE: "/profile",
  NOTIFICATION_SETTING: "/notificationSetting",
  SELECT_ASSETS_TO_BUY: "/selectAssets",
  BUY: "/buy",
  BUYPAIR: "/buyPair",
  ADD_PAYMENT_METHOD: "/addPaymentMethod",
  ORDER_SUMMARY: "/orderSummary",
  BUY_SENT: "/buySent",
  ORDER_STATUS: "/orderStatus/:id",
  PAIR_ORDER_STATUS: "/PairOrderStatus/:id",
  EMAIL_VERIFIY: "/emailVerify",
  FORGOT_PASSWORD: "/forgotPassword",
  CHANGE_PASSWORD: "/changePassword",
  P2PHOME: "/P2PHOME",
  P2PORDER: "/P2POrder",
  P2PADDNEW: "/P2PAddNew",
  PAYMENTMETHODS: "/paymentMethods",
  RECOMENDED: "/recomended",
  ADDPAYMETHOD: "/addPayMethod",
  EDITPAYMETHOD: "/editPayMethod",
  BUYP2P: "/buyP2P",
  VERIFYORDER: "/verifyOrder",
  VERIFYORDERTOSELL: "/verifyOrderToSell",
  MYADDS: "/myAdds",
  SELECTMETHOD: "/selectMethod",
};
