import React, { useEffect, useState } from 'react'
import { getAllCoins, getAllCurrency } from '../../../service/api'
import PopUp from './PopUp'
import { IconCheck, IconSearch } from '@tabler/icons-react'
import { Danger } from '../../Core/Errors'
import { getLocale } from '../../../constants/language'
const paymentTypes = [
    { name: "au PAY", color: "bg-orange-500", id: 1 },   // Tailwind class for #FA6400 (orange)
    { name: "Absa Bank", color: "bg-red-500", id: 2 },   // Tailwind class for #F53333 (red)
    { name: "AlinmaPay", color: "bg-blue-500", id: 3 },  // Tailwind class for #1A73E8 (light blue)
    { name: "Al Barid Bank", color: "bg-yellow-500", id: 4 }, // Tailwind class for #FFC700 (yellow)
    { name: "Azercell", color: "bg-purple-500", id: 5 }, // Tailwind class for #6F47F2 (purple)
    { name: "Algérie poste - CCP", color: "bg-blue-500", id: 6 }, // Tailwind class for #1A73E8 (light blue)
    { name: "Algérie poste - Baridimob", color: "bg-purple-500", id: 7 }, // Tailwind class for #6F47F2 (purple)
    { name: "Alkuraimi Bank", color: "bg-purple-500", id: 8 }, // Tailwind class for #6F47F2 (purple)
    { name: "ATMoney", color: "bg-red-500", id: 9 },   // Tailwind class for #F53333 (red)
    { name: "Artsakhbank", color: "bg-blue-500", id: 10 },  // Tailwind class for #1A73E8 (light blue)
]
function Tags({ setCurrFilter, currFilter, selectedCoin, setSelectedCoin }) {

    const checkedOrNot = (id) => {
        return selectedCoin?.find(item => item.id === id) ? true : false
    }
    return (
        <PopUp open={currFilter} setOpen={setCurrFilter} title={"All Tags"}>
            <div className=' px-4'>
                <div className=' flex justify-between items-center w-full '>
                    <div className=' w-[80%] bg-bgGray items-center rounded-md h-[30px] flex justify-between gap-1 pl-1'>
                        <IconSearch size={20} />
                        <input type="text" className=' w-full h-full border-none outline-none placeholder:opacity-[0.5] bg-transparent ' placeholder='Search payment method' />
                    </div>
                    <p onClick={() => {
                        setCurrFilter(false)
                        setSelectedCoin({ _id: -1 })
                    }} className=' text-textPrime cursor-pointer text-[16px] tracking-[1.02px] leading-[140%]'>
                        {/* Cancel */}
                        {getLocale("cancel")}
                        </p>
                </div>
                <div className=' mt-5 max-h-[73vh] overflow-y-auto pb-12 no-scrollbar'>
                    <p className='font- opacity-50 text-left mt-5 leading-[145%] tracking-[1px] text-[16px] text-[#111111]'>
                        {/* Select Tags */}
                        {getLocale("selectTag")}
                        </p>
                    {
                        paymentTypes?.map((item, index) => (
                            <div onClick={() => {
                                const isExist = checkedOrNot(item?.id)
                                if (isExist) {
                                    setSelectedCoin(prev => prev?.filter(val => val?.id !== item?.id))

                                } else {
                                    setSelectedCoin(prev => [...prev, { ...item }])

                                }
                            }} key={`pay-${index}`} className="rounded-md bg-opacity-10 w-full cursor-pointer mt-2 flex items-center justify-center py-4 mx-auto active:bg-[#ccc] border pl-2">
                                <input checked={checkedOrNot(item?.id)} type="checkbox" name="" id="" className=' scale-[1.3]' />
                                <div className="text-[#111] text-left ml-3 text-base mr-auto flex h-full items-center gap-0.5">
                                    <div className={`${item.color} w-[2px] h-5`}></div>
                                    <p>{item.name}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div onClick={() => setCurrFilter(false)} className=' px-4 w-full absolute bottom-0 left-0 justify-center flex items-center '>
                    <button className=' w-[95%]  text-clip absolute bottom-0 bg-bgBlue text-textWhite py-2 rounded-md tracking-[0.2px] leading-[140%] mb-2'>Add</button>
                </div>
            </div>
        </PopUp>
    )
}

export default Tags