import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import "react-phone-number-input/style.css";
import { generateToken } from "../src/firebase";
import { BrowserRouter } from "react-router-dom";
import './i18n'; // Initialize i18n
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);
generateToken();
reportWebVitals();
