import {
  IconArrowNarrowLeft,
  IconArrowsSort,
  IconCreditCard,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getWallet, placePairOrder } from "../../service/api";
import { Danger } from "../Core/Errors";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";

function SpotBuy() {
  const navigate = useNavigate();
  const { activePair } = useSelector((state) => state.user);
  const [isBuyActive, setIsBuyActive] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isPair, setIsPair] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [limit, setLimit] = useState(activePair?.currentPrice);
  const [balance, setBalance] = useState();
  const [amount, setAmount] = useState("");
  const [err, setErr] = useState({ limitErr: false, amountErr: false });
  const [selectedOrderType, setSelectedOrderType] = useState("LIMIT");
  const [selectedCoin, setSelectedCoin] = useState(activePair?.toCoin?.symbol);

  const handleSelect = (type) => {
    setSelectedOrderType(type);
    setIsOpen(false);
  };
  const handleSelectCoin = (type) => {
    setSelectedCoin(type);
    setIsPair(false);
  };

  const getWalletBalance = () => {
    try {
      getWallet({ walletType: "SPOT" })
        .then((res) => {
          let balanceData = res?.data[0]?.data;
          if (isBuyActive) {
            let blc =
              balanceData &&
              balanceData?.length > 0 &&
              balanceData?.find(
                (obj) => activePair?.toCoin?._id === obj?.coin?._id
              );

            setBalance(blc ? blc?.amount : 0);
          } else {
            let blc =
              balanceData &&
              balanceData?.length > 0 &&
              balanceData?.find(
                (obj) => activePair?.fromCoin?._id === obj?.coin?._id
              );
            setBalance(blc ? blc?.amount : 0);
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const handleOrder = () => {
    if (selectedOrderType === "LIMIT" && limit <= 0) {
      setErr({ ...err, limitErr: true });
      Danger("Limit price must be greater than zero");
      return;
    } else if (
      selectedOrderType === "LIMIT" &&
      isBuyActive &&
      limit > activePair?.currentPrice
    ) {
      setErr({ ...err, limitErr: true });
      Danger("Limit price must be less than current price");
      return;
    } else if (
      selectedOrderType === "LIMIT" &&
      !isBuyActive &&
      limit < activePair?.currentPrice
    ) {
      setErr({ ...err, limitErr: true });
      Danger("Limit price must be greater than current price");
      return;
    } else if (!amount || amount <= 0) {
      setErr({ ...err, amountErr: true });
      Danger(getLocale("greaterThen"));
      return;
    } else if (selectedOrderType === "LIMIT" && isBuyActive) {
      if (selectedCoin === activePair?.toCoin?.symbol && balance < amount) {
        setErr({ ...err, amountErr: true });
        Danger(getLocale("noBalance"));
        return;
      } else if (
        selectedCoin === activePair?.fromCoin?.symbol &&
        balance / +activePair?.currentPrice < amount
      ) {
        setErr({ ...err, amountErr: true });
        Danger(getLocale("noBalance"));
        return;
      }
    } else if (selectedOrderType === "LIMIT" && !isBuyActive) {
      if (selectedCoin === activePair?.fromCoin?.symbol && balance < amount) {
        setErr({ ...err, amountErr: true });
        Danger(getLocale("noBalance"));
        return;
      } else if (
        selectedCoin === activePair?.toCoin?.symbol &&
        balance * +activePair?.currentPrice < amount
      ) {
        setErr({ ...err, amountErr: true });
        Danger(getLocale("noBalance"));
        return;
      }
    }
    let body = {
      pairId: activePair?._id,
      type: selectedOrderType,
      orderType: isBuyActive ? "BUY" : "SELL",
      // price:
      //   selectedOrderType === "LIMIT"
      //     ? Number(limit)
      //     : Number(activePair?.currentPrice),
      // amount:
      //   selectedCoin === activePair?.fromCoin?.symbol ? amount : limit * amount,
      // quantity:
      //   selectedCoin === activePair?.toCoin?.symbol ? amount : amount / limit,
    };
    if (selectedOrderType === "LIMIT") {
      body.amount =
        selectedCoin === activePair?.toCoin?.symbol
          ? Number(amount)
          : Number(limit * amount);
      body.quantity =
        selectedCoin === activePair?.fromCoin?.symbol
          ? Number(amount)
          : Number(amount / limit);
      body.price = Number(limit);
    } else {
      if (selectedCoin === activePair?.toCoin?.symbol) {
        body.amount = Number(amount);
      } else {
        body.quantity = Number(amount);
      }
    }

    setAddLoading(true);
    placePairOrder(body)
      ?.then((res) => {
        if (res?.status) {
          setLimit(activePair?.currentPrice);
          // setAmount("");
          navigate(ROUTES.PAIRCOIN);
        } else {
          Danger(res?.message);
        }
      })
      .catch((e) => {
        Danger(e?.response?.data?.message);
      })
      .finally(() => setAddLoading(false));
  };
  useEffect(() => {
    getWalletBalance();
  }, [isBuyActive]);

  return (
    <div>
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 pt-12 pb-3 flex flex-col">
        <div className=" flex justify-between items-center">
          <IconArrowNarrowLeft
            className="size-7 cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h1 className="font-medium leading-[145%] tracking-[-1px] text-[18px] text-[#111111] ">
            <span>
              {activePair?.fromCoin?.symbol}/{activePair?.toCoin?.symbol}
            </span>
          </h1>
          <p></p>
        </div>

        <div className="flex w-[95%] mx-auto mt-6 border border-gray-300 rounded-full overflow-hidden">
          <button
            onClick={() => {
              setIsBuyActive(true);
              setSelectedCoin(activePair?.toCoin?.symbol);
            }}
            className={`py-2 ${
              isBuyActive ? "bg-green-500 text-white" : "text-gray-500"
            } font-semibold flex-1 rounded-l-full`}
          >
            {/* Buy */}
            {getLocale("buy")}
          </button>
          <button
            onClick={() => {
              setIsBuyActive(false);
              setSelectedCoin(activePair?.fromCoin?.symbol);
            }}
            className={`py-2 ${
              !isBuyActive ? "bg-red-500 text-white" : "text-gray-500"
            } font-semibold flex-1 rounded-r-full`}
          >
            {/* Sell */}
            {getLocale("sell")}
          </button>
        </div>

        <div className="relative inline-block w-[95%] mt-4 mx-auto">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="w-full p-4 text-left bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none"
          >
            {selectedOrderType}
            <span className="float-right rotate-180">^</span>
          </button>

          {isOpen && (
            <div className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg text-sm">
              <button
                onClick={() => {
                  handleSelect("LIMIT");
                  setLimit(activePair?.currentPrice);
                  setErr({ ...err, limitErr: false });
                }}
                className="w-full px-4 py-2 text-left hover:bg-gray-100"
              >
                {/* Limit */}
                {getLocale("limit")}
              </button>
              <button
                onClick={() => {
                  handleSelect("MARKET");
                  setLimit(activePair?.currentPrice);
                }}
                className="w-full px-4 py-2 text-left hover:bg-gray-100"
              >
                {/* Market */}
                {getLocale("market")}
              </button>
            </div>
          )}
        </div>

        <div className="inline-block w-[95%] mt-4 mx-auto">
          {selectedOrderType === "MARKET" ? (
            <div className="w-full rounded-md bg-gray-300 text-black opacity-70 py-4 border border-gray-300">
              $
              {activePair?.currentPrice
                ? activePair?.currentPrice
                : "Market Price"}
            </div>
          ) : (
            <div
              className={`w-full rounded-md relative bg-white text-black py-4 border ${
                err?.limitErr ? "border-red-500" : "border-gray-300"
              } overflow-hidden`}
            >
              <button
                onClick={() => {
                  setLimit(limit === 0 ? 0 : limit - 1);
                  setErr({ ...err, limitErr: false });
                }}
                className="text-3xl absolute left-0 pb-2 border-r border-gray-300 bg-gray-700 px-4 text-white inset-y-0 flex items-center focus:outline-none hover:bg-opacity-80"
              >
                -
              </button>

              <input
                type="number"
                placeholder="Price"
                value={limit}
                onChange={(e) => {
                  setLimit(Number(e.target.value));
                  setErr({ ...err, limitErr: false });
                }}
                className="w-full px-10 text-lg text-center focus:outline-none"
              />

              <button
                onClick={() => {
                  setLimit(
                    limit >= activePair?.currentPrice
                      ? activePair?.currentPrice
                      : limit + 1
                  );
                  setErr({ ...err, limitErr: false });
                }}
                className="text-3xl absolute right-0 inset-y-0 border-l border-gray-300 bg-gray-700 flex items-center px-4 text-white focus:outline-none hover:bg-opacity-80"
              >
                +
              </button>
            </div>
          )}
        </div>

        <div
          className={`w-[95%] mx-auto rounded-md flex justify-center mt-4 items-center gap-2 bg-white text-black border ${
            err?.amountErr ? "border-red-500" : "border-gray-300"
          }`}
        >
          <input
            type="number"
            placeholder={"Total"}
            value={amount}
            onChange={(e) => {
              setAmount(Number(e.target.value));
              setErr({ ...err, amountErr: false });
            }}
            className="w-[70%] px-10 text-lg text-center focus:outline-none"
          />

          <div className="relative pl-3 my-2 border-l border-gray-700 inline-block w-[30%] mx-auto ">
            <button
              onClick={() => setIsPair(!isPair)}
              className="w-full py-2 text-left bg-white rounded-md focus:outline-none"
            >
              {selectedCoin}
              <span className="float-right absolute right-2 rotate-180">^</span>
            </button>

            {isPair && (
              <div className="absolute z-10 right-0 w-full bg-white border border-gray-300 text-black text-sm text-opacity-70 rounded-md shadow-lg">
                <button
                  onClick={() => handleSelectCoin(activePair?.fromCoin?.symbol)}
                  className="w-full px-4 py-2 text-left hover:bg-gray-100"
                >
                  {activePair?.fromCoin?.symbol}
                </button>
                <button
                  onClick={() => handleSelectCoin(activePair?.toCoin?.symbol)}
                  className="w-full px-4 py-2 text-left hover:bg-gray-100"
                >
                  {activePair?.toCoin?.symbol}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="mt-4 w-[95%] mx-auto">
          <div className="flex justify-between items-center w-full my-2">
            <label className="text-gray-600 text-sm font-medium opacity-70">
              {/* Available */}
              {getLocale("available")}
            </label>
            <label className="text-gray-800 text-sm font-medium opacity-100">
              {balance}{" "}
              {isBuyActive
                ? activePair?.toCoin?.symbol
                : activePair?.fromCoin?.symbol}
            </label>
          </div>
          <div className="flex justify-between items-center w-full my-2">
            <label className="text-gray-600 text-sm font-medium opacity-70">
              {/* Max {isBuyActive ? "Buy" : "Sell"} */}
              {/* Max Buy */}
              {getLocale("max")}
              {getLocale("buy")}
            </label>
            <label className="text-gray-800 text-sm font-medium opacity-100">
              {isBuyActive
                ? balance / +activePair?.currentPrice
                : balance * +activePair?.currentPrice}{" "}
              {!isBuyActive
                ? activePair?.toCoin?.symbol
                : activePair?.fromCoin?.symbol}
            </label>
          </div>
          <div className="flex justify-between items-center w-full my-2">
            <label className="text-gray-600 text-sm font-medium opacity-70">
              {/* Max {isBuyActive ? "Buy" : "Sell"} */}
              Est. {getLocale("fee")}
            </label>
            <label className="text-gray-800 text-sm font-medium opacity-100">
              0
            </label>
          </div>
        </div>

        <button
          onClick={handleOrder}
          disabled={addLoading}
          className={`py-2 ${
            !isBuyActive ? "bg-red-500 text-white" : " bg-green-500 text-white"
          } font-semibold w-[95%] mt-4 rounded-md mx-auto`}
        >
          {addLoading
            ? "Loading..."
            : !isBuyActive
            ? `Sell ${activePair?.fromCoin?.symbol}`
            : `Buy ${activePair?.fromCoin?.symbol}`}
        </button>
      </div>
    </div>
  );
}

export default SpotBuy;
