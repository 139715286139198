import {
  IconArrowNarrowLeft,
  IconTrashFilled,
  IconX,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { getLocale } from "../../../constants/language";

import { useLocation, useNavigate } from "react-router-dom";
import {
  addPayMethodByUser,
  deletePayMethodByUser,
  editPayMethodByUser,
  getAllCoins,
  getPayMethodAddedByUser,
  getPaymethodById,
} from "../../../service/api";
import { Confrim, Danger, Success } from "../../Core/Errors";
import Loader from "../../Core/Loader";
import CurrencySlider from "./CurrencySlider";
import { CustomInput } from "../../Core/input";
import { useFormik } from "formik";
import * as yup from "yup";

// const validationSchema = yup.object().shape({
//     name: yup.string().required("name is required"),
//     upiId: yup.string().required("UPI id is required."),
// });
function EditPaymethod() {
  const [validationSchema, setValidationSchema] = useState(
    yup.object().shape({})
  );
  const [initValues, setInitValues] = useState({});

  const payId = useLocation().state?.payId;
  console.log("payId: ", payId);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [fields, setFields] = useState({});
  console.log("fields: ", fields);
  const formShape = () => {
    const schemaShape = {};
    const initialValues = {};
    Object.entries(fields?.fields ?? {}).forEach(([field, rules]) => {
      let validator;
      // Define the type of validation based on the type specified
      if (typeof rules === "string")
        validator = yup.string().required(`${field} is required`);
      if (typeof rules === "number")
        validator = yup.number().required(`${field} is required`);
      initialValues[field] = rules; // Initialize with empty values
      schemaShape[field] = validator; // Build schema shape
    });
    setInitValues(initialValues);
    setValidationSchema(yup.object().shape(schemaShape));
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoader(true);
      try {
        editPayMethodByUser({ details: { ...values } }, fields?._id)
          .then((res) => {
            Success(res?.message).then((_) => {
              navigate("/paymentMethods");
            });
            setLoader(false);
          })
          .catch((error) => {
            Danger(error?.response?.data?.message);
            setLoader(false);
          });
      } catch (error) {
        Danger(error?.response?.data?.message);
        setLoader(false);
      }
    },
  });

  const handleDelet = () => {
    Confrim().then(async (value) => {
      console.log("value: ", value);
      switch (value) {
        case "cancel":
          break;
        case "catch":
          setLoader(true);
          try {
            deletePayMethodByUser(payId)
              .then((res) => {
                Success(res?.message).then((_) => {
                  navigate("/paymentMethods");
                });
                setLoader(false);
              })
              .catch((error) => {
                Danger(error?.response?.data?.message);
                setLoader(false);
              });
          } catch (error) {
            Danger(error?.response?.data?.message);
            setLoader(false);
          }
          break;

        default:
          break;
      }
    });
  };

  const getPaymentAddedById = () => {
    try {
      setLoader(true);
      getPayMethodAddedByUser(payId)
        .then((res) => {
          setFields({ fields: res?.data?.details, ...res?.data });
          setLoader(false);
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoader(false);
        });
    } catch (error) {
      Danger(error?.response?.data?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (payId) {
      getPaymentAddedById();
    }
  }, []);

  useEffect(() => {
    if (fields) formShape();
  }, [fields]);
  return (
    <div>
      {loader && <Loader />}
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col ">
        <div>
          <div className=" flex justify-between items-center">
            <IconArrowNarrowLeft
              className="size-7 cursor-pointer"
              onClick={() => navigate("/recomended")}
            />
            <h1 className="font-medium leading-[145%] tracking-[-1px] text-[18px] text-textPrime">
              {/* Edit  */}
              {getLocale("edit")}
              {fields?.type}
            </h1>
            <IconTrashFilled
              onClick={handleDelet}
              size={25}
              className=" text-red-600 cursor-pointer"
              title="Delete"
            />
          </div>
        </div>

        {fields && (
          <form onSubmit={formik.handleSubmit}>
            {Object.entries(fields?.fields ?? {}).map(([key, value], index) => (
              <div
                key={`input-${index}`}
                className=" flex flex-col justify-start mt-5"
              >
                <label
                  className=" text-left font-medium leading-[145%] tracking-[-1px] text-[16px] text-textPrime opacity-60"
                  htmlFor=""
                >
                  {key?.toUpperCase()}
                </label>
                <CustomInput
                  type={typeof value}
                  placeholder={value?.toUpperCase()}
                  className="w-full pr-10"
                  name={key}
                  id={key}
                  defaultValue={value}
                  value={formik?.values[key]}
                  onChange={formik.handleChange}
                  error={
                    formik.errors?.[key] && formik.touched?.[key] ? true : false
                  }
                />
              </div>
            ))}

            <div className=" flex flex-col justify-start mt-5">
              <label
                className=" text-left font-medium leading-[145%] tracking-[-1px] text-[16px] text-textPrime opacity-60"
                htmlFor=""
              >
                {/* Payment QR code (optional) */}
                {getLocale("paymentQR")}
              </label>
              <div className="flex items-center justify-center w-[40%] h-auto mt-1">
                <label
                  for="dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-34 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      {/* Upload */}
                      {getLocale("upload")}
                    </p>
                  </div>
                  <input id="dropzone-file" type="file" className="hidden" />
                </label>
              </div>
            </div>
            <div className=" px-4 w-full absolute bottom-0 left-0 justify-center flex items-center ">
              <button
                type="submit"
                className=" w-[95%]  text-clip absolute bottom-0 bg-bgBlue text-textWhite py-2 rounded-md tracking-[0.2px] leading-[140%] mb-2"
              >
                {/* Confirm */}
                {getLocale("confirm")}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default EditPaymethod;
