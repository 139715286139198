import React, { useEffect, useState } from "react";
import { Drawer, List, ListItem, ListItemText, Box } from "@mui/material"; // Import MUI components
import {
  IconArrowsSort,
  IconBrandCashapp,
  IconGraphFilled,
  IconHomeFilled,
  IconMenu2,
  IconSpeakerphone,
  IconWallet,
} from "@tabler/icons-react";
import { useLocation, useNavigate } from "react-router-dom";
import p2p from "../../Assets/icons/p2p.svg";
import history from "../../Assets/icons/History.svg";
import { getLocale } from "../../constants/language";

import { useTour } from "@reactour/tour";

const Navbar = ({ toggleDrawer, isP2P }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setIsOpen } = useTour();

  // useEffect(() => {
  //   setIsOpen(true);
  // }, []);

  return (
    <div>
      {isP2P ? (
        <div
          className="h-[80px] max-w-[425px] bg-white shadow-lg fixed bottom-0 left-0 right-0 mx-auto text-center pt-2"
          style={{ boxShadow: "0 -4px 10px rgba(0, 0, 0, 0.3)" }}
        >
          <div className="flex justify-around h-full items-center ">
            <div className=" flex flex-col items-center">
              <div
                className={` ${
                  pathname === "/P2PHOME" ? "opacity-100" : "opacity-60"
                } size-10 cursor-pointer hover:bg-lightGrey rounded-full p-2 flex justify-center items-center flex-col`}
              >
                <img
                  className=" w-full h-full"
                  src={p2p}
                  onClick={() => navigate("/P2PHOME")}
                />
              </div>
              <p className=" text-[10px] leading-normal tracking-wide">
                All Ads
              </p>
            </div>
            <div className=" flex flex-col items-center">
              <div
                className={` ${
                  pathname === "/P2POrder" ? "opacity-100" : "opacity-60"
                } size-10 cursor-pointer hover:bg-lightGrey rounded-full p-2 flex justify-center items-center flex-col`}
              >
                <img
                  className=" w-full h-full"
                  src={history}
                  onClick={() => navigate("/P2POrder")}
                />
              </div>
              <p className=" text-[10px] leading-normal tracking-wide">
                Pendings
              </p>
            </div>
            <div className=" flex flex-col items-center">
              <div>
                <IconHomeFilled
                  onClick={() => navigate("/home")}
                  className="size-11 text-white hover:text-gray-600 cursor-pointer hover:bg-lightGrey rounded-full p-2 bg-bgBlue"
                />
              </div>
              <p className=" text-[10px] leading-normal tracking-wide">Home</p>
            </div>
            <div className=" flex flex-col items-center">
              <div>
                <IconSpeakerphone
                  onClick={() => navigate("/myAdds")}
                  className={` ${
                    pathname === "/myAdds" ? "opacity-100" : "opacity-60"
                  } size-10 cursor-pointer hover:bg-lightGrey rounded-full p-2`}
                />
              </div>
              <p className=" text-[10px] leading-normal tracking-wide">
                My Ads
              </p>
            </div>
            <div className=" flex flex-col items-center">
              <div>
                <IconBrandCashapp
                  onClick={() => navigate("/paymentMethods")}
                  className={` ${
                    pathname === "/paymentMethods"
                      ? "opacity-100"
                      : "opacity-60"
                  } size-10 cursor-pointer hover:bg-lightGrey rounded-full p-2`}
                />
              </div>
              <p className=" text-[10px] leading-normal tracking-wide">Pay</p>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="h-[80px] max-w-[425px] bg-white shadow-lg fixed bottom-0 left-0 right-0 mx-auto text-center pt-2"
          style={{ boxShadow: "0 -4px 10px rgba(0, 0, 0, 0.3)" }}
        >
          <div className="flex justify-around h-full items-center ">
            <div className=" flex flex-col items-center">
              <div>
                <IconWallet
                  data-tour="dashboard"
                  onClick={() => navigate("/wallet")}
                  className={` ${
                    pathname === "/wallet" ? "opacity-100" : "opacity-60"
                  } size-10 cursor-pointer hover:bg-lightGrey rounded-full p-2`}
                />
              </div>
              <p className=" text-[10px] leading-normal tracking-wide">
                {/* Wallets */}
                {getLocale("wallets")}
              </p>
            </div>
            <div className=" flex flex-col items-center">
              <div>
                <IconHomeFilled
                  data-tour="connect-profile"
                  onClick={() => navigate("/home")}
                  className={` ${
                    pathname === "/home" ? "opacity-100" : "opacity-60"
                  } size-10 cursor-pointer hover:bg-lightGrey rounded-full p-2`}
                />
              </div>
              <p className=" text-[10px] leading-normal tracking-wide">Home</p>
            </div>
            <div className=" flex flex-col items-center">
              <div>
                <IconArrowsSort
                  data-tour="publish-post"
                  onClick={() => navigate("/market")}
                  className="size-11 text-white hover:text-gray-600 cursor-pointer hover:bg-lightGrey rounded-full p-2 rotate-90 bg-bgBlue"
                />
              </div>
              <p className=" text-[10px] leading-normal tracking-wide">
                {/* Market */}
                {getLocale("market")}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <div>
                <IconGraphFilled
                  // onClick={() => navigate("/P2PHome")}
                  data-tour="schedule-post"
                  className="opacity-60 size-10 cursor-pointer hover:bg-lightGrey rounded-full p-2 "
                />
              </div>
              <p className=" text-[10px] leading-normal tracking-wide">
                {/* Analytics */}
                {getLocale("analytics")}
              </p>
            </div>
            <div className=" flex flex-col items-center">
              <div>
                <IconMenu2
                  onClick={toggleDrawer}
                  data-tour="accounts-summary"
                  className="opacity-60 cursor-pointer size-10 hover:bg-lightGrey rounded-full p-2 "
                />
              </div>
              <p className=" text-[10px] leading-normal tracking-wide">
                {/* Menu */}
                {getLocale("menu")}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
