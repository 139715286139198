import { IconArrowNarrowLeft } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getPairOrderById, getTransactionById } from "../../service/api";
import moment from "moment/moment";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";

function PairOrderStatus() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { search } = useLocation();
  const [data, setData] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      setLoading(true);
      const api =
        search === "?Open" ? getPairOrderById(id) : getTransactionById(id);
      api
        ?.then((res) => {
          if (res?.status) {
            setData(search === "?Open" ? res?.data[0] : res?.data);
          }
        })
        .catch((e) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  const renderSkeleton = () => (
    <div className="px-4 mt-8">
      <div className="flex justify-between items-center">
        <div className="w-24 h-4 bg-gray-300 rounded animate-pulse"></div>
        <div className="w-32 h-4 bg-gray-300 rounded animate-pulse"></div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="h-full min-h-[100vh] relative bg-white font-sans pt-12 pb-3 flex flex-col justify-between ">
        <div>
          <div className="flex justify-between items-center px-4">
            <IconArrowNarrowLeft
              className="size-7 cursor-pointer"
              onClick={() => navigate(-1)}
            />
            <h1 className="font-medium leading-[145%] tracking-[-1px] text-[18px] text-[#111111] ">
              {/* Order Status */}
              {getLocale("orderStatus")}{" "}
            </h1>
            <p></p>
          </div>
          <div className="mt-[50px] px-4">
            {loading ? (
              <>
                <div className="h-6 bg-gray-300 rounded w-48 mx-auto animate-pulse"></div>
                <div className="h-8 bg-gray-300 rounded w-32 mx-auto mt-2 animate-pulse"></div>
              </>
            ) : (
              <>
                <p className="font-medium leading-[145%] tracking-[-1px] text-[16px] text-[#707070] text-center">
                  {data?.quantity} {data?.fromCoin?.symbol}
                </p>
                <h1 className="font-medium leading-[145%] tracking-[-1px] text-[22px] text-[#111111] text-center">
                  {data?.amount} {data?.toCoin?.symbol}
                </h1>
              </>
            )}
          </div>

          <div className="mt-5 border-t border-t-[#CFCFCF]">
            {loading ? (
              Array(7)
                .fill(null)
                .map((_, index) => <div key={index}>{renderSkeleton()}</div>)
            ) : (
              <>
                <div className=" px-4 mt-8 ">
                  <div className=" flex justify-between items-center">
                    <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal">
                      Trading Pair
                    </p>
                    <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal">
                      {data?.fromCoin?.symbol}/{data?.toCoin?.symbol}
                    </p>
                  </div>
                </div>
                <div className=" px-4 mt-8 ">
                  <div className=" flex justify-between items-center">
                    <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal">
                      {/* Price per coin */}
                      {getLocale("pricePerCoin")}
                    </p>
                    <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal">
                      {data?.pairId?.currentPrice}
                    </p>
                  </div>
                </div>

                <div className=" px-4 mt-8 ">
                  <div className=" flex justify-between items-center">
                    <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal">
                      {/* Fee */}
                      {getLocale("fee")}
                    </p>
                    <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal">
                      {data?.fee || 0}
                    </p>
                  </div>
                </div>
                {data?.pendingAmount && (
                  <div className=" px-4 mt-8 ">
                    <div className=" flex justify-between items-center">
                      <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal">
                        Pending Amount
                      </p>
                      <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal">
                        {data?.pendingAmount} {data?.toCoin?.symbol}
                      </p>
                    </div>
                  </div>
                )}
                {data?.pendingQuantity && (
                  <div className=" px-4 mt-8 ">
                    <div className=" flex justify-between items-center">
                      <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal">
                        {/* Pending Quantity */}
                        {getLocale("pandingQuantity")}
                      </p>
                      <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal">
                        {data?.pendingQuantity} {data?.fromCoin?.symbol}
                      </p>
                    </div>
                  </div>
                )}

                <div className=" px-4 mt-8 ">
                  <div className=" flex justify-between items-center">
                    <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal">
                      {/* Amount */}
                      {getLocale("amount")}
                    </p>
                    <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal">
                      {data?.amount} {data?.toCoin?.symbol}
                    </p>
                  </div>
                </div>
                <div className=" px-4 mt-8 ">
                  <div className=" flex justify-between items-center">
                    <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal">
                      {/* Date */}
                      {getLocale("date")}
                    </p>
                    <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal">
                      {moment(data?.createdAt).format("DD/MM/YY hh:mm A")}
                    </p>
                  </div>
                </div>
                <div className=" px-4 mt-8 ">
                  <div className=" flex justify-between items-center">
                    <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal">
                      {/* Status */}
                      {getLocale("status")}
                    </p>
                    <div className=" flex items-center gap-3">
                      <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal">
                        {data?.orderStatus || data?.orderId?.orderStatus}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PairOrderStatus;
