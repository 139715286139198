import {
  IconArrowsTransferUpDown,
  IconHistory,
  IconX,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocale } from "../../constants/language";

import {
  getAllCoins,
  getTrWallet,
  getWallet,
  walletTransfer,
} from "../../service/api";
import { Danger } from "../Core/Errors";
import Loader from "../Core/Loader";
import { ROUTES } from "../../constants/routes";
import actions from "../../redux/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { PiApproximateEquals } from "react-icons/pi";
import moment from "moment";
import NotradeModal from "./NotradeModal";

function Wallet() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);
  const [loaderAdd, setLoaderAdd] = useState(false);
  const [activeTab, setActiveTab] = useState("SPOT");
  const [activeTabTransfer, setActiveTabTransfer] = useState({
    from: "Spot wallet",
    to: "Funding",
  });
  const [dropdown, setDropdown] = useState(false);
  const [warn, setWarn] = useState(false);
  const [isDeposit, setIsDeposit] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [coinData, setCoinData] = useState([]);
  const [amount, setAmount] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState();
  const [err, setErr] = useState({ coin: false, amount: false });
  const [allData, setAllData] = useState({ spot: [], funding: [] });
  const location = useLocation();

  useEffect(() => {
    if (location?.search === "?funding") {
      setActiveTab("FUNDING");
    }
  }, [location]);

  const getAssets = () => {
    setData([]);
    try {
      setLoader(true);
      getWallet({ walletType: activeTab })
        .then((res) => {
          setData(res.data[0]?.data);
          setCount(res.data[0]?.total[0]);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error: ", error);
          Danger(error?.response?.data?.message);
          setLoader(false);
        });
    } catch (error) {
      console.log("error: ", error);
      Danger(error?.response?.data?.message);
      setLoader(false);
    }
  };

  const getCoin = () => {
    try {
      getAllCoins(1, false, 10).then((res) => {
        setCoinData(res.data?.result);
      });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const getAssetsForAll = () => {
    try {
      // setLoader(true);

      Promise.all([
        getWallet({ walletType: "SPOT" }),
        getWallet({ walletType: "FUNDING" }),
      ])
        .then(([spotRes, fundingRes]) => {
          const spotData = spotRes.data[0]?.data;
          const fundingData = fundingRes.data[0]?.data;
          setAllData({ spot: spotData, funding: fundingData });
          // setSelectedCoin(spotData?.length > 0 ? spotData[0] : "");

          // setLoader(false);
        })
        .catch((error) => {
          console.log("error: ", error);
          Danger(error?.response?.data?.message);
          // setLoader(false);
        });
    } catch (error) {
      console.log("error: ", error);
      Danger(error?.response?.data?.message);
      // setLoader(false);
    }
  };

  const handleTransfer = () => {
    if (!amount || amount <= 0) {
      Danger("Enter Valid Amount");
      setErr({ ...err, amount: true });
      return;
    } else if (!selectedCoin) {
      setErr({ ...err, coin: true });
      Danger("Please Select Coin");
      return;
    } else if (selectedCoin?.amount < amount) {
      Danger("Please Enter amount you had in your wallet");
      return;
    }
    let body = {
      coin: selectedCoin?.coin?._id,
      amount: Number(amount),
      fromWalletType:
        activeTabTransfer?.from === "Spot wallet" ? "SPOT" : "FUNDING",
      toWalletType:
        activeTabTransfer?.to === "Spot wallet" ? "SPOT" : "FUNDING",
    };
    setLoaderAdd(true);
    walletTransfer(body)
      ?.then((res) => {
        if (res?.status) {
          setAmount(0);
          // setActiveTabTransfer({
          //   from: "Spot wallet",
          //   to: "Funding",
          // });
          setActiveTab(
            activeTabTransfer?.to === "Spot wallet" ? "SPOT" : "FUNDING"
          );
          getAssetsForAll();
        }
      })
      .catch((e) => {
        Danger(e?.response?.data?.message);
      })
      .finally(() => setLoaderAdd(false));
  };

  const getTrWalletData = () => {
    let params = {
      page: 1,
      sizePerPage: 100,
      pagination: false,
    };
    getTrWallet(params, user?._id)
      ?.then((res) => {
        if (res?.status) {
          console.log("%c Line:152 🍿 res", "color:#3f7cff", res);
          setOrderData(res?.data?.result);
        }
      })
      .catch((e) => {
        console.log("%c Line:155 🍩 e", "color:#7f2b82", e);
      });
  };

  useEffect(() => {
    if (activeTab === "TRANSFER") {
      getAssetsForAll();
    } else if (activeTab === "FUNDING" || activeTab === "SPOT") {
      getAssets();
    } else if (activeTab === "WALLETORDER") {
      getTrWalletData();
    }

    getCoin();
  }, [activeTab]);

  return (
    <div>
      {loader && <Loader />}
      <div
        onClick={(e) => {
          setDropdown(false);
        }}
        className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-10 flex flex-col "
      >
        <div>
          <div className=" flex  justify-center items-center relative">
            {activeTab === "TRANSFER" && (
              <IconX
                className="size-7 cursor-pointer absolute left-0"
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveTab("SPOT");
                  // navigate(ROUTES.LANDING);
                }}
              />
            )}
            <div className="hidden justify-center items-center w-fit border border-[#111] rounded-lg font-medium text-base p-1">
              <label
                className={`${
                  activeTab === "SPOT"
                    ? "bg-[#111] text-white"
                    : "bg-transparent text-[#111]"
                } px-2 rounded-lg py-1 cursor-pointer`}
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveTab("SPOT");
                }}
              >
                {/* Spot */}
                {getLocale("spot")}
              </label>
              <label
                className={`${
                  activeTab === "TRANSFER"
                    ? "bg-[#111] text-white"
                    : "bg-transparent text-[#111]"
                } px-2 rounded-lg py-1 cursor-pointer`}
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveTab("TRANSFER");
                }}
              >
                Transfer
              </label>
              <label
                className={`${
                  activeTab === "FUNDING"
                    ? "bg-[#111] text-white"
                    : "bg-transparent text-[#111]"
                } px-2 rounded-lg py-1 cursor-pointer`}
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveTab("FUNDING");
                }}
              >
                {/* Funding */}
                {getLocale("funding")}
              </label>
            </div>
          </div>
        </div>
        {activeTab !== "TRANSFER" && (
          <>
            <div className="my-2 border-b border-[#111] py-2 flex items-center justify-start gap-4 px-2">
              <label
                className={`${
                  activeTab === "SPOT" ? "font-bold" : "font-medium"
                } rounded-lg py-1 cursor-pointer bg-transparent text-[#111]`}
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveTab("SPOT");
                }}
              >
                {/* Spot */}
                {getLocale("spot")}
              </label>
              {/* <label
                className={`${
                  activeTab === "TRANSFER"
                    ? "bg-[#111] text-white"
                    : "bg-transparent text-[#111]"
                } px-2 rounded-lg py-1 cursor-pointer`}
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveTab("TRANSFER");
                }}
              >
                Transfer
              </label> */}
              <label
                className={`${
                  activeTab === "FUNDING" ? "font-bold" : "font-medium"
                } rounded-lg py-1 cursor-pointer bg-transparent text-[#111]`}
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveTab("FUNDING");
                }}
              >
                {/* Funding */}
                {getLocale("funding")}
              </label>
              {/* <label
                className={`${
                  activeTab === "WALLETORDER" ? "font-bold" : "font-medium"
                } rounded-lg py-1 cursor-pointer bg-transparent text-[#111]`}
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveTab("WALLETORDER");
                }}
              >
                Wallet Order
              </label> */}
            </div>

            {(activeTab === "FUNDING" || activeTab === "SPOT") && (
              <div className="flex justify-between items-center gap-2 mt-2">
                <div className="mt-2 px-2">
                  <div className="text-[18px] font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                    {/* Total balance */}
                    {getLocale("totalBalance")}
                  </div>
                  <div className="font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                    <span className="text-[26px] hidden">
                      {data && data?.length > 0
                        ? data?.reduce((arr, crr) => arr + +crr?.amount, 0)
                        : 0}
                    </span>{" "}
                    <div className="flex items-center justify-start gap-2">
                      <PiApproximateEquals />
                      <span className="text-[24px]">
                        $
                        {data && data?.length > 0
                          ? data?.reduce(
                              (arr, crr) =>
                                arr + +crr?.amount * +crr?.coin?.price,
                              0
                            )
                          : 0}
                      </span>
                    </div>
                  </div>
                </div>
                <IconHistory
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveTab("WALLETORDER");
                  }}
                  className={`mr-1 ${
                    activeTab === "SPOT" ? "hidden" : "block"
                  } cursor-pointer`}
                />
              </div>
            )}

            <div className="flex items-center justify-center gap-2 mt-4 w-full">
              {activeTab === "FUNDING" && (
                <>
                  {" "}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setDropdown(!dropdown);
                      setIsDeposit(true);
                    }}
                    className="rounded-lg bg-[#111] hover:bg-opacity-90 w-full p-2 border border-[#111] text-white text-sm cursor-pointer"
                  >
                    {/* Deposit */}
                    {getLocale("deposit")}
                  </button>
                  <button
                    onClick={(e) => {
                      if (user?.kycStatus !== "APPROVED") {
                        setWarn(true);
                      } else {
                        e.stopPropagation();
                        setDropdown(!dropdown);
                        setIsDeposit(false);
                      }
                    }}
                    className="rounded-lg bg-[#111] hover:bg-opacity-90 w-full p-2 text-[#fff] border border-[#111] text-sm cursor-pointer"
                  >
                    {/* Withdraw */}
                    {getLocale("withdraw")}
                  </button>
                </>
              )}
              {(activeTab === "FUNDING" || activeTab === "SPOT") && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveTab("TRANSFER");
                    if (activeTab === "SPOT") {
                      setActiveTabTransfer({
                        from: "Spot wallet",
                        to: "Funding",
                      });
                    } else {
                      setActiveTabTransfer({
                        from: "Funding",
                        to: "Spot wallet",
                      });
                    }
                    setSelectedCoin();
                    setIsDeposit(false);
                    setIsOpen(false);
                  }}
                  className="rounded-lg bg-[#111] hover:bg-opacity-90 w-full p-2 text-[#fff] border border-[#111] text-sm cursor-pointer"
                >
                  {/* Transfer */}
                  {getLocale("transfer")}
                </button>
              )}
            </div>
          </>
        )}
        {activeTab === "TRANSFER" ? (
          <div className="mt-4">
            <div className="mt-2">
              <div className="flex justify-start gap-6 items-center border border-[#d1d1d1] rounded-lg hover:bg-[#f9f9f9] shadow-sm p-4 my-2 transition-all duration-200">
                <label className="text-[#111] opacity-50 font-normal w-[7%] text-left">
                  {/* From */}
                  {getLocale("from")}
                </label>
                <label className="text-[#111] font-normal">
                  {activeTabTransfer?.from}
                </label>
              </div>
              <IconArrowsTransferUpDown
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveTabTransfer({
                    from: activeTabTransfer?.to,
                    to: activeTabTransfer?.from,
                  });
                  setSelectedCoin();
                }}
                className="size-7 mx-auto cursor-pointer rounded-full border border-[#111] p-1"
              />
              <div className="flex justify-start gap-6 items-center border border-[#d1d1d1] rounded-lg hover:bg-[#f9f9f9] shadow-sm p-4 my-2 transition-all duration-200">
                <label className="text-[#111] opacity-50 font-normal w-[7%] text-left">
                  {/* To */}
                  {getLocale("to")}
                </label>
                <label className="text-[#111] font-normal">
                  {activeTabTransfer?.to}
                </label>
              </div>
            </div>
            <label className="text-[#111] opacity-80 mt-2 text-sm text-left block">
              Coin
            </label>
            <div className="relative inline-block mt-1 mx-auto w-full">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(!isOpen);
                }}
                className={`w-full p-4 text-left bg-white border ${
                  err?.coin ? "border-red-500" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none flex justify-start items-center gap-2 relative`}
              >
                {selectedCoin ? (
                  <>
                    {" "}
                    <img
                      src={selectedCoin?.coin?.image}
                      className="size-6 rounded-full"
                      alt=""
                    />
                    <label> {selectedCoin?.coin?.name} </label>
                  </>
                ) : (
                  <label>
                    {/* No Selected Coin */}
                    {getLocale("noCoin")}
                  </label>
                )}
                <span className="absolute right-4 rotate-180">^</span>
              </button>

              {isOpen && (
                <div className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg text-sm">
                  {activeTabTransfer?.from === "Spot wallet" ? (
                    allData?.spot && allData?.spot?.length > 0 ? (
                      allData?.spot?.map((obj) => {
                        return (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedCoin(obj);
                              setIsOpen(false);
                              setErr({ ...err, coin: false });
                            }}
                            className="w-full px-4 py-2 text-left hover:bg-gray-100 flex justify-start items-center gap-2"
                            key={obj?._id}
                          >
                            <img
                              src={obj?.coin?.image}
                              className="size-6 rounded-full"
                              alt=""
                            />
                            <label> {obj?.coin?.name}</label>
                          </button>
                        );
                      })
                    ) : (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setErr({ ...err, coin: false });
                        }}
                        className="w-full px-4 py-2 text-left hover:bg-gray-100"
                      >
                        {/* You don't have any coin in Spot wallet */}
                        {getLocale("noCoinInSpot")}
                      </button>
                    )
                  ) : allData?.funding && allData?.funding?.length > 0 ? (
                    allData?.funding?.map((obj) => {
                      return (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedCoin(obj);
                            setIsOpen(false);
                            setErr({ ...err, coin: false });
                          }}
                          className="w-full px-4 py-2 text-left hover:bg-gray-100 flex justify-start items-center gap-2"
                          key={obj?._id}
                        >
                          <img
                            src={obj?.coin?.image}
                            className="size-6 rounded-full"
                            alt=""
                          />
                          <label> {obj?.coin?.name}</label>
                        </button>
                      );
                    })
                  ) : (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setErr({ ...err, coin: false });
                      }}
                      className="w-full px-4 py-2 text-left hover:bg-gray-100"
                    >
                      {/* You don't have any coin in Funding wallet */}
                      {getLocale("noCoinInFunding")}
                    </button>
                  )}
                </div>
              )}
            </div>
            <label className="text-[#111] opacity-80 mt-2 text-sm text-left block">
              {/* Amount */}
              {getLocale("amount")}
            </label>
            <div className="flex justify-start gap-6 items-center border border-[#d1d1d1] rounded-lg shadow-sm p-4 my-1 transition-all duration-200 relative">
              <input
                type="number"
                placeholder={getLocale("amount")}
                value={amount}
                max={selectedCoin ? selectedCoin?.amount : false}
                onChange={(e) => {
                  e.stopPropagation();
                  setAmount(Number(e?.target?.value));
                  setErr({ ...err, amount: false });
                  if (selectedCoin && e?.target?.value > selectedCoin?.amount) {
                    setAmount(selectedCoin?.amount);
                  } else {
                    setAmount(e?.target?.value);
                  }
                }}
                className="w-full pr-10 text-lg text-left focus:outline-none"
              />
              <div className="absolute right-4 flex items-start justify-end gap-4">
                {" "}
                <label className="text-[#395682] opacity-80 text-sm text-left block">
                  {selectedCoin?.coin?.symbol}
                </label>
                <label
                  onClick={(e) => {
                    e.stopPropagation();
                    setAmount(selectedCoin ? selectedCoin?.amount : null);
                  }}
                  className="text-[#111] opacity-80 text-sm text-left block cursor-pointer"
                >
                  {/* Max */}
                  {getLocale("max")}
                </label>
              </div>
            </div>
            <label className="text-[#111] opacity-80 text-sm text-left block cursor-pointer mt-2">
              {/* Available  */}
              {getLocale("available")} &nbsp;&nbsp;&nbsp;
              {selectedCoin ? selectedCoin?.amount : "0"}&nbsp;
              {selectedCoin ? selectedCoin?.coin?.symbol : ""}
            </label>
            <button
              className={`rounded-lg bg-[#184067] w-full cursor-pointer py-3 mt-4 text-white font-medium ${
                !amount || !selectedCoin ? "bg-opacity-45" : "bg-opacity-100"
              }`}
              disabled={!amount || !selectedCoin || loaderAdd}
              onClick={(e) => {
                e.stopPropagation();
                handleTransfer();
              }}
            >
              {loaderAdd ? "Loading..." : "Confirm Transfer"}
            </button>
          </div>
        ) : activeTab === "SPOT" || activeTab === "FUNDING" ? (
          <div className="mt-4">
            {data &&
              data?.length > 0 &&
              data?.map((obj, index) => (
                <div
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      actions.setActiveCoin({
                        data: obj,
                        wallet: activeTab === "FUNDING" ? "Funding" : "Spot",
                      })
                    );
                    navigate(ROUTES.COINHISTORY);
                  }}
                  className="flex justify-between cursor-pointer items-start border border-[#d1d1d1] rounded-lg  hover:bg-[#f9f9f9] shadow-sm p-4 mb-3 transition-all duration-200"
                >
                  <div className="flex items-start gap-3">
                    <img
                      src={obj?.coin?.image}
                      className="size-8 rounded-full"
                    />
                    <div className="text-left">
                      <h1 className="text-[16px] font-medium leading-[140%] tracking-[0.2px] text-[#111111]">
                        {obj?.coin?.name}
                      </h1>
                      <p className="text-[12px] leading-[145%] tracking-normal text-[#707070] mt-1">
                        {/* {moment(obj?.createdAt)?.format("DD/MM/YY")} */}
                      </p>
                    </div>
                  </div>

                  <div className="text-right">
                    <h1 className="text-[16px] font-bold leading-[140%] tracking-[0.2px] text-[#111111]">
                      {obj?.amount ? obj?.amount : ""}
                    </h1>
                    {/* <p className="text-[14px] leading-[145%] tracking-normal text-[#707070]">
                    ${parseFloat(obj?.amount).toFixed(2)}
                  </p> */}
                  </div>
                </div>
              ))}
          </div>
        ) : orderData && orderData?.length > 0 ? (
          orderData?.map((obj, index) => (
            <div
              key={index}
              className="flex justify-between items-center border border-[#d1d1d1] rounded-lg cursor-pointer hover:bg-[#f9f9f9] shadow-sm p-4 mb-3 transition-all duration-200"
              // onClick={() =>
              //   navigate(`/PairOrderStatus/${obj?._id}?Open`)
              // }
            >
              <div className="flex items-center gap-3">
                <div
                  className={`p-2 rounded-full ${
                    obj?.type === "DEBIT" ? "bg-red-200" : "bg-green-200"
                  }`}
                >
                  {obj?.type !== "DEBIT" ? (
                    <i className="text-green-600 fas fa-arrow-up"></i>
                  ) : (
                    <i className="text-red-600 fas fa-arrow-down"></i>
                  )}
                </div>

                <div className="text-left">
                  <h1 className="text-[16px] font-medium leading-[140%] tracking-[0.2px] text-[#111111]">
                    {obj?.toAddress}
                  </h1>

                  <div
                    className={`text-[12px] w-fit px-2 text-[#fff] mt-1 ${
                      obj?.orderStatus === "CANCELLED"
                        ? "bg-red-500"
                        : "bg-gray-500"
                    } rounded-2xl`}
                  >
                    {obj?.orderStatus}
                  </div>
                </div>
              </div>

              <div className="text-right">
                <h1 className="text-[16px] font-bold leading-[140%] tracking-[0.2px] text-[#111111]">
                  {`${obj?.amount} ${obj?.tokenName}`}
                </h1>
                <p className="text-[12px] leading-[145%] tracking-normal text-[#707070] mt-1">
                  {moment(obj?.createdAt)?.format("DD/MM/YY hh:mm A")}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="bg-transparent border border-gray-600 rounded-lg p-4 w-[90%] mx-auto text-black font-medium text-lg mt-6">
            {/* No any order found */}
            {getLocale("noOrder")}
          </div>
        )}
        <div
          onClick={(e) => {
            e.stopPropagation();
            setDropdown(!dropdown);
          }}
          className={`${
            dropdown ? "h-[85vh]" : "h-0"
          } transition-all w-full rounded-2xl bg-slate-300 border-t-2 absolute left-0 bottom-0  flex justify-end flex-col overflow-hidden scrollbar-hidden`}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className=" py-5 h-full bg-[#f6f5f5] bottom-0 left-0 rounded-tl-[20px] rounded-tr-[20px] flex flex-col overflow-y-scroll scrollbar-hidden"
          >
            <div className=" px-4">
              <h1 className="text-[22px] font-medium text-[#111111] leading-[145%] tracking-[-1px] text-left">
                {/* Choose Coin */}
                {getLocale("chooseCoin")}
              </h1>
              {isDeposit ? (
                coinData && coinData?.length > 0 ? (
                  coinData?.map((obj, index) => {
                    return (
                      <div
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(ROUTES.DEPOSIT);
                          dispatch(actions.setActiveWithdrawCoin(obj));
                        }}
                        className="w-full cursor-pointer rounded-md border border-[#111] hover:bg-white p-1 text-left flex items-center justify-start mt-2 gap-2 px-2"
                      >
                        <img
                          src={obj?.image}
                          alt=""
                          className="rounded-full size-8"
                        />
                        <div className="flex flex-col items-start justify-center">
                          <label
                            className={`text-[#111] text-base opacity-100`}
                          >
                            {obj?.name}
                          </label>
                          <label className={`text-[#111] text-xs opacity-70`}>
                            {obj?.symbol}
                          </label>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    {/* No Coin found */}
                    {getLocale("noCoinFound")}
                  </div>
                )
              ) : data && data?.length > 0 ? (
                data?.map((obj, index) => {
                  return (
                    <div
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(ROUTES.WITHDRAW);
                        dispatch(actions.setActiveWithdrawCoin(obj));
                      }}
                      className="w-full cursor-pointer rounded-md border border-[#111] hover:bg-white p-1 text-left flex items-center justify-between mt-2 gap-2 px-2"
                    >
                      <div className="flex items-center justify-start gap-2">
                        <img
                          src={obj?.coin?.image}
                          alt=""
                          className="rounded-full size-8"
                        />
                        <div className="flex flex-col items-start justify-center">
                          <label
                            className={`text-[#111] text-base opacity-100`}
                          >
                            {obj?.coin?.name}
                          </label>
                          <label className={`text-[#111] text-xs opacity-70`}>
                            {obj?.coin?.symbol}
                          </label>
                        </div>
                      </div>
                      <label className={`text-[#111] text-base opacity-100`}>
                        {obj?.amount}
                      </label>
                    </div>
                  );
                })
              ) : (
                <div>
                  {/* No Coin found */}
                  {getLocale("noCoinFound")}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <NotradeModal warn={warn} setWarn={setWarn} />
    </div>
  );
}

export default Wallet;
