import { IconArrowNarrowLeft, IconChevronRight } from "@tabler/icons-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";
import { Button } from "@mui/material";
const pauseNotification = [
  {
    title: getLocale("disabled"),
    value: 1,
  },
  {
    title: getLocale("hours"),
    value: 2,
  },
  {
    title: getLocale("day"),
    value: 3,
  },
  {
    title: getLocale("week"),
    value: 4,
  },
];

function NotificationSetting() {
  const navigate = useNavigate();
  const [isInfo, setIsInfo] = useState(false);
  const [active, setActive] = useState(0);
  return (
    <div>
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col ">
        <div className=" flex justify-between items-center">
          <IconArrowNarrowLeft
            className="size-7 cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h2 className=" font-semibold leading-[140%] tracking-[-1px] ">
            {/* Notification settings */}
            {getLocale("notificationsSettings")}
          </h2>
          <p></p>
        </div>
        <div className=" mt-[30px]">
          <h1 className=" text-left text-[22px] tracking-[-1px] leading-[145%]">
            {/* Push notification */}
            {getLocale("pushNotification")}
          </h1>
          <div className="mt-[20px]">
            <div className=" flex justify-between items-center rounded-md py-2 px-2 cursor-pointer gap-2">
              <div>
                <h1 className="text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal text-left">
                  {/* Alertas activadas */}
                  {getLocale("alertsActivated")}
                </h1>
                <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal text-left">
                  {/* Quiero recibir notificaciones */}
                  {getLocale("alertsActivatedInfo")}
                </p>
              </div>
              <label className="inline-flex items-center cursor-pointer gap-2 ">
                <input type="checkbox" value="" className="sr-only peer" />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
              </label>
            </div>
            <div className=" flex justify-between items-center rounded-md py-2 px-2 cursor-pointer gap-2">
              <div>
                <h1 className="text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal text-left">
                  {/* Tomarse un descanso */}
                  {getLocale("takeBreak")}
                </h1>
                <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal text-left">
                  {/* Pausar las notificaciones por un tiempo */}
                  {getLocale("takeBreakInfo")}
                </p>
              </div>
              <label className="inline-flex items-center cursor-pointer gap-2 ">
                <input type="checkbox" value="" className="sr-only peer" />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
              </label>
            </div>
          </div>
        </div>
        <div className=" mt-[30px]">
          <div>
            <h1 className=" text-left text-[22px] tracking-[-1px] leading-[145%]">
              {/* Personalizar notificaciones */}
              {getLocale("customizeNotifications")}
            </h1>
            <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal text-left">
              {/* Escoge los mensajes que quieres recibir */}
              {getLocale("customizeNotificationsInfo")}
            </p>
          </div>
          <div className="mt-[20px]">
            <div
              onClick={() => setIsInfo(true)}
              className=" flex justify-between items-center rounded-md py-2 px-2 active:bg-[#ccc] cursor-pointer"
            >
              <div>
                <h1 className="text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal text-left">
                  {/* Alertas de seguridad */}
                  {getLocale("securityAlerts")}
                </h1>
                <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal text-left">
                  {/* Push, Email, SMS, In app */}
                  {getLocale("push")}
                  {getLocale("email")}
                  {getLocale("sms")}
                  {getLocale("inApp")}
                </p>
              </div>
              <IconChevronRight />
            </div>
            <div className=" mt-3 flex justify-between items-center rounded-md py-2 px-2 active:bg-[#ccc] cursor-pointer">
              <div>
                <h1 className="text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal text-left">
                  {/* Actividad de la cuenta */}
                  {getLocale("AccountActivity")}
                </h1>
                <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal text-left">
                  {/* Push, Email, In app */}
                  {getLocale("push")}
                  {getLocale("email")}
                  {getLocale("inApp")}
                </p>
              </div>
              <IconChevronRight />
            </div>
            <div className=" mt-3 flex justify-between items-center rounded-md py-2 px-2 active:bg-[#ccc] cursor-pointer">
              <div>
                <h1 className="text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal text-left">
                  {/* Alertas de precio */}
                  {getLocale("PriceAlerts")}
                </h1>
                <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal text-left">
                  {/* Push, In app */}
                  {getLocale("push")}
                  {getLocale("inApp")}
                </p>
              </div>
              <IconChevronRight />
            </div>
            <div className=" mt-3 flex justify-between items-center rounded-md py-2 px-2 active:bg-[#ccc] cursor-pointer">
              <div>
                <h1 className="text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal text-left">
                  {/* Alertas de noticias */}
                  {getLocale("newsAlerts")}
                </h1>
                <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal text-left">
                  {/* In app */}
                  {getLocale("inApp")}
                </p>
              </div>
              <IconChevronRight />
            </div>
            <div className=" mt-3 flex justify-between items-center rounded-md py-2 px-2 active:bg-[#ccc] cursor-pointer">
              <div>
                <h1 className="text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal text-left">
                  {/* Alertas de productos */}
                  {getLocale("productAlerts")}
                </h1>
                <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal text-left">
                  {/* Push, In app */}

                  {getLocale("push")}
                  {getLocale("inApp")}
                </p>
              </div>
              <IconChevronRight />
            </div>
          </div>
        </div>

        <div
          onClick={(e) => {
            e.stopPropagation();
            setIsInfo(!isInfo);
          }}
          className={`${
            isInfo ? "h-full" : "h-0"
          } transition-all w-full bg-[#111111]/[0.2] absolute left-0 bottom-0  flex justify-end flex-col overflow-hidden`}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className=" py-5 min-h-[300px] bg-white bottom-0 left-0 rounded-tl-[20px] rounded-tr-[20px] flex flex-col"
          >
            <div className=" px-4">
              <h1 className="text-[22px]  font-medium text-[#111111] leading-[145%] tracking-[-1px] text-left">
                {/* ¿Quieres un descanso? */}
                {getLocale("break")}
              </h1>
              <p className=" mt-5 text-[16px] font-normal text-[#707070] leading-[140%] tracking-[0.2px] text-left">
                {/* Puedes pausar las notificaciones por 1 semana, los correos y SMS
                no serán afectados */}
                {getLocale("breakInfo")}
              </p>
            </div>
            <div className="px-2 mt-2">
              {pauseNotification?.map((pause, index) => (
                <div
                  onClick={() => setActive(index)}
                  className={`${
                    active === index ? "bg-[#F5F8FE]" : "bg-white"
                  } py-6 px-2 rounded-md cursor-pointer`}
                >
                  <h1 className=" text-left">{pause?.title}</h1>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationSetting;
