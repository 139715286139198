import { useSelector } from "react-redux";
import { PUBLIC_LOCALE } from "../config";
import store from "../redux/store";
import { buyCoin, verifyEmail } from "../service/api";
import { useTranslation } from 'react-i18next';
import React from "react";
import i18n from "../i18n";
// const locales = {
//   en: {},
//   sp: {},
// };

// const active = store.getState().user?.language
//   ? store.getState().user?.language
//   : PUBLIC_LOCALE;
// console.log(
//   "%c Line:1004 🍆 store.getState()",
//   "color:#ffdd4d",
//   store.getState()
// );
export const getLocale = (key) => {
  return i18n.t(key);
  // const locale = locales[active];
  // if (!locale) {
  //   throw new Error(`Locale with code "${active}" not found.`);
  // }
  // const value = locale[key];
  // if (typeof value !== "string") {
  //   throw new Error(`Key "${key}" not found in locale "${active}".`);
  // }
  // return value;
};