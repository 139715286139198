import React from "react";
import { useTour } from "@reactour/tour";
import { Button } from "@mui/material";

/* Common Styling for Modals */
const modalWrapperClass =
  "bg-gray-800 rounded-lg p-6 shadow-lg flex flex-col md:flex-row items-start text-white space-y-4 md:space-y-0 md:space-x-6";
const contentClass = "flex flex-col justify-center space-y-2";
const titleClass = "text-2xl font-semibold";
const descriptionClass = "text-gray-300";

export const styles = {
  /**
   * Overlay style
   * @param base
   */
  maskWrapper: (base) => ({ ...base }),
  maskArea: (base) => ({
    ...base,
    rx: 32,
    ry: 32,
  }),

  highlightedArea: (base) => ({ ...base }),
  badge: (base) => ({ ...base }),
  popover: (base) => ({
    ...base,
    boxShadow: "0 0 3em rgba(0, 0, 0, 0.5)",
    backgroundColor: "var(--bs-body-bg)",
    color: "var(--bs-body-color)",
    borderRadius: "var(--bs-border-radius)",
    // bottom: 0,
    // left: "40%",
    // height: "fit-content",
    // background: "black",
    // top: "69vh",
    padding: 0,
    // borderRadius: "12px",
  }),
};

/* Tour Navigation Component */
const TourNavigation = () => {
  const { currentStep, setCurrentStep } = useTour();
  return (
    <div className="flex justify-center gap-6 w-full mt-6">
      {!!currentStep && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          Prev
        </Button>
      )}
      <Button
        color="primary"
        variant="contained"
        onClick={() => setCurrentStep(currentStep + 1)}
      >
        Next
      </Button>
    </div>
  );
};

/* Modal Components */
const ConnectProfileTour = () => (
  <div className={modalWrapperClass}>
    <div className={contentClass}>
      <h2 className={titleClass}>Overview</h2>
      <p className={descriptionClass}>
        Get a quick glance at your stats and activities.
      </p>
      <TourNavigation />
    </div>
  </div>
);

const DashboardTour = () => (
  <div className={modalWrapperClass}>
    <div className={contentClass}>
      <h2 className={titleClass}>Wallet Management</h2>
      <p className={descriptionClass}>
        Manage your wallet balances and transactions here.
      </p>
      <TourNavigation />
    </div>
  </div>
);

const PublishPostTour = () => (
  <div className={modalWrapperClass}>
    <div className={contentClass}>
      <h2 className={titleClass}>Marketplace</h2>
      <p className={descriptionClass}>
        Access and trade coins in the marketplace.
      </p>
      <TourNavigation />
    </div>
  </div>
);

const SchedulePostTour = () => (
  <div className={modalWrapperClass}>
    <div className={contentClass}>
      <h2 className={titleClass}>P2P Trading</h2>
      <p className={descriptionClass}>
        Initiate person-to-person trades seamlessly here.
      </p>
      <TourNavigation />
    </div>
  </div>
);

const AccountsSummaryTour = () => (
  <div className={modalWrapperClass}>
    <div className={contentClass}>
      <h2 className={titleClass}>Navigation Menu</h2>
      <p className={descriptionClass}>
        Explore additional options and features from here.
      </p>
      <TourNavigation />
    </div>
  </div>
);

const PostAnalyticsTour = () => (
  <div className={modalWrapperClass}>
    <div className={contentClass}>
      <h2 className={titleClass}>Analytics Dashboard</h2>
      <p className={descriptionClass}>
        Dive into detailed insights about your posts and engagement rates.
      </p>
      <TourNavigation />
    </div>
  </div>
);

/* TOUR STEPS */
const getTargetName = (name) => `[data-tour='${name}']`;

const steps = [
  {
    selector: getTargetName("dashboard"),
    content: () => <DashboardTour />,
  },
  {
    selector: getTargetName("connect-profile"),
    content: () => <ConnectProfileTour />,
  },
  {
    selector: getTargetName("publish-post"),
    content: () => <PublishPostTour />,
  },
  {
    selector: getTargetName("schedule-post"),
    content: () => <SchedulePostTour />,
  },
  {
    selector: getTargetName("accounts-summary"),
    content: () => <AccountsSummaryTour />,
  },
  // Uncomment to add additional steps
  // {
  //   selector: getTargetName("post-analytics"),
  //   content: () => <PostAnalyticsTour />,
  // },
];

export default steps;
