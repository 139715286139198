import { IconLockFilled, IconX } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import cardImage from "../../Assets/image/onbrdMobile.png";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";

function Onboarding1() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col justify-between">
        <div>
          <div className=" flex justify-between items-center">
            <IconX
              size={35}
              className="size-7 cursor-pointer"
              onClick={() => navigate(-1)}
            />
            <h2 className=" font-semibold leading-[140%] tracking-[-1px] ">
              {/* Recieve Crypto */}
              {getLocale("recieveCrypto")}
            </h2>
            <p></p>
          </div>
          <div className="flex items-start justify-center w-full mt-[50px] h-[228px]">
            <img className="h-full" src={cardImage} alt="card-image" />
          </div>
          <div className=" mt-[100px]">
            <h2 className=" text-[20px] font-medium  text-center leading-[130%] tracking-normal ">
              {/* Make sure to select the correct options. */}
              {getLocale("makeSure")}
            </h2>
            <p className=" text-center text-[#707070] text-[16px] font-normal tracking-[0.02px] mt-3 leading-[140%]">
              {/* If you send cryptocurrency to the wrong address (for example,
              Bitcoin to a Bitcoin Cash address), your cryptocurrency will be
              lost. */}
              {getLocale("onboarding1Info")}
            </p>
          </div>
        </div>
        <button
          onClick={() => navigate(ROUTES.SECURITY)}
          className="w-full p-4 bg-bgBlue text-white rounded-lg mt-2 text-base leading-[140%]"
        >
          {/* I understand */}
          {getLocale("understand")}
        </button>
      </div>
    </div>
  );
}

export default Onboarding1;
