import {
  IconBellPlus,
  IconCaretDownFilled,
  IconChevronLeft,
  IconClock,
  IconFilter,
  IconPlus,
  IconRecharging,
  IconSearch,
  IconTrash,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import PopUp from "./PopUp";
import { useNavigate } from "react-router-dom";
import { Danger } from "../../Core/Errors";
import { getLocale } from "../../../constants/language";

import {
  addMessage,
  createChat,
  deleteP2pOrder,
  getChatById,
  userP2PAds,
} from "../../../service/api";
import Filter from "./Filter";
import CoinSlider from "./CoinSlider";
import Loader from "../../Core/Loader";
import { NumericFormat } from "react-number-format";
import actions from "../../../redux/user/actions";
import { p2pAddPayloadreset } from "./PostAdForm";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../../constants/routes";
import NotradeModal from "../NotradeModal";
const paymentMethods = [
  "All",
  "Lightning UPI",
  "UPI",
  "IMPS",
  "Bank Transfer (India)",
  "Digital eRupee",
  "Google Pay (GPay)",
  "IMPS-PAN",
  "International Wire",
  "Paytm",
  "PhonePe",
  "Skrill (Moneybookers)",
  "UPI-PAN",
  "Western Union",
];

const MyAdds = () => {
  const dispatch = useDispatch();
  const { user, orderChatId } = useSelector((state) => state.user);
  const [isActive, setIsActive] = useState(true);
  const [currFilter, setCurrFilter] = useState(false);
  const [chatActive, setChatActive] = useState(false);
  const [tempOrderId, setTempOrderId] = useState();
  const [amountFilter, setAmountFilter] = useState(false);
  const [paymentFilter, setPaymentFilter] = useState(false);
  const [extraFIlter, setExtraFilter] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("INR");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("All");
  const [messages, setMessages] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);
  const [warn, setWarn] = useState(false);

  function formatNumber(num) {
    if (num < 1000) {
      return num.toString(); // Return the number as a string if less than 1000
    } else {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "k"; // Convert to k notation, remove decimal if unnecessary
    }
  }

  const getAllP2POrders = () => {
    try {
      setLoader(true);
      userP2PAds(
        pageNumber,
        10,
        isActive ? "BUY" : "SELL"
        // selectedCoin?.coin?.symbol??''
      )
        .then((res) => {
          setData(res?.data?.result);
          setLoader(false);
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoader(false);
        });
    } catch (error) {
      Danger(error?.response?.data?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllP2POrders();
  }, [isActive]);

  const handlecreateChat = (orderId) => {
    try {
      setLoader(true);
      try {
        createChat({ order: orderId ?? tempOrderId })
          .then((res) => {
            setMessages(res?.data?.[0]?.messages);
            setChatActive(true);
            setLoader(false);
            dispatch(actions.addChatId(res?.data[0]?._id));
          })
          .catch((error) => {
            Danger(error?.response?.data?.message);
            setLoader(false);
          });
      } catch (error) {
        Danger(error?.response?.data?.message);
        setLoader(false);
      }
    } catch (error) {}
  };
  const handleAddMessage = (message) => {
    try {
      setLoader(true);
      try {
        addMessage(orderChatId, { message: message })
          .then((res) => {
            getChatById(orderChatId).then((message) => {
              setMessages(message?.data?.[0]?.messages);
            });
            setLoader(false);
          })
          .catch((error) => {
            Danger(error?.response?.data?.message);
            setLoader(false);
          });
      } catch (error) {
        Danger(error?.response?.data?.message);
        setLoader(false);
      }
    } catch (error) {}
  };
  return (
    <div>
      <div>
        <NotradeModal warn={warn} setWarn={setWarn} />
        <div className="  relative overflow-x-hidden">
          {loader && <Loader />}
          <div className="h-full min-h-[100vh] font-sansflex flex-col bg-bgWhite relative">
            <div className=" flex justify-between items-center pt-12 w-full px-4 py-4 bg-bgBlue">
              <IconChevronLeft
                onClick={() => navigate(-1)}
                color="white"
                className="size-7 cursor-pointer"
              />
              <h1 className=" flex justify-center items-center text-textWhite font-bold tracking-[1.02px] leading-[140%] gap-1">
                Ads <IconPlus size={15} className=" mt-1" />
              </h1>
              <p></p>
            </div>

            <div className=" h-full w-full bg-bgWhite rounded-tl-xl rounded-tr-xl py-4 z-10 ">
              <div className=" flex justify-between items-center px-4 ">
                <div className="flex justify-between text-[16px] leading-[140%] px-0.5 py-0.5  items-center bg-bgWhite border h-[35px]  min-w-[160px] rounded-md overflow-hidden ">
                  <button
                    onClick={() => setIsActive(true)}
                    className={`  transition-all px-2 ${
                      isActive ? "bg-bgBlue text-white" : ""
                    } rounded-md h-full w-1/2`}
                  >
                    {/* Buy */}
                    {getLocale("buy")}
                  </button>
                  <button
                    onClick={() => setIsActive(false)}
                    className={` transition-all px-2 ${
                      isActive ? "" : "bg-bgBlue text-white"
                    } rounded-md h-full w-1/2`}
                  >
                    {/* Sell */}
                    {getLocale("sell")}
                  </button>
                </div>

                <div className=" relative ">
                  <IconBellPlus className=" text-textPrime cursor-pointer" />
                  <div className=" w-1 h-1  rounded-full absolute top-[2px] right-1">
                    {" "}
                  </div>
                </div>
              </div>
              <div className=" flex justify-between items-center w-full mt-5 px-4 pb-2 ">
                <div className=" flex items-center gap-2">
                  <div
                    onClick={() => setCurrFilter(!currFilter)}
                    className=" flex items-start gap-1 cursor-pointer"
                  >
                    {/* <IconCurrencyDollar className='text-textPrime' size={12} /> */}
                    <p className=" text-[12px]">USDT</p>
                    <IconCaretDownFilled
                      className={` transition-all text-textPrime mt-[2px] ${
                        currFilter ? "rotate-180" : ""
                      }`}
                      size={12}
                    />
                  </div>
                  <div
                    onClick={() => setAmountFilter(!amountFilter)}
                    className=" flex items-start gap-1 cursor-pointer"
                  >
                    {/* <IconCurrencyDollar className='text-textPrime' size={12} /> */}
                    <p className=" text-[12px]">
                      {/* Amount */}
                      {getLocale("amount")}
                    </p>
                    <IconCaretDownFilled
                      className={` transition-all text-textPrime mt-[2px] ${
                        amountFilter ? "rotate-180" : ""
                      }`}
                      size={12}
                    />
                  </div>
                  <div
                    onClick={() => setPaymentFilter(!paymentFilter)}
                    className=" flex items-start gap-1 cursor-pointer"
                  >
                    {/* <IconCurrencyDollar className='text-textPrime' size={12} /> */}
                    <p className=" text-[12px]">
                      {/* Payment */}
                      {getLocale("payment")}
                    </p>
                    <IconCaretDownFilled
                      className={` transition-all text-textPrime mt-[2px] ${
                        paymentFilter ? "rotate-180" : ""
                      }`}
                      size={12}
                    />
                  </div>
                </div>
                <div className=" relative">
                  <IconFilter
                    onClick={() => setExtraFilter(true)}
                    className=" text-textPrime cursor-pointer"
                  />
                  <div className=" w-1 h-1 bg-red-500 rounded-full absolute top-[1px] right-[1px]">
                    {" "}
                  </div>
                </div>
              </div>
              <div className=" relative bg-bgWhite">
                <div
                  className={` bg-bgWhite max-h-[55vh] overflow-y-auto w-full left-0 top-0 absolute transition-all pb-10 pt-4 no-scrollbar`}
                >
                  {data?.map((item, index) => (
                    <P2PCard
                      setTempOrderId={setTempOrderId}
                      handleAddMessage={(id) => handlecreateChat(id)}
                      buy={true}
                      key={`buy-${index}`}
                      item={item}
                      getAllP2POrders={getAllP2POrders}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className=" absolute bottom-[90px] left-0 w-full flex justify-center px-4  ">
              <button
                onClick={() => {
                  if (user?.kycStatus !== "APPROVED") {
                    setWarn(true);
                  } else {
                    navigate(ROUTES.P2PADDNEW);
                    dispatch(actions.setP2PPaylaod({ ...p2pAddPayloadreset }));
                    dispatch(actions.setP2PFromStep(1));
                  }
                }}
                className="  w-full rounded-md m-auto py-2 text-center flex items-center justify-center gap-1 text-textWhite bottom-[0px] left-0  bg-bgBlue"
              >
                <IconPlus className=" font-bold" size={20} />
                <span>
                  {/* Add */}
                  {getLocale("add")}
                </span>
              </button>
            </div>
          </div>
          <CoinSlider currFilter={currFilter} setCurrFilter={setCurrFilter} />
          <PopUp
            open={amountFilter}
            setOpen={setAmountFilter}
            title={"I Want to Buy"}
          >
            <div className=" px-4">
              <div className=" flex justify-between items-center w-full ">
                <div className=" w-full bg-bgGray items-center rounded-md h-[40px] flex justify-between gap-1 px-2">
                  <input
                    value={selectedAmount}
                    onChange={(e) => setSelectedAmount(e.target.value)}
                    type="number"
                    className=" w-full h-full border-none outline-none placeholder:opacity-[0.5] bg-transparent "
                    placeholder="e.g. 200"
                  />
                  <p className=" text-textPrime tracking-[0.2px] leading-[145%] ">
                    {selectedCurrency}
                  </p>
                </div>
              </div>

              <div className=" flex justify-between items-center gap-2 mt-2 flex-wrap">
                {[100, 1000, 5000, 7000]?.map((item, index) => (
                  <div
                    onClick={() => setSelectedAmount(item)}
                    className={` cursor-pointer ${
                      selectedAmount === item
                        ? "bg-bgGray text-textPrime "
                        : "bg-primBlue text-textWhite "
                    } px-3 min-w-[80px]  rounded-sm py-2`}
                  >
                    {formatNumber(item)}
                  </div>
                ))}
              </div>
              <div className=" w-full flex justify-between items-center mt-2 gap-2">
                <button
                  onClick={() => setSelectedAmount("")}
                  className=" w-1/2 text-clip bg-bgGray text-textPrime py-2 rounded-md tracking-[0.2px] leading-[140%]"
                >
                  {/* Reset */}
                  {getLocale("reset")}
                </button>
                <button
                  onClick={() => {
                    if (selectedAmount > 0) {
                      setAmountFilter(false);
                    }
                  }}
                  className=" w-1/2 text-clip bg-bgBlue text-textWhite py-2 rounded-md tracking-[0.2px] leading-[140%]"
                >
                  {/* Confirm */}
                  {getLocale("confirm")}
                </button>
              </div>
            </div>
          </PopUp>
          <PopUp
            open={paymentFilter}
            setOpen={setPaymentFilter}
            title={"Payment Methods"}
          >
            <div className="px-4 pb-12 flex flex-col justify-between h-full">
              <div>
                <div className=" flex  bg-bgGray items-start gap-2 rounded-md py-2  px-2">
                  <IconRecharging className="text-orange-600 mt-1" size={15} />
                  <p className="text-[14px] text-left tracking-[0.2px] leading-[140%]">
                    {/* Release the crypto automatically upon payment complition */}
                    {getLocale("releaseCrypto")}
                  </p>
                </div>
                <div className=" flex justify-between items-center w-full mt-2">
                  <div className=" w-full bg-bgGray items-center rounded-md h-[30px] flex justify-between gap-1 pl-1">
                    <IconSearch size={20} />
                    <input
                      type="text"
                      className=" w-full h-full border-none outline-none placeholder:opacity-[0.5] bg-transparent "
                      placeholder="Search"
                    />
                  </div>
                </div>

                <div className=" grid grid-cols-2 m-auto gap-1 mt-2 ">
                  {paymentMethods?.map((item, index) => (
                    <div
                      onClick={() => setSelectedPaymentMethod(item)}
                      key={`payMethod-${index}`}
                      className={`text-center flex justify-center px-2 py-1 ${
                        selectedPaymentMethod === item
                          ? " bg-bgGray text-textPrime"
                          : "bg-bgBlue text-textWhite "
                      } rounded-md cursor-pointer`}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              </div>

              <div className=" w-full flex justify-between items-center mt-2 gap-2">
                <button
                  onClick={() => setSelectedPaymentMethod("")}
                  className=" w-1/2 text-clip bg-bgGray text-textPrime py-2 rounded-md tracking-[0.2px] leading-[140%]"
                >
                  {/* Reset */}
                  {getLocale("reset")}
                </button>
                <button
                  onClick={() => {
                    if (selectedPaymentMethod?.length > 0) {
                      setPaymentFilter(false);
                    }
                  }}
                  className=" w-1/2 text-clip bg-bgBlue text-textWhite py-2 rounded-md tracking-[0.2px] leading-[140%]"
                >
                  {/* Confirm */}
                  {getLocale("confirm")}
                </button>
              </div>
            </div>
          </PopUp>

          <PopUp open={extraFIlter} setOpen={setExtraFilter}>
            <Filter selectedCurrency={selectedCurrency} />
          </PopUp>
        </div>
      </div>
      <CoinSlider currFilter={currFilter} setCurrFilter={setCurrFilter} />
      <PopUp
        open={amountFilter}
        setOpen={setAmountFilter}
        title={"I Want to Buy"}
      >
        <div className=" px-4">
          <div className=" flex justify-between items-center w-full ">
            <div className=" w-full bg-bgGray items-center rounded-md h-[40px] flex justify-between gap-1 px-2">
              <input
                value={selectedAmount}
                onChange={(e) => setSelectedAmount(e.target.value)}
                type="number"
                className=" w-full h-full border-none outline-none placeholder:opacity-[0.5] bg-transparent "
                placeholder="e.g. 200"
              />
              <p className=" text-textPrime tracking-[0.2px] leading-[145%] ">
                {selectedCurrency}
              </p>
            </div>
          </div>

          <div className=" flex justify-between items-center gap-2 mt-2 flex-wrap">
            {[100, 1000, 5000, 7000]?.map((item, index) => (
              <div
                onClick={() => setSelectedAmount(item)}
                className={` cursor-pointer ${
                  selectedAmount === item
                    ? "bg-bgGray text-textPrime "
                    : "bg-primBlue text-textWhite "
                } px-3 min-w-[90px]  rounded-md py-1`}
              >
                {formatNumber(item)}
              </div>
            ))}
          </div>
          <div className=" w-full flex justify-between items-center mt-4 gap-2">
            <button
              onClick={() => setSelectedAmount("")}
              className=" w-1/2 text-clip bg-bgGray text-textPrime py-2 rounded-md tracking-[0.2px] leading-[140%]"
            >
              {/* Reset */}
              {getLocale("reset")}
            </button>
            <button
              onClick={() => {
                if (selectedAmount > 0) {
                  setAmountFilter(false);
                }
              }}
              className=" w-1/2 text-clip bg-bgBlue text-textWhite py-2 rounded-md tracking-[0.2px] leading-[140%]"
            >
              {/* Confirm */}
              {getLocale("confirm")}
            </button>
          </div>
        </div>
      </PopUp>
      <PopUp
        open={paymentFilter}
        setOpen={setPaymentFilter}
        title={"Payment Methods"}
      >
        <div className="px-4 pb-12 flex flex-col justify-between h-full">
          <div>
            <div className=" flex  bg-bgGray items-start gap-2 rounded-md py-2  px-2">
              <IconRecharging className="text-orange-600 mt-1" size={15} />
              <p className="text-[14px] text-left tracking-[0.2px] leading-[140%]">
                {/* Release the crypto automatically upon payment complition */}
                {getLocale("releaseCrypto")}
              </p>
            </div>
            <div className=" flex justify-between items-center w-full mt-2">
              <div className=" w-full bg-bgGray items-center rounded-md h-[35px] flex justify-between gap-1 pl-1">
                <IconSearch size={20} />
                <input
                  type="text"
                  className=" w-full h-full border-none outline-none placeholder:opacity-[0.5] bg-transparent "
                  placeholder="Search"
                />
              </div>
            </div>

            <div className=" grid grid-cols-2 m-auto gap-1 mt-2 ">
              {paymentMethods?.map((item, index) => (
                <div
                  onClick={() => setSelectedPaymentMethod(item)}
                  key={`payMethod-${index}`}
                  className={`text-center flex justify-center px-2 py-1 ${
                    selectedPaymentMethod === item
                      ? " bg-bgGray text-textPrime"
                      : "bg-bgBlue text-textWhite "
                  } rounded-md cursor-pointer`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>

          <div className=" w-full flex justify-between items-center mt-2 gap-2">
            <button
              onClick={() => setSelectedPaymentMethod("")}
              className=" w-1/2 text-clip bg-bgGray text-textPrime py-2 rounded-md tracking-[0.2px] leading-[140%]"
            >
              {/* Reset */}
              {getLocale("reset")}
            </button>
            <button
              onClick={() => {
                if (selectedPaymentMethod?.length > 0) {
                  setPaymentFilter(false);
                }
              }}
              className=" w-1/2 text-clip bg-bgBlue text-textWhite py-2 rounded-md tracking-[0.2px] leading-[140%]"
            >
              {/* Confirm */}
              {getLocale("confirm")}
            </button>
          </div>
        </div>
      </PopUp>

      <PopUp open={extraFIlter} setOpen={setExtraFilter}>
        <Filter selectedCurrency={selectedCurrency} />
      </PopUp>

      {/* <ChatModal
        getMessage={(id) => handlecreateChat(id)}
        setCurrFilter={setChatActive}
        currFilter={chatActive}
        messages={messages}
        addMessage={(message) => handleAddMessage(message)}
      /> */}
    </div>
  );
};

const P2PCard = ({
  buy,
  item,
  handleAddMessage,
  setTempOrderId,
  getAllP2POrders,
}) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleOrder = (id) => {
    if (deleteLoading) {
      return;
    }
    setDeleteLoading(true);
    deleteP2pOrder(id)
      ?.then((res) => {
        if (res?.status) {
          getAllP2POrders();
        }
      })
      .catch((e) => {
        Danger(e?.response?.data?.message);
      })
      .finally(() => setDeleteLoading(false));
  };
  return (
    <div>
      <div
        className="px-4 border-b border-t border-borderGray py-2 hover:bg-bgGray cursor-pointer "
        onClick={(e) => {
          // handleAddMessage(item?._id);
          // setTempOrderId(item?._id);
        }}
      >
        <div className=" flex justify-between items-start mt-0.5">
          <div className=" text-left space-y-1">
            <h1 className=" flex items-end">
              <span className=" tracking-[1.02px] leading-[140%] text-[12px] text-textSec">
                ₹
              </span>
              <p>
                <NumericFormat
                  value={item?.price}
                  displayType="text"
                  thousandSeparator
                />
              </p>
              <span className=" tracking-[1.02px] leading-[140%] text-[10px] text-textSec">
                /{item?.coin?.symbol}
              </span>
            </h1>
            <p className=" tracking-[1.02px] leading-[140%] text-[10px] text-textSec mt-1">
              {/* limit */}
              {getLocale("limit")}{" "}
              <span className=" text-textPrime">
                <NumericFormat
                  value={item?.minLimit}
                  displayType="text"
                  thousandSeparator
                />
                -{" "}
                <NumericFormat
                  decimalScale={2}
                  value={item?.maxLimit}
                  displayType="text"
                  thousandSeparator
                />
              </span>
            </p>
            <p className=" tracking-[1.02px] leading-[140%] text-[10px] text-textSec">
              {/* Available */}
              {getLocale("available")}{" "}
              <span className=" text-textPrime">
                <NumericFormat
                  decimalScale={2}
                  value={item?.amount}
                  displayType="text"
                  thousandSeparator
                />
                {item?.coin?.symbol}
              </span>
            </p>
          </div>

          <div
            className=" text-right space-y-1"
            onClick={(e) => e.stopPropagation()}
          >
            <p className=" tracking-[1.02px] leading-[140%] text-[10px] text-textSec">
              {item?.fiatCurrencyDetails?.name}
            </p>
            <div className=" flex items-center justify-end mt-1">
              <IconClock size={10} className=" text-textPrime" />
              <p className=" tracking-[1.02px] leading-[140%] text-[10px] text-textSec">
                {item?.paymentTimeLimitMinutes}min
              </p>
            </div>
            {/* <div className="flex justify-end items-center gap-2">
              {item?.type === "BUY" ? (
                <button
                  // onClick={() => {
                  //     dispatch(actions.setP2POrder({ ...P2PorderPayload, type: "BUY", offerId: item?._id, paymentTimeLimitMinutes: item?.paymentTimeLimitMinutes }))
                  //     navigate("/buyP2P", { state: { ID: item?._id } })
                  // }}
                  className=" w-[80px] rounded-sm bg-bgBlue text-textWhite mt-1 flex items-center justify-center text-[16px] leading-[145%] tracking-[1.02px]"
                >
                  // Buy
                  {getLocale("buy")}
                </button>
              ) : (
                <button
                  // onClick={() => {
                  //     dispatch(actions.setP2POrder({ ...P2PorderPayload, type: "SELL", offerId: item?._id, paymentTimeLimitMinutes: item?.paymentTimeLimitMinutes }))
                  //     navigate("/buyP2P", { state: { ID: item?._id } })
                  // }}
                  className=" w-[80px] rounded-sm bg-bgDanger text-textWhite mt-1 flex items-center justify-center text-[16px] leading-[145%] tracking-[1.02px]"
                >
                  // Sell
                  {getLocale("sell")}
                </button>
              )}
          
            </div> */}
            <div className=" flex justify-end items-center">
              <IconTrash
                onClick={() => handleOrder(item?._id)}
                className="size-6 cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyAdds;
