import { IconChevronDown, IconChevronRight } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getUserProfile,
  getUserProfileUpdate,
  userFireBaseRemoveToken,
} from "../../service/api";
import actions from "../../redux/user/actions";
import { getLocale } from "../../constants/language";

import { ROUTES } from "../../constants/routes";

function Profile() {
  const { firebaseToken, user } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const accordionItems = [
    {
      title: "Personal information",
      content: [
        {
          name: "UID",
          value: `${user?._id?.slice(0, 2)}..${user?._id?.slice(-2)}`,
        },
        {
          name: "First name",
          value: `${user?.firstName}`,
        },
        {
          name: "Last name",
          value: `${user?.lastName}`,
        },
        {
          name: "Phone Number",
          value: `${user?.phoneNumber}`,
        },
      ],
    },
    { title: "Frequently asked questions", content: "Content for FAQs" },
    {
      title: "Terms and conditions",
      content: "Content for terms and conditions",
    },
    { title: "Privacy policy", content: "Content for privacy policy" },
    { title: "Report a problem", content: "Content for report a problem" },
  ];

  const handleLogout = () => {
    if (firebaseToken) {
      FireBaseTokenRemove();
    } else {
      localStorage.clear();
      sessionStorage.clear();
      dispatch(actions.setToken(null));
    }
    navigate(ROUTES.HOME);
    dispatch(actions.setUser(null));
    dispatch(actions.setLoggedIn(false));
  };

  const FireBaseTokenRemove = () => {
    userFireBaseRemoveToken()
      .then((res) => {
        if (res?.status) {
          dispatch(actions.setToken(null));
          localStorage.clear();
          sessionStorage.clear();
          navigate(ROUTES.HOME);
        }
      })
      .catch((e) => {});
  };

  const handleProfile = (data, check) => {
    let body = {
      [data]: check,
    };
    getUserProfileUpdate(body)
      ?.then((res) => {
        if (res?.status) {
          // setUserData({ ...userData, [data]: check });
          let newUser = { ...user, [data]: check };
          dispatch(actions.setUser(newUser));
        }
      })
      .catch((e) => {});
  };

  useEffect(() => {
    getUserProfile()?.then((res) => {
      if (res?.status) {
        dispatch(actions.setUser(res?.data));
      }
    });
  }, []);

  return (
    <div className='className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12"'>
      <div className="pt-[50px]">
        <p className="text-[16px] font-normal text-[#707070] leading-[140%] tracking-[0.2px] text-left">
          {user?.email}
        </p>
        <h1 className=" text-left text-[30px] tracking-[-0.5px] leading-[100%]">
          {/* {user?.firstName}&nbsp;{user?.lastName} {user?.phoneNumber} */}
          {!user?.firstName
            ? user?.phoneNumber
            : `${user?.firstName || ""}${" "}${user?.lastName || ""}`}
        </h1>
      </div>

      <div className="pt-[30px]">
        <h1 className=" text-left text-[22px] tracking-[-1px] leading-[145%]">
          {/* Payment methods */}
          {getLocale("paymentMethod")}
        </h1>
        <button
          onClick={() => navigate(ROUTES.PAYMENTMETHODS)}
          className="w-full p-4 bg-white text-[#111111] rounded-lg mt-2 text-base border border-md border-[#CFCFCF]"
        >
          {/* Add a payment method */}
          {getLocale("addPaymentMethod")}
        </button>
      </div>
      <div className=" mt-[30px]">
        <h1 className=" text-left text-[22px] tracking-[-1px] leading-[145%]">
          {/* My account */}
          {getLocale("myAccount")}
        </h1>
        <div className="mt-[20px]">
          {accordionItems.map((item, index) => (
            <div key={index} className="mb-5">
              <div
                onClick={() => toggleAccordion(index)}
                className="flex justify-between items-center rounded-md py-2 px-2 cursor-pointer hover:bg-gray-200"
              >
                <p className="text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal">
                  {item.title}
                </p>
                {activeIndex === index ? (
                  <IconChevronDown />
                ) : (
                  <IconChevronRight />
                )}
              </div>
              {activeIndex === index && (
                <div className="mt-2 bg-gray-50 p-3 rounded-md text-[#111] text-sm">
                  {Array.isArray(item?.content) && item?.content?.length > 0 ? (
                    item.content.map((obj, index) => (
                      <div
                        key={index}
                        className="flex justify-between items-center w-full py-1"
                      >
                        <p className="opacity-65">{obj?.name}</p>
                        <p>{obj?.value}</p>
                      </div>
                    ))
                  ) : (
                    <p>{item?.content}</p>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className=" mt-[30px]">
        <h1 className=" text-left text-[22px] tracking-[-1px] leading-[145%]">
          {/* Notifications */}
          {getLocale("notifications")}
        </h1>
        <div className="mt-[20px]">
          <div className=" flex justify-between items-center rounded-md py-2 px-2 cursor-pointer">
            <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal">
              {/* Push notifications */}
              {getLocale("pushNotification")}
            </p>
            <label className="inline-flex items-center cursor-pointer gap-2 ">
              <input
                type="checkbox"
                checked={user?.pushNotification}
                onChange={(e) =>
                  handleProfile("pushNotification", e.target.checked)
                }
                className="sr-only peer"
              />

              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
            </label>
          </div>

          <div className="mt-3">
            <div
              onClick={() => navigate(ROUTES.NOTIFICATION_SETTING)}
              className=" flex justify-between items-center rounded-md py-2 px-2 active:bg-[#ccc] cursor-pointer"
            >
              <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal">
                {/* Notification settings */}
                {getLocale("notificationsSettings")}
              </p>
              <IconChevronRight />
            </div>
          </div>
        </div>
      </div>
      <div className=" mt-[30px] pb-5">
        <h1 className=" text-left text-[22px] tracking-[-1px] leading-[145%]">
          {/* Security */}
          {getLocale("security")}
        </h1>
        <div className=" mt-[20px]">
          <div className="flex justify-between items-center mt-5 px-2 py-2 rounded-md">
            <div className=" flex items-center gap-4">
              <div className="w-7 h-7 rounded-full bg-bgBlue text-white flex justify-center items-center">
                1
              </div>
              <h2 className=" text-[18px] font-medium leading-[145%] tracking-[-1px]">
                {/* Create an account */}
                {getLocale("createAnAcc")}
              </h2>
            </div>
            <p>{getLocale("completed")}</p>
          </div>
          <div
            onClick={() => {
              if (!user?.isEmailVerified) {
                navigate(ROUTES.EMAIL_VERIFIY);
              }
            }}
            className={`flex justify-between items-center mt-5 px-2 py-2 rounded-md ${
              !user?.isEmailVerified ? "cursor-pointer" : "cursor-auto"
            }`}
          >
            <div className=" flex items-center gap-4">
              <div
                className={`w-7 h-7 rounded-full ${
                  user?.isEmailVerified
                    ? "bg-bgBlue text-white"
                    : "text-[#707070] bg-[#F3F3F3]"
                } flex justify-center items-center`}
              >
                2
              </div>
              <div className=" w-[70%]">
                <h2 className=" text-[18px] font-medium leading-[145%] tracking-[-1px] text-left">
                  {/* Email verification */}
                  {getLocale("step4")}
                </h2>
                <p className=" text-left text-[#707070] text-[16px] font-normal tracking-[0.02px] leading-[140%] w-full">
                  {/* Accede al link que enviamos a tu correo */}
                  {getLocale("accessLink")}
                </p>
              </div>
            </div>
            <p>{user?.isEmailVerified ? "Completed" : "Pending"}</p>
          </div>
          <div
            onClick={() => {
              if (!(user?.isEmailVerified && user.isMobileVerified)) {
                navigate(ROUTES.SECURITY);
              }
            }}
            className={`flex justify-between items-center mt-5 px-2 py-2 rounded-md ${
              !user?.isMobileVerified ? "cursor-pointer" : "cursor-auto"
            }`}
          >
            <div className=" flex items-center gap-4">
              <div
                className={`w-7 h-7 rounded-full ${
                  user?.isMobileVerified
                    ? "bg-bgBlue text-white"
                    : "text-[#707070] bg-[#F3F3F3]"
                } flex justify-center items-center`}
              >
                3
              </div>
              <div>
                <h2 className=" text-[18px] font-medium leading-[145%] tracking-[-1px] text-left">
                  {/* Secure your account */}
                  Mobile verification
                </h2>
                <p className=" text-left text-[#707070] text-[16px] font-normal tracking-[0.02px] leading-[140%]">
                  {/* Verificación 2FA */}
                  {getLocale("step2Info")}
                </p>
              </div>
            </div>
            <p>{user?.isMobileVerified ? "Completed" : "Pending"}</p>
          </div>
          <div
            onClick={() => {
              if (user?.kycStatus === "PENDING") {
                if (user?.kycLevel === 1) {
                  navigate(ROUTES.SECURITY_ID);
                } else if (user?.kycLevel === 2) {
                  navigate(ROUTES.PERSONAL_INFO);
                } else if (user?.kycLevel === 3) {
                  navigate(ROUTES.S_PHOTO_ID);
                }
              }
            }}
            className={`flex justify-between items-center ${
              user?.kycStatus === "IN-PROGRESS"
                ? "cursor-not-allowed opacity-50"
                : "cursor-pointer"
            } mt-5 px-2 py-2 rounded-md `}
          >
            <div className=" flex items-center gap-4">
              <div
                className={`w-7 h-7 rounded-full ${
                  user?.kycStatus === "APPROVED"
                    ? "bg-bgBlue text-white"
                    : "text-[#707070] bg-[#F3F3F3]"
                } flex justify-center items-center`}
              >
                4
              </div>
              <div className=" w-[70%]">
                <h2 className=" text-[18px] font-medium leading-[145%] tracking-[-1px] text-left">
                  {/* Verify your identity */}
                  {getLocale("verifyId")}
                </h2>
                <p className=" text-left text-[#707070] text-[16px] font-normal tracking-[0.02px] leading-[140%] w-[100%]">
                  {/* Requerido por regulaciones financieras */}
                  {getLocale("step3Info")}
                </p>
              </div>
            </div>
            <p>{user?.kycStatus}</p>
          </div>

          <div className=" w-full mt-5 flex justify-center items-center">
            <Link
              to={"/changePassword"}
              className="w-full p-4 bg-white text-textPrime rounded-lg mt-8 text-base border border-md border-[#CFCFCF]"
            >
              {/* Change Password */}
              {getLocale("changePass")}
            </Link>
          </div>
          <div>
            <button
              onClick={() => {
                handleLogout();
              }}
              className="w-full p-4 bg-white text-[#E25C5C] rounded-lg mt-8 text-base cursor-pointer border border-md border-[#CFCFCF]"
            >
              {/* Log out */}
              {getLocale("logOut")}
            </button>
            <p className=" mt-2 text-left text-[14px] text-[#707070] leading-[145%] tracking-normal">
              {getLocale("appVersion")}: 9.26.4 (92604),{" "}
              {getLocale("production")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
