import { IconArrowNarrowLeft, IconEdit, IconX } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAllCoins, getAllPayMethodByUser } from '../../../service/api';
import { Danger } from '../../Core/Errors';
import Loader from '../../Core/Loader';
import { getLocale } from "../../../constants/language";

const data = [
    {
        title: "IMPS",
        color: "bg-yellow-500",
        name: "Fenil Borad",
        upiId: "",
        type: "Saving",
        ifsc: "SBIN022932",
        bankName: "State Bank of India",
        brnach: "Jaipur",
        account: "123014569872"

    },
    {
        title: "Paytm",
        color: "bg-blue-500",
        name: "Fenil Borad",
        upiId: "620172327@sbi",
        type: "",
        ifsc: "",
        bankName: "",
        brnach: "",
        account: ""

    },
    {
        title: "UPI",
        color: "bg-red-500",
        name: "Fenil Borad",
        upiId: "620172327@sbi",
        type: "",
        ifsc: "",
        bankName: "",
        brnach: "",
        account: ""
    },
    {
        title: "Bank tranfer (India)",
        color: "bg-orange-500",
        name: "Fenil Borad",
        upiId: "",
        type: "Saving",
        ifsc: "SBIN022932",
        bankName: "State Bank of India",
        brnach: "Jaipur",
        account: "123014569872"
    },


]
function PaymentMethods() {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [sizePerPage, setSizePerPage] = useState(10)
    const [count, setCount] = useState(0)
    const [data, setData] = useState([])
    const getAllPaymethods = () => {
        try {
            setLoader(true)
            getAllPayMethodByUser(pageNumber, true, sizePerPage).then(res => {
                console.log('res: ', res);
                setData(res?.data?.result)
                setLoader(false);
            }).catch(error => {

                Danger(error?.response?.data?.message)
                setLoader(false);
            })
        } catch (error) {

            Danger(error?.response?.data?.message)
            setLoader(false);
        }
    }

    useEffect(() => {
        getAllPaymethods()
    }, [])
    return (
        <div className=' bg-bgWhite'>
            {loader && <Loader />}
            <div className="h-[90vh] relative bg-white font-sans px-4 py-4 flex flex-col ">
                <div>
                    <div className=' flex justify-between items-center' >
                        <IconArrowNarrowLeft className="size-7 cursor-pointer" onClick={() => navigate(-1)} />
                        <h1 className='font-medium leading-[145%] tracking-[-1px] text-[18px] text-[#111111] '>P2P Payment Methods</h1>
                        <p></p>
                    </div>
                </div>

                <div className=' mt-2 max-h-[80vh] flex flex-col overflow-y-auto py-[120px] no-scrollbar  '>
                    {
                        data?.map((item, index) => (
                            <P2PPaymentCard key={`paymethods-${index}`} item={item} />
                        ))
                    }
                </div>

                <div onClick={() => navigate("/recomended")} className=' px-4 w-full absolute bottom-[70px] left-0 justify-center flex items-center '>
                    <button className=' w-[95%]  text-clip absolute bottom-0 bg-bgBlue text-textWhite py-2.5 rounded-md tracking-[0.2px] leading-[140%] mb-2'>Add</button>
                </div>
            </div>

        </div>
    )
}

export default PaymentMethods

const P2PPaymentCard = ({ item }) => {
    console.log('item: ', item);
    const navigate = useNavigate()
    return (
        <div className="rounded-md bg-opacity-10 w-full cursor-pointer mt-2 flex py-4 mx-auto active:bg-[#ccc] border justify-between items-center px-2">
            <div>
                <div className="text-[#111] text-left text-base mr-auto flex h-full items-center">
                    {/* <div className={`${item.color} w-[2px] h-3`}></div> */}
                    <p className=' uppercase text-[12px] font-semibold'>{item.type}</p>
                </div>
                {Object.entries(item?.details ?? {}).map(([key, value], index) => (
                    <div className='text-left'>
                        <p className=' text-[12px] text-textPrime leading-[140%] tracking-[0.02px]'>{value}</p>
                    </div>

                ))}
            </div>
            <IconEdit className=' cursor-pointer text-textPrime ' onClick={() => navigate("/editPayMethod", { state: { payId: item?._id, typeId: item?._id } })} />
            {/* <div className=' px-3 text-left'>
                {item?.account && <p className=' text-[12px] text-textPrime leading-[140%] tracking-[0.02px]'>{item?.account}</p>}
                {item?.ifsc && <p className=' text-[12px] text-textPrime leading-[140%] tracking-[0.02px]'>{item?.ifsc}</p>}
                {item?.type && <p className=' text-[12px] text-textPrime leading-[140%] tracking-[0.02px]'>{item?.type}</p>}
                {item?.bankName && <p className=' text-[12px] text-textPrime leading-[140%] tracking-[0.02px]'>{item?.bankName}</p>}
                {item?.brnach && <p className=' text-[12px] text-textPrime leading-[140%] tracking-[0.02px]'>{item?.brnach}</p>}
            </div> */}
        </div>
    )
}