import {
  IconArrowNarrowLeft,
  IconArrowsSort,
  IconCreditCard,
} from "@tabler/icons-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import visa from "../../Assets/image/visa.png";
import masterCard from "../../Assets/image/mastercard.png";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";
// import { getLocale } from "../../../constants/language";

function AddPaymentMethod() {
  const navigate = useNavigate();
  const [number, setNumber] = useState("1000");
  const [isInfo, setIsInfo] = useState(true);
  const handleClick = (value, index) => {
    if (index === 11) {
      let copyNumber = number;
      copyNumber = copyNumber.substring(0, number.length - 1);
      setNumber(copyNumber);
    } else {
      setNumber((prev) => prev + value);
    }
  };
  return (
    <div>
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 pt-12 pb-3 flex flex-col justify-between ">
        <div>
          <div className=" flex justify-between items-center">
            <IconArrowNarrowLeft
              className="size-7 cursor-pointer"
              onClick={() => navigate(-1)}
            />
            <h1 className="font-medium leading-[145%] tracking-[-1px] text-[18px] text-[#111111] ">
              {" "}
            </h1>
            <p></p>
          </div>
          <div className="mt-5 ">
            <h1 className="font-medium leading-[145%] tracking-[-1px] text-[22px] text-[#111111] text-left">
              {/* Add a payment method */}
              {getLocale("addPaymentMethod")}
            </h1>
          </div>
          <div className="mt-5 ">
            <div className=" text-left">
              <label htmlFor="" className=" mb-5 ">
                {/* Name on the card */}
                {getLocale("nameOnCard")}
              </label>
              <input
                placeholder="Omar Chavez Gutierrez"
                className={` w-full h-full block border-2  rounded-md text-base font-proxima-regular px-5 py-4 mt-1 placeholder:font-proxima-regular placeholder:text-base focus:outline-none text-black outline-none`}
              />
            </div>
            <div className=" text-left mt-3">
              <label htmlFor="" className=" mb-5 ">
                {/* Name on the card */}
                {getLocale("nameOn Card")}
              </label>
              <div
                className={` w-full h-full border-2  rounded-md text-base font-proxima-regular mt-1  text-black flex justify-between items-center pr-2`}
              >
                <input
                  placeholder="XXXX XXXX XXXX XX"
                  className={` w-[70%] h-full block  rounded-md text-base font-proxima-regular px-5 py-4 placeholder:font-proxima-regular text-black outline-none`}
                />
                <div className=" flex items-center gap-3 w-[30%] justify-end">
                  <img src={visa} alt="" />
                  <img src={masterCard} alt="" />
                </div>
              </div>
            </div>
            <div className=" text-left mt-3">
              <div
                className={` w-full h-full rounded-md text-base font-proxima-regular mt-1  text-black flex justify-between items-center pr-2 gap-5`}
              >
                <div className=" w-1/2 ">
                  <label htmlFor="" className=" mb-5 ">
                    {/* Expiration date */}
                    {getLocale("exp")}
                  </label>
                  <input
                    placeholder="MM/AA"
                    className={`w-full h-full block border-2  rounded-md text-base font-proxima-regular px-5 py-4 mt-1 placeholder:font-proxima-regular placeholder:text-base focus:outline-none text-black outline-none`}
                  />
                </div>
                <div className=" w-1/2">
                  <label htmlFor="" className=" mb-5 ">
                    {/* CVC */}
                    {getLocale("cvc")}
                  </label>
                  <input
                    placeholder="123"
                    className={` w-full h-full block border-2  rounded-md text-base font-proxima-regular px-5 py-4 placeholder:font-proxima-regular text-black outline-none`}
                  />
                </div>
              </div>
            </div>
            <div className=" text-left mt-3">
              <label htmlFor="" className=" mb-5 ">
                {/* Postal Code */}
                {getLocale("postalCode")}
              </label>
              <input
                placeholder="012345"
                className={` w-full h-full block border-2  rounded-md text-base font-proxima-regular px-5 py-4 mt-1 placeholder:font-proxima-regular placeholder:text-base focus:outline-none text-black outline-none`}
              />
            </div>
          </div>
        </div>
        <div className="mt-[100px]">
          <p className="text-[14px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal text-left ">
            {/* By adding a new card, you agree to the credit/debit card terms and
            conditions. */}
            {getLocale("paymentInfo")}
          </p>

          <button
            onClick={() => navigate(ROUTES.ORDER_SUMMARY)}
            className="w-full p-4 bg-bgBlue text-white rounded-lg mt-10 text-base"
          >
            {/* Add card */}
            {getLocale("addCard")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddPaymentMethod;
