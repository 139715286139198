import React, { useState, useEffect } from "react";
import { getLocale } from "../../../constants/language";

const Countdown = ({ initialSeconds, cancelOrder }) => {
  const [timeLeft, setTimeLeft] = useState(initialSeconds);

  // Function to format seconds into MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };

  useEffect(() => {
    // Check if a target time exists in localStorage
    const storedTargetTime = localStorage.getItem("targetTime");

    if (storedTargetTime) {
      const targetTime = new Date(storedTargetTime).getTime();
      const now = new Date().getTime();
      const remainingTime = Math.max(0, targetTime - now);

      setTimeLeft(Math.floor(remainingTime / 1000)); // Convert ms to seconds
    } else {
      // Calculate and store the target time
      const targetTime = new Date().getTime() + initialSeconds * 1000; // ms
      localStorage.setItem("targetTime", new Date(targetTime).toISOString());
      setTimeLeft(initialSeconds);
    }

    // Countdown timer logic
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          localStorage.removeItem("targetTime");
          cancelOrder();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    window.addEventListener("beforeunload", () => {
        localStorage.removeItem("targetTime");
    });

    return () => clearInterval(interval); // Cleanup on unmount
  }, [initialSeconds]);

  return <div>{getLocale("timeLeft")} {formatTime(timeLeft)}</div>;
};

export default Countdown;
