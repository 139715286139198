import { IconChevronLeft, IconLockFilled, IconX } from "@tabler/icons-react";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import stepImage from "../../Assets/image/step2.png";
import cardImage from "../../Assets/image/SecureAccount.png";
import { getLocale } from "../../constants/language";

import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import * as yup from "yup";
import { useFormik } from "formik";
import { addMobile, resendMobile } from "../../service/api";
import { Danger, Success, Warning } from "../Core/Errors";
import Loader from "../Core/Loader";
import { ROUTES } from "../../constants/routes";
import { useSelector } from "react-redux";
const validationSchema = yup.object().shape({
  mobile: yup.string().nonNullable().required("Mobile number is required."),
});
function TwoFA() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.user);

  const [mobileNumber, setMobileNumber] = useState(user?.phoneNumber);

  const email = useLocation().state?.email;

  const handleMobileOtpSend = () => {
    try {
      if (!mobileNumber) {
        Warning("Please Enter valid mobile number");
        return;
      }
      setLoading(true);
      addMobile({ mobile: mobileNumber })
        .then((res) => {
          Success(res?.message).then((_) => {
            navigate(ROUTES.CONFIRM_TWO_FA_MOB_KYC, {
              state: { mobile: mobileNumber },
            });
          });
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoading(false);
        })
        .finally((res) => {
          setLoading(false);
        });
    } catch (error) {}
  };

  return (
    <div>
      {loading && <Loader />}
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col justify-between">
        <div>
          <div className=" flex justify-between items-center">
            <IconX
              className="size-7 cursor-pointer"
              onClick={() => navigate(-1)}
            />
            <img src={stepImage} alt="step-top" />
            <p></p>
          </div>
          <div className=" mt-9 flex justify-center flex-col w-full ">
            <h2 className=" text-[22px] font-medium text-left leading-[145%] tracking-[-1px] ">
              {/* Two step verification process (2FA) */}
              {getLocale("twoFA")}
            </h2>
            <p className="text-left text-[#707070] text-[16px] font-normal tracking-[0.02px] mt-1 leading-[140%]">
              {/* Enter your phone number so we can send you an authentication code
              via text message. */}
              {getLocale("twoFAInfo")}
            </p>

            <div>
              <PhoneInput
                international
                placeholder="Tu número de teléfono"
                defaultCountry="IN"
                value={mobileNumber}
                countries={[]}
                onChange={(e) => {
                  setMobileNumber(e);
                }}
              />
            </div>
          </div>
        </div>
        <div className=" mt-6">
          <button
            onClick={() => handleMobileOtpSend()}
            //  onClick={() =>             navigate("/confirmTwoFaMob", { state: { mobile: mobileNumber } })  }
            type="submit"
            disabled={loading}
            className="w-full p-4 bg-bgBlue text-white rounded-lg mt-2 text-base"
          >
            {/* Continue */}
            {getLocale("continue")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default TwoFA;
