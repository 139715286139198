import React, { useState } from "react";
import {
  IconChevronLeft,
  IconChevronRight,
  IconLockFilled,
} from "@tabler/icons-react";
import { CustomInput } from "../Core/input";
import { useLocation, useNavigate } from "react-router-dom";
import stepImage from "../../Assets/image/step3start.png";
import { Danger, Success } from "../Core/Errors";
import { addAmount } from "../../service/api";
import Loader from "../Core/Loader";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";
const expectations = [
  {
    title: "< $1,000",
    value: "<1000",
  },
  {
    title: "$1,000 - $9,999",
    value: "1000 - 9999",
  },
  {
    title: "$10,000-$99,999",
    value: "10000 - 99999",
  },
  {
    title: "> $100,000",
    value: ">100000",
  },
  {
    title: "Otro",
    value: "Otro",
  },
];
const Expectations = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const email = useLocation().state?.email;
  console.log("email: ", email);
  const handleResendCode = (value) => {
    console.log("value: ", value);
    try {
      setLoading(true);
      addAmount({ amount: value })
        .then((resp) => {
          console.log("resp: ", resp);
          if (resp.status) {
            Success(resp?.message).then((_) => {
              navigate(ROUTES.SUCCESS_REGISTER, { state: { email: email } });
            });
          }
        })
        .catch((error) => {
          console.log("error: ", error?.response?.data?.message);
          Danger(error?.response?.data?.message);
          setLoading(false);
        })
        .finally((res) => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col justify-between">
      {loading && <Loader />}
      <div>
        <div className=" flex justify-between items-center">
          <IconChevronLeft
            className="size-7 cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <img src={stepImage} alt="step-top" />
          <p></p>
        </div>

        <div className="flex items-start flex-col w-full mt-6">
          <div>
            <h3 className="text-2xl font-medium text-left ">
              {/* ¿Cuánto dinero esperas comerciar por año? */}
              {getLocale("expectation")}
            </h3>
            <p className="text-[#707070] text-[16px] space-x-[0.02px] text-left">
              {/* We are legally required to collect this information. If you are
              not employed or your industry is not listed, select "Other." */}
              {getLocale("weRequire")}
            </p>
          </div>
        </div>
        <div className="mt-5">
          {expectations?.map((purpose, index) => (
            <div
              onClick={() => handleResendCode(purpose.value)}
              className=" flex justify-between items-center px-2 py-2 mt-2 transition-all rounded-md cursor-pointer active:bg-[#ccc]"
            >
              <div className=" flex items-center gap-2">
                <p className=" text-[16px] font-normal space-x-[0.2px text-[#111111] ">
                  {purpose.title}
                </p>
              </div>
              <IconChevronRight className=" cursor-pointer" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Expectations;
