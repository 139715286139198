import { IconChevronLeft, IconLockFilled, IconX } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import stepImage from "../../Assets/image/step2.png";
import cardImage from "../../Assets/image/securityEmail.png";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";

function SecurityEmail() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col justify-between">
        <div>
          <div className=" flex justify-between items-center">
            <IconX
              className="size-7 cursor-pointer"
              onClick={() => navigate(-1)}
            />
            <img src={stepImage} alt="step-top" />
            <p></p>
          </div>
          <div className="flex items-start justify-center w-full mt-[50px]">
            <img src={cardImage} alt="card-image" />
          </div>
          <div className=" mt-9">
            <h2 className=" text-[22px] font-[700] text-center leading-[145%] tracking-[-1px] ">
              {/* Verify your email */}
              {getLocale("verifyEmail")}
            </h2>
            <p className="text-center text-[#707070] text-[16px] font-normal tracking-[0.02px] mt-1 leading-[140%]">
              {/* We have sent a verification link to your email. Please access this
              link to continue */}
              {getLocale("verifyEmailInfo")}
            </p>
          </div>
        </div>
        <div className=" mt-6">
          <button
            onClick={() => navigate(ROUTES.TWO_FA)}
            className="w-full p-4 bg-bgBlue text-white rounded-lg mt-2 text-base"
          >
            {/* Check my email */}
            {getLocale("checkMail")}
          </button>
          <button className="w-full p-4 bg-white text-[#111111] rounded-lg mt-2 text-base border border-[#CFCFCF]">
            {/* Send again */}
            {getLocale("sendAgain")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SecurityEmail;
