import {
  IconArrowsExchange2,
  IconBellPlus,
  IconCaretDownFilled,
  IconChevronLeft,
  IconClock,
  IconFilter,
  IconRecharging,
  IconRosetteDiscountCheck,
  IconSearch,
  IconThumbUp,
} from "@tabler/icons-react";
import { getLocale } from "../../../constants/language";

import React, { useEffect, useState } from "react";
import PopUp from "./PopUp";
import { useNavigate } from "react-router-dom";
import { Danger } from "../../Core/Errors";
import { getAllP2pOrders } from "../../../service/api";
import Filter from "./Filter";
import CoinSlider from "./CoinSlider";
import Loader from "../../Core/Loader";
import { NumericFormat } from "react-number-format";
import actions from "../../../redux/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../../constants/routes";
import NotradeModal from "../NotradeModal";
const currList = [
  "USD", // US Dollar
  "EUR", // Euro
  "GBP", // British Pound
  "JPY", // Japanese Yen
  "CAD", // Canadian Dollar
  "AUD", // Australian Dollar
  "CHF", // Swiss Franc
  "CNY", // Chinese Yuan
  "INR", // Indian Rupee
  "BRL", // Brazilian Real
  "RUB", // Russian Ruble
  "ZAR", // South African Rand
  "MXN", // Mexican Peso
  "KRW", // South Korean Won
  "SGD", // Singapore Dollar
  "NZD", // New Zealand Dollar
  "TRY", // Turkish Lira
  "SEK", // Swedish Krona
  "NOK", // Norwegian Krone
  "DKK", // Danish Krone
];
const paymentMethods = [
  "All",
  "Lightning UPI",
  "UPI",
  "IMPS",
  "Bank Transfer (India)",
  "Digital eRupee",
  "Google Pay (GPay)",
  "IMPS-PAN",
  "International Wire",
  "Paytm",
  "PhonePe",
  "Skrill (Moneybookers)",
  "UPI-PAN",
  "Western Union",
];

const P2PHome = () => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(true);
  const [currFilter, setCurrFilter] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState({ _id: -1 });
  const [amountFilter, setAmountFilter] = useState(false);
  const [paymentFilter, setPaymentFilter] = useState(false);
  const [extraFIlter, setExtraFilter] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("INR");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("All");
  const [pageNumber, setPageNumber] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [data, setData] = useState([]);
  const [warn, setWarn] = useState(false);

  function formatNumber(num) {
    if (num < 1000) {
      return num.toString(); // Return the number as a string if less than 1000
    } else {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "k"; // Convert to k notation, remove decimal if unnecessary
    }
  }

  const getAllP2POrders = () => {
    try {
      setLoader(true);
      getAllP2pOrders(pageNumber, true, sizePerPage)
        .then((res) => {
          console.log("res: ", res);
          setData(res?.data?.result);
          setLoader(false);
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoader(false);
        });
    } catch (error) {
      Danger(error?.response?.data?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllP2POrders();
  }, []);

  return (
    <div>
      <NotradeModal warn={warn} setWarn={setWarn} />

      <div>
        <div className="  relative overflow-x-hidden">
          {loader && <Loader />}
          <div className="h-full min-h-[100vh] font-sansflex flex-col bg-bgWhite relative">
            <div className=" flex justify-between items-center pt-12 w-full px-4 py-4 bg-bgBlue">
              <IconChevronLeft
                color="white"
                className="size-7 cursor-pointer"
                onClick={() => navigate(-1)}
              />
              <h1 className=" flex justify-center items-center text-textWhite font-bold tracking-[1.02px] leading-[140%] gap-1">
                P2P <IconArrowsExchange2 color="white" />
              </h1>
              <div>
                <select
                  value={selectedCurrency}
                  onChange={(e) => setSelectedCurrency(e.target.value)}
                  className="outline-none border-[1px] border-borderWhite rounded-md px-4 py-1"
                >
                  {currList?.map((curr, index) => (
                    <option value={curr}>{curr}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className=" h-full w-full bg-bgWhite rounded-tl-xl rounded-tr-xl py-4 z-10 ">
              <div className=" flex justify-between items-center px-4 ">
                <div className="flex justify-between text-[16px] leading-[140%] px-0.5 py-0.5  items-center bg-bgWhite border h-[35px]  min-w-[160px] rounded-md overflow-hidden ">
                  <button
                    onClick={() => setIsActive(true)}
                    className={`  transition-all px-2 ${
                      isActive ? "bg-bgBlue text-white" : ""
                    } rounded-md h-full w-1/2`}
                  >
                    {/* Buy */}
                    {getLocale("buy")}
                  </button>
                  <button
                    onClick={() => setIsActive(false)}
                    className={` transition-all px-2 ${
                      isActive ? "" : "bg-bgBlue text-white"
                    } rounded-md h-full w-1/2`}
                  >
                    {/* Sell */}
                    {getLocale("sell")}
                  </button>
                </div>

                <div className=" relative ">
                  <IconBellPlus className=" text-textPrime cursor-pointer" />
                  <div className=" w-1 h-1  rounded-full absolute top-[2px] right-1">
                    {" "}
                  </div>
                </div>
              </div>
              <div className=" flex justify-between items-center w-full mt-5 px-4 pb-2 ">
                <div className=" flex items-center gap-2">
                  <div
                    onClick={() => setCurrFilter(!currFilter)}
                    className=" flex items-start gap-1 cursor-pointer"
                  >
                    {/* <IconCurrencyDollar className='text-textPrime' size={12} /> */}
                    <p className=" text-[12px]">USDT</p>
                    <IconCaretDownFilled
                      className={` transition-all text-textPrime mt-[2px] ${
                        currFilter ? "rotate-180" : ""
                      }`}
                      size={12}
                    />
                  </div>
                  <div
                    onClick={() => setAmountFilter(!amountFilter)}
                    className=" flex items-start gap-1 cursor-pointer"
                  >
                    {/* <IconCurrencyDollar className='text-textPrime' size={12} /> */}
                    <p className=" text-[12px]">
                      {/* Amount */}
                      {getLocale("amount")}
                    </p>
                    <IconCaretDownFilled
                      className={` transition-all text-textPrime mt-[2px] ${
                        amountFilter ? "rotate-180" : ""
                      }`}
                      size={12}
                    />
                  </div>
                  <div
                    onClick={() => setPaymentFilter(!paymentFilter)}
                    className=" flex items-start gap-1 cursor-pointer"
                  >
                    {/* <IconCurrencyDollar className='text-textPrime' size={12} /> */}
                    <p className=" text-[12px]">
                      {/* Payment */}
                      {getLocale("payment")}
                    </p>
                    <IconCaretDownFilled
                      className={` transition-all text-textPrime mt-[2px] ${
                        paymentFilter ? "rotate-180" : ""
                      }`}
                      size={12}
                    />
                  </div>
                </div>
                <div className=" relative">
                  <IconFilter
                    onClick={() => setExtraFilter(true)}
                    className=" text-textPrime cursor-pointer"
                  />
                  <div className=" w-1 h-1 bg-red-500 rounded-full absolute top-[1px] right-[1px]">
                    {" "}
                  </div>
                </div>
              </div>
              <div className=" relative bg-bgWhite">
                <div
                  className={` bg-bgWhite max-h-[75vh] overflow-y-auto w-full left-0 top-0 absolute transition-all py-2 ${
                    isActive ? "translate-x-0 " : " translate-x-[100%]"
                  } no-scrollbar`}
                >
                  {data && data?.length > 0 ? (
                    data?.filter((filt) => filt?.type === "SELL")?.length >
                    0 ? (
                      data
                        ?.filter((filt) => filt?.type === "SELL")
                        ?.map((item, index) => (
                          <P2PCard
                            buy={true}
                            key={`buy-${index}`}
                            item={item}
                            setWarn={setWarn}
                          />
                        ))
                    ) : (
                      <div className="bg-transparent border border-gray-600 rounded-lg p-4 w-[90%] mx-auto text-black font-medium text-lg mt-6">
                        {/* No any buy order found */}
                        {getLocale("noOrder")}{" "}
                      </div>
                    )
                  ) : (
                    <div className="bg-transparent border border-gray-600 rounded-lg p-4 w-[90%] mx-auto text-black font-medium text-lg mt-6">
                      {/* No any buy order found */}
                      {getLocale("noOrder")}{" "}
                    </div>
                  )}
                </div>
                <div
                  className={`bg-bgWhite max-h-[75vh] transition-all left-0 top-0 w-full absolute overflow-y-auto py-2 ${
                    isActive ? "-translate-x-[100%]" : " translate-x-0"
                  } no-scrollbar`}
                >
                  {data && data?.length > 0 ? (
                    data?.filter((filt) => filt?.type === "BUY")?.length > 0 ? (
                      data
                        ?.filter((filt) => filt?.type === "BUY")
                        ?.map((item, index) => (
                          <P2PCard
                            setWarn={setWarn}
                            buy={false}
                            key={`sell-${index}`}
                            item={item}
                          />
                        ))
                    ) : (
                      <div className="bg-transparent border border-gray-600 rounded-lg p-4 w-[90%] mx-auto text-black font-medium text-lg mt-6">
                        {/* No any sell order found */}
                        {getLocale("noOrder")}{" "}
                      </div>
                    )
                  ) : (
                    <div className="bg-transparent border border-gray-600 rounded-lg p-4 w-[90%] mx-auto text-black font-medium text-lg mt-6">
                      {/* No any sell order found */}
                      {getLocale("noOrder")}{" "}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <CoinSlider
            currFilter={currFilter}
            setCurrFilter={setCurrFilter}
            selectedCoin={selectedCoin}
            setSelectedCoin={setSelectedCoin}
          />
          <PopUp
            open={amountFilter}
            setOpen={setAmountFilter}
            title={"I Want to Buy"}
          >
            <div className=" px-4">
              <div className=" flex justify-between items-center w-full ">
                <div className=" w-full bg-bgGray items-center rounded-md h-[40px] flex justify-between gap-1 px-2">
                  <input
                    value={selectedAmount}
                    onChange={(e) => setSelectedAmount(e.target.value)}
                    type="number"
                    className=" w-full h-full border-none outline-none placeholder:opacity-[0.5] bg-transparent "
                    placeholder="e.g. 200"
                  />
                  <p className=" text-textPrime tracking-[0.2px] leading-[145%] ">
                    {selectedCurrency}
                  </p>
                </div>
              </div>

              <div className=" flex justify-between items-center gap-2 mt-2 flex-wrap">
                {[100, 1000, 5000, 7000]?.map((item, index) => (
                  <div
                    onClick={() => setSelectedAmount(item)}
                    className={` cursor-pointer ${
                      selectedAmount === item
                        ? "bg-bgGray text-textPrime "
                        : "bg-primBlue text-textWhite "
                    } px-3 min-w-[80px]  rounded-sm py-2`}
                  >
                    {formatNumber(item)}
                  </div>
                ))}
              </div>
              <div className=" w-full flex justify-between items-center mt-2 gap-2">
                <button
                  onClick={() => setSelectedAmount("")}
                  className=" w-1/2 text-clip bg-bgGray text-textPrime py-2 rounded-md tracking-[0.2px] leading-[140%]"
                >
                  {/* Reset */}
                  {getLocale("reset")}
                </button>
                <button
                  onClick={() => {
                    if (selectedAmount > 0) {
                      setAmountFilter(false);
                    }
                  }}
                  className=" w-1/2 text-clip bg-bgBlue text-textWhite py-2 rounded-md tracking-[0.2px] leading-[140%]"
                >
                  {/* Confirm */}
                  {getLocale("confirm")}
                </button>
              </div>
            </div>
          </PopUp>
          <PopUp
            open={paymentFilter}
            setOpen={setPaymentFilter}
            title={"Payment Methods"}
          >
            <div className="px-4 pb-12 flex flex-col justify-between h-full">
              <div>
                <div className=" flex  bg-bgGray items-start gap-2 rounded-md py-2  px-2">
                  <IconRecharging className="text-orange-600 mt-1" size={15} />
                  <p className="text-[14px] text-left tracking-[0.2px] leading-[140%]">
                    {/* Release the crypto automatically upon payment complition */}
                    {getLocale("releaseCrypto")}
                  </p>
                </div>
                <div className=" flex justify-between items-center w-full mt-2">
                  <div className=" w-full bg-bgGray items-center rounded-md h-[30px] flex justify-between gap-1 pl-1">
                    <IconSearch size={20} />
                    <input
                      type="text"
                      className=" w-full h-full border-none outline-none placeholder:opacity-[0.5] bg-transparent "
                      placeholder="Search"
                    />
                  </div>
                </div>

                <div className=" grid grid-cols-2 m-auto gap-1 mt-2 ">
                  {paymentMethods?.map((item, index) => (
                    <div
                      onClick={() => setSelectedPaymentMethod(item)}
                      key={`payMethod-${index}`}
                      className={`text-center flex justify-center px-2 py-1 ${
                        selectedPaymentMethod === item
                          ? " bg-bgGray text-textPrime"
                          : "bg-bgBlue text-textWhite "
                      } rounded-md cursor-pointer`}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              </div>

              <div className=" w-full flex justify-between items-center mt-2 gap-2">
                <button
                  onClick={() => setSelectedPaymentMethod("")}
                  className=" w-1/2 text-clip bg-bgGray text-textPrime py-2 rounded-md tracking-[0.2px] leading-[140%]"
                >
                  {/* Reset */}
                  {getLocale("reset")}
                </button>
                <button
                  onClick={() => {
                    if (selectedPaymentMethod?.length > 0) {
                      setPaymentFilter(false);
                    }
                  }}
                  className=" w-1/2 text-clip bg-bgBlue text-textWhite py-2 rounded-md tracking-[0.2px] leading-[140%]"
                >
                  {/* Confirm */}
                  {getLocale("confirm")}
                </button>
              </div>
            </div>
          </PopUp>

          <PopUp open={extraFIlter} setOpen={setExtraFilter}>
            <Filter selectedCurrency={selectedCurrency} />
          </PopUp>
        </div>
      </div>
      <CoinSlider
        currFilter={currFilter}
        setCurrFilter={setCurrFilter}
        selectedCoin={selectedCoin}
        setSelectedCoin={setSelectedCoin}
      />
      <PopUp
        open={amountFilter}
        setOpen={setAmountFilter}
        title={"I Want to Buy"}
      >
        <div className=" px-4">
          <div className=" flex justify-between items-center w-full ">
            <div className=" w-full bg-bgGray items-center rounded-md h-[40px] flex justify-between gap-1 px-2">
              <input
                value={selectedAmount}
                onChange={(e) => setSelectedAmount(e.target.value)}
                type="number"
                className=" w-full h-full border-none outline-none placeholder:opacity-[0.5] bg-transparent "
                placeholder="e.g. 200"
              />
              <p className=" text-textPrime tracking-[0.2px] leading-[145%] ">
                {selectedCurrency}
              </p>
            </div>
          </div>

          <div className=" flex justify-between items-center gap-2 mt-2 flex-wrap">
            {[100, 1000, 5000, 7000]?.map((item, index) => (
              <div
                onClick={() => setSelectedAmount(item)}
                className={` cursor-pointer ${
                  selectedAmount === item
                    ? "bg-bgGray text-textPrime "
                    : "bg-primBlue text-textWhite "
                } px-3 min-w-[90px]  rounded-md py-1`}
              >
                {formatNumber(item)}
              </div>
            ))}
          </div>
          <div className=" w-full flex justify-between items-center mt-4 gap-2">
            <button
              onClick={() => setSelectedAmount("")}
              className=" w-1/2 text-clip bg-bgGray text-textPrime py-2 rounded-md tracking-[0.2px] leading-[140%]"
            >
              {/* Reset */}
              {getLocale("reset")}
            </button>
            <button
              onClick={() => {
                if (selectedAmount > 0) {
                  setAmountFilter(false);
                }
              }}
              className=" w-1/2 text-clip bg-bgBlue text-textWhite py-2 rounded-md tracking-[0.2px] leading-[140%]"
            >
              {/* Confirm */}
              {getLocale("confirm")}
            </button>
          </div>
        </div>
      </PopUp>
      <PopUp
        open={paymentFilter}
        setOpen={setPaymentFilter}
        title={"Payment Methods"}
      >
        <div className="px-4 pb-12 flex flex-col justify-between h-full">
          <div>
            <div className=" flex  bg-bgGray items-start gap-2 rounded-md py-2  px-2">
              <IconRecharging className="text-orange-600 mt-1" size={15} />
              <p className="text-[14px] text-left tracking-[0.2px] leading-[140%]">
                {/* Release the crypto automatically upon payment complition */}
                {getLocale("releaseCrypto")}
              </p>
            </div>
            <div className=" flex justify-between items-center w-full mt-2">
              <div className=" w-full bg-bgGray items-center rounded-md h-[35px] flex justify-between gap-1 pl-1">
                <IconSearch size={20} />
                <input
                  type="text"
                  className=" w-full h-full border-none outline-none placeholder:opacity-[0.5] bg-transparent "
                  placeholder="Search"
                />
              </div>
            </div>

            <div className=" grid grid-cols-2 m-auto gap-1 mt-2 ">
              {paymentMethods?.map((item, index) => (
                <div
                  onClick={() => setSelectedPaymentMethod(item)}
                  key={`payMethod-${index}`}
                  className={`text-center flex justify-center px-2 py-1 ${
                    selectedPaymentMethod === item
                      ? " bg-bgGray text-textPrime"
                      : "bg-bgBlue text-textWhite "
                  } rounded-md cursor-pointer`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>

          <div className=" w-full flex justify-between items-center mt-2 gap-2">
            <button
              onClick={() => setSelectedPaymentMethod("")}
              className=" w-1/2 text-clip bg-bgGray text-textPrime py-2 rounded-md tracking-[0.2px] leading-[140%]"
            >
              {/* Reset */}
              {getLocale("reset")}
            </button>
            <button
              onClick={() => {
                if (selectedPaymentMethod?.length > 0) {
                  setPaymentFilter(false);
                }
              }}
              className=" w-1/2 text-clip bg-bgBlue text-textWhite py-2 rounded-md tracking-[0.2px] leading-[140%]"
            >
              {/* Confirm */}
              {getLocale("confirm")}
            </button>
          </div>
        </div>
      </PopUp>

      <PopUp open={extraFIlter} setOpen={setExtraFilter}>
        <Filter selectedCurrency={selectedCurrency} />
      </PopUp>
    </div>
  );
};

const P2PCard = ({ buy, item, setWarn }) => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { P2PorderPayload } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const getAvgPercentage = (val) => {
    return (val * 100) / 5;
  };
  return (
    <div>
      <div className="px-4 border-b border-t border-borderGray py-2">
        <div className=" flex items-center gap-1 mb-1">
          {/* <div className=" w-5 h-5 rounded-full border flex justify-center items-center">
            <h1 className=" text-[13px] font-semibold">
              {item?.sellerId?.firstName?.split("")?.[0]}
            </h1>
          </div> */}
          <p className=" text-[13px] tracking-[1.02px] leading-[145%] font-semibold">
            {item?.sellerId?.firstName}
          </p>
          <IconRosetteDiscountCheck
            title={`KYC ${item?.sellerId?.kycStatus}`}
            className={`  font-semibold ${
              item?.sellerId?.kycStatus === "REJECTED"
                ? "text-red-500"
                : item?.sellerId?.kycStatus === "PENDING"
                ? "text-gray-500"
                : "text-green-500"
            } `}
            size={15}
          />
        </div>
        <div className=" flex items-center gap-1">
          <p className=" tracking-[1.02px] leading-[140%] text-[10px]">
            {getLocale("trades")} {item?.averageRating?.toFixed(2)} (
            {getAvgPercentage(item?.averageRating)?.toFixed(2)})%
          </p>
          <div className=" flex items-center gap-[2px] ">
            <IconThumbUp size={10} className=" text-textPrime" />
            <p className=" tracking-[1.02px] leading-[140%] text-[10px]">
              97.9%
            </p>
          </div>
        </div>
        <div className=" flex justify-between items-start mt-0.5">
          <div className=" text-left">
            <h1 className=" flex items-end">
              <span className=" tracking-[1.02px] leading-[140%] text-[12px] text-textSec">
                ₹
              </span>
              <p>
                <NumericFormat
                  value={item?.price}
                  displayType="text"
                  thousandSeparator
                />
              </p>
              <span className=" tracking-[1.02px] leading-[140%] text-[10px] text-textSec">
                /{item?.coin?.symbol}
              </span>
            </h1>
            <p className=" tracking-[1.02px] leading-[140%] text-[10px] text-textSec mt-1">
              {/* limit */}
              {getLocale("limit")}{" "}
              <span className=" text-textPrime">
                <NumericFormat
                  value={item?.minLimit}
                  displayType="text"
                  thousandSeparator
                />
                -{" "}
                <NumericFormat
                  value={item?.maxLimit}
                  displayType="text"
                  thousandSeparator
                />
              </span>
            </p>
            <p className=" tracking-[1.02px] leading-[140%] text-[10px] text-textSec">
              {/* Available */}
              {getLocale("available")}{" "}
              <span className=" text-textPrime">
                <NumericFormat
                  decimalScale={2}
                  value={item?.amount}
                  displayType="text"
                  thousandSeparator
                />

                {item?.coin?.symbol}
              </span>
            </p>
          </div>

          <div className=" text-right">
            <p className=" tracking-[1.02px] leading-[140%] text-[10px] text-textSec">
              {/* Digital eRupee */}
              {getLocale("digitaleRupee")}
            </p>
            <div className=" flex items-center justify-end mt-1">
              <IconClock size={10} className=" text-textPrime" />
              <p className=" tracking-[1.02px] leading-[140%] text-[10px] text-textSec">
                {item?.paymentTimeLimitMinutes}min
              </p>
            </div>
            {buy ? (
              <button
                onClick={() => {
                  if (user?.kycStatus !== "APPROVED") {
                    setWarn(true);
                  } else {
                    dispatch(
                      actions.setP2POrder({
                        ...P2PorderPayload,
                        type: "BUY",
                        offerId: item?._id,
                        paymentTimeLimitMinutes: item?.paymentTimeLimitMinutes,
                      })
                    );
                    navigate(ROUTES.BUYP2P, { state: { ID: item?._id } });
                  }
                }}
                className=" w-[90px] rounded-md bg-green-500 text-textWhite mt-1 flex items-center justify-center text-[16px] py-1 leading-[145%] tracking-[1.02px]"
              >
                {/* Buy */}
                {getLocale("buy")}
              </button>
            ) : (
              <button
                onClick={() => {
                  if (user?.kycStatus !== "APPROVED") {
                    setWarn(true);
                  } else {
                    dispatch(
                      actions.setP2POrder({
                        ...P2PorderPayload,
                        type: "SELL",
                        offerId: item?._id,
                        paymentTimeLimitMinutes: item?.paymentTimeLimitMinutes,
                      })
                    );
                    navigate(ROUTES.BUYP2P, { state: { ID: item?._id } });
                  }
                }}
                className=" w-[90px] rounded-md bg-bgDanger text-textWhite mt-1 flex items-center justify-center text-[16px] py-1 leading-[145%] tracking-[1.02px]"
              >
                {/* Sell */}
                {getLocale("sell")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default P2PHome;
