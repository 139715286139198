import { IconArrowNarrowLeft } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import qrCode from "../../Assets/image/QrCode.png";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";

function Transaction() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col">
        <div className=" flex justify-between items-center">
          <IconArrowNarrowLeft
            className="size-7 cursor-pointer"
            onClick={() => navigate(-1)}
          />

          <p></p>
        </div>
        <div className=" mt-5 flex justify-between items-center">
          <div>
            <p className="text-left text-[14px] leading-[145%] font-normal tracking-normal text-[#707070]">
              0 KMB
            </p>
            <h1 className="text-left text-[30px] leading-[145%] font-normal tracking-[-0.5px] text-[#707070]">
              $13.84
            </h1>
          </div>
          <div className=" w-[50px] h-[50px] rounded-full border border-[#707070]">
            <img className=" w-full h-full" src={qrCode} alt="" />
          </div>
        </div>

        <div className=" border border-[#CFCFCF] rounded-md px-4 py-4  mt-5">
          <h2 className="text-[22px] font-medium text-[#111111] leading-[145%] tracking-[-1px] text-left">
            {/* You have no transactions */}
            {getLocale("noTransactions")}
          </h2>
          <p className="text-[16px] font-normal text-[#707070] leading-[140%] tracking-[0.2px] text-left">
            {/* Buy KMB now, and your transactions will be displayed here. */}
            {getLocale("noTransactionsInfo")}
          </p>

          <button
            onClick={() => navigate(ROUTES.COIN)}
            className="w-full p-4 bg-bgBlue text-white rounded-lg mt-8 text-base"
          >
            {/* Buy KMB */}
            {getLocale("buyKMB")}
          </button>
        </div>
        <div className="mt-[100px]">
          <h2 className="text-[22px] font-medium text-[#111111] leading-[145%] tracking-[-1px] text-left">
            {/* Recurring purchase */}
            {getLocale("recurringPurchase")}
          </h2>
          <p className="text-[16px] font-normal text-[#707070] leading-[140%] tracking-[0.2px] text-left">
            {/* Not sure when to buy? Set up a recurring purchase. */}
            {getLocale("recurringPurchaseInfo")}
          </p>

          <button className="w-full p-4 bg-white text-[#111111] rounded-lg mt-8 text-base border border-md border-[#CFCFCF]">
            {/* Set up a recurring purchase. */}
            {getLocale("setRecurring")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Transaction;
