import React, { useState } from "react";
import {
  IconChevronLeft,
  IconChevronRight,
  IconLockFilled,
} from "@tabler/icons-react";
import { CustomInput } from "../Core/input";
import { useLocation, useNavigate } from "react-router-dom";
import stepImage from "../../Assets/image/step3start.png";
import Loader from "../Core/Loader";
import { Danger, Success } from "../Core/Errors";
import { addWhyUsing } from "../../service/api";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";

const purposes = [
  {
    title: "Investment",
    value: 0,
  },
  {
    title: "Trade cryptocurrencies",
    value: 1,
  },
  {
    title: "Trade with other exchanges",
    value: 2,
  },
  {
    title: "Buy CryptoKMBIO",
    value: 3,
  },
  {
    title: "Pay friends",
    value: 4,
  },
  {
    title: "Online payments",
    value: 5,
  },
  {
    title: "Business",
    value: 6,
  },
];
const Purpose = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const email = useLocation().state?.email;

  const handleResendCode = (value) => {
    try {
      setLoading(true);
      addWhyUsing({ whyUsingKMBIO: value })
        .then((resp) => {
          if (resp.status) {
            Success(resp?.message).then((_) => {
              navigate(ROUTES.EXPECTATIONS, { state: { email: email } });
            });
          }
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoading(false);
        })
        .finally((res) => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col justify-between">
      {loading && <Loader />}
      <div>
        <div className=" flex justify-between items-center">
          <IconChevronLeft
            className="size-7 cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <img src={stepImage} alt="step-top" />
          <p></p>
        </div>

        <div className="flex items-start flex-col w-full mt-6">
          <div>
            <h3 className="text-2xl font-medium text-left ">
              {/* Why are you using K-MBIO HX? */}
              {getLocale("whyKMBIO")}
            </h3>
            <p className="text-[#707070] text-[16px] space-x-[0.02px] text-left">
              {/* This question will help us greatly in improving our service. */}
              {getLocale("whyKMBIOInfo")}
            </p>
          </div>
        </div>
        <div className="mt-5">
          {purposes?.map((purpose, index) => (
            <div
              onClick={() => handleResendCode(purpose.title)}
              className=" flex justify-between items-center px-2 py-2 mt-2 transition-all rounded-md cursor-pointer active:bg-[#ccc]"
            >
              <div className=" flex items-center gap-2">
                <p className=" text-[16px] font-normal space-x-[0.2px text-[#111111] ">
                  {purpose.title}
                </p>
              </div>
              <IconChevronRight className=" cursor-pointer" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Purpose;
