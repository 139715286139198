import { IconLockFilled, IconX } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import stepIMage from "../../Assets/image/step1.png";
import fingerLock from "../../Assets/image/fingerLock.png";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";

function Security() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col justify-between">
        <div>
          <div className=" flex justify-between items-center">
            <IconX
              size={35}
              className="size-7 cursor-pointer"
              onClick={() => navigate(-1)}
            />
            <img src={stepIMage} alt="" />
            <p></p>
          </div>
          <div className="flex items-start justify-center w-full mt-[50px] h-[228px]">
            <img className="h-full" src={fingerLock} alt="card-image" />
          </div>
          <div className=" mt-[50px]">
            <h2 className=" text-[20px] font-medium  text-center leading-[130%] tracking-[-1px]  ">
              {/* Secure your account */}
              {getLocale("secAccount")}
            </h2>
            <p className=" text-center text-[#707070] text-[16px] font-normal tracking-[0.02px] mt-3 leading-[140%]">
              {/* One way to keep your account secure is through two-step
              verification, which requires your phone number. We will never call
              you or use your number without your permission. */}
              {getLocale("secAccountInfo")}
            </p>
          </div>
        </div>
        <button
          onClick={() => navigate(ROUTES.SECURITY_STEPS)}
          className="w-full p-4 bg-bgBlue text-white rounded-lg mt-2 text-base leading-[140%]"
        >
          {/* Continue */}
          {getLocale("continue")}
        </button>
      </div>
    </div>
  );
}

export default Security;
