import React, { useEffect, useState } from "react";
import { getAllP2PSupportedCoins } from "../../../service/api";
import PopUp from "./PopUp";
import { IconCheck, IconSearch } from "@tabler/icons-react";
import { Danger } from "../../Core/Errors";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/user/actions";
import { getLocale } from "../../../constants/language";

function CoinSlider({ setCurrFilter, currFilter }) {
  const [loader, setLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const { p2pAddPayload } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const getAssets = () => {
    try {
      setLoader(true);
      getAllP2PSupportedCoins(
        pageNumber,
        true,
        sizePerPage,
        p2pAddPayload?.type === "SELL" ? true : false
      )
        .then((res) => {
          setData(res.data?.result);
          setCount(res.data?.total);
          setLoader(false);
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoader(false);
        });
    } catch (error) {
      Danger(error?.response?.data?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    getAssets();
  }, [p2pAddPayload?.type]);
  const handleSelectCoin = (item) => {
    dispatch(actions.setSelectedCoin(item));
  };
  return (
    <PopUp open={currFilter} setOpen={setCurrFilter} title={"Select Coin"}>
      <div className=" px-4">
        <div className=" flex justify-between items-center w-full ">
          <div className=" w-[80%] bg-bgGray items-center rounded-md h-[30px] flex justify-between gap-1 pl-1">
            <IconSearch size={20} />
            <input
              type="text"
              className=" w-full h-full border-none outline-none placeholder:opacity-[0.5] bg-transparent "
              placeholder="Search Coin"
            />
          </div>
          <p
            onClick={() => {
              setCurrFilter(false);
              handleSelectCoin(null);
              dispatch(actions.setP2PPaylaod({ ...p2pAddPayload, coin: null }));
            }}
            className=" text-textPrime cursor-pointer text-[16px] tracking-[1.02px] leading-[140%]"
          >
            Cancel
          </p>
        </div>
        <div className=" mt-2 text-left text-textPrime opacity-[0.7]">
          <p>
            {/* P2P Supported Coins */}
            P2P{getLocale("supportedCoin")}
          </p>
        </div>
        <div>
          {data?.map((item, index) => (
            <div
              onClick={() => {
                setCurrFilter(false);
                dispatch(
                  actions.setP2PPaylaod({
                    ...p2pAddPayload,
                    coin: item,
                    fiatCurrency: null,
                    price: null,
                  })
                );
              }}
              className="rounded-md bg-opacity-10 w-[98%] cursor-pointer mt-2 flex items-center justify-center h-[74px] mx-auto active:bg-[#ccc]"
            >
              <img
                className=" w-10 h-10 rounded-full"
                alt={item?.name}
                src={item?.coin?.image}
              />
              <div className="text-[#111] text-left ml-3 text-base mr-auto">
                <h3 className="text-base">{item?.coin?.symbol}</h3>
                <h6 className="text-[10px] opacity-60">{item?.coin?.name}</h6>
              </div>
              <div className="text-right text-base ml-auto">
                {p2pAddPayload?.coin?.coin?._id === item?.coin?._id && (
                  <IconCheck size={20} className=" text-textPrime" />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </PopUp>
  );
}

export default CoinSlider;
