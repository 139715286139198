import { getLocale } from "../../../constants/language";

// export const validateP2pAddPayload = (payload) => {
//   const errors = {};

//   if (!payload.coin) {
//     errors.coin = getLocale("coinRequired");
//   }
//   if (!payload.type) {
//     errors.type = "Transaction type (Buy/Sell) is required.";
//   }
//   if (!payload.fiatCurrency) {
//     errors.fiatCurrency = "Fiat currency is required.";
//   }
//   if (payload.price == null) {
//     errors.price = "Price is required.";
//   }
//   if (payload.minLimit == null) {
//     errors.minLimit = "Minimum limit is required.";
//   }
//   if (payload.maxLimit == null) {
//     errors.maxLimit = "Maximum limit is required.";
//   }
//   if (
//     !Array.isArray(payload.paymentMethods) ||
//     payload.paymentMethods.length === 0
//   ) {
//     errors.paymentMethods = "At least one payment method is required.";
//   }
//   if (payload.paymentTimeLimit == null) {
//     errors.paymentTimeLimit = "Payment time limit is required.";
//   }
//   if (payload.amount == null) {
//     errors.amount = "Amount is required.";
//   }
//   if (!payload.remarks) {
//     errors.remarks = "Remarks are required.";
//   }

//   return {
//     isValid: Object.keys(errors).length === 0,
//     errors,
//   };
// };
// export const validateP2Order = (payload) => {
//   const errors = {};
//   if (!payload.totalAmountCoin) {
//     errors.totalAmountCoin = "Total amount is required.";
//   }
//   if (!payload.paymentMethods) {
//     errors.paymentMethods = "Payment is required.";
//   }
//   return {
//     isValid: Object.keys(errors).length === 0,
//     errors,
//   };
// };

// export const step1Error = (payload) => {
//   console.log("payload: ", payload);
//   const errors = {};
//   if (!payload.coin) {
//     errors.coin = "Please select a coin";
//   }
//   if (!payload.fiatCurrency) {
//     errors.fiatCurrency = "Please select a currency";
//   }
//   if (Number(payload.price) < payload?.fiatCurrency?.minP2PAmount) {
//     errors.price = "Price should be greater than min limit";
//   }
//   if (Number(payload.price) > payload?.fiatCurrency?.maxP2PAmount) {
//     errors.price = "Price should be less than max limit";
//   }
//   return {
//     isvalid: Object.keys(errors).length === 0,
//     errors,
//   };
// };
// export const step2Error = (payload) => {
//   const errors = {};
//   if (payload?.type === "SELL") {
//     if (payload?.amount > payload?.coin?.userFundingAmount) {
//       errors.amount = "Insufficient balance";
//     }
//   }
//   if (!payload.amount) {
//     errors.amount = "Enter amount";
//   }
//   if (!payload.minLimit) {
//     errors.minLimit = "Enter order limit";
//   }
//   if (!payload.maxLimit) {
//     errors.maxLimit = "Enter order limit";
//   }
//   if (payload.paymentMethods?.length <= 0) {
//     errors.paymentMethods = "Price should be greater than zero";
//   }
//   if (!payload.paymentTimeLimit) {
//     errors.paymentTimeLimit = "select payment time limit";
//   }
//   if (Number(payload.minLimit) > Number(payload?.maxLimit)) {
//     errors.minLimit = "Should be less than the max order limit";
//   }
//   if (
//     Number(payload.minLimit) / Number(payload?.price) >
//     Number(payload?.amount)
//   ) {
//     errors.minLimit = "Should be less than the order amount";
//   }
//   return {
//     isvalid: Object.keys(errors).length === 0,
//     errors,
//   };
// };
// export const step3Error = (payload) => {
//   const errors = {};
//   if (!payload.remarks) {
//     errors.remarks = "Fill the memo";
//   }
//   return {
//     isvalid: Object.keys(errors).length === 0,
//     errors,
//   };
// };
// export const validatePayoad = (payload, fields, byWhich, amount, buy) => {
//   const errors = {};
//   if (!payload.paymentMethods) {
//     errors.paymentMethods = "Please choose a payment method";
//   }
//   if (!payload.totalAmountCoin) {
//     errors.totalAmountCoin = "Please enter amount";
//   }
//   if (byWhich) {
//     if (Number(payload.totalAmountCoin) > fields?.maxLimit) {
//       errors.totalAmountCoin = "Please enter amount less than max limit";
//     }
//     if (Number(payload.totalAmountCoin) < fields?.minLimit) {
//       errors.totalAmountCoin = "Please enter amount greater than max limit";
//     }
//     if (
//       Number(payload.totalAmountCoin) / Number(fields?.price) > amount &&
//       !buy
//     ) {
//       errors.totalAmountCoin = "Insufficient amount in wallet";
//     }
//   } else {
//     if (Number(payload.totalAmountCoin) > fields?.maxLimit / fields?.price) {
//       errors.totalAmountCoin = "Please enter amount less than max limit";
//     }
//     if (Number(payload.totalAmountCoin) < fields?.minLimit / fields?.price) {
//       errors.totalAmountCoin = "Please enter amount greater than max limit";
//     }
//     if (Number(payload.totalAmountCoin) > amount && !buy) {
//       errors.totalAmountCoin = "Insufficient amount in wallet";
//     }
//   }

//   return {
//     isvalid: Object.keys(errors).length === 0,
//     errors,
//   };
// };
export const validateP2pAddPayload = (payload) => {
  const errors = {};

  if (!payload.coin) {
    errors.coin = getLocale("coinRequired");
  }
  if (!payload.type) {
    errors.type = getLocale("transactionTypeRequired");
  }
  if (!payload.fiatCurrency) {
    errors.fiatCurrency = getLocale("fiatCurrencyRequired");
  }
  if (payload.price == null) {
    errors.price = getLocale("priceRequired");
  }
  if (payload.minLimit == null) {
    errors.minLimit = getLocale("minLimitRequired");
  }
  if (payload.maxLimit == null) {
    errors.maxLimit = getLocale("maxLimitRequired");
  }
  if (
    !Array.isArray(payload.paymentMethods) ||
    payload.paymentMethods.length === 0
  ) {
    errors.paymentMethods = getLocale("atLeastOnePaymentMethodRequired");
  }
  if (payload.paymentTimeLimit == null) {
    errors.paymentTimeLimit = getLocale("paymentTimeLimitRequired");
  }
  if (payload.amount == null) {
    errors.amount = getLocale("amountRequired");
  }
  if (!payload.remarks) {
    errors.remarks = getLocale("remarksRequired");
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors,
  };
};

export const validateP2Order = (payload) => {
  const errors = {};
  if (!payload.totalAmountCoin) {
    errors.totalAmountCoin = getLocale("totalAmountRequired");
  }
  if (!payload.paymentMethods) {
    errors.paymentMethods = getLocale("paymentRequired");
  }
  return {
    isValid: Object.keys(errors).length === 0,
    errors,
  };
};

export const step1Error = (payload) => {
  console.log("payload: ", payload);
  const errors = {};
  if (!payload.coin) {
    errors.coin = getLocale("pleaseSelectACoin");
  }
  if (!payload.fiatCurrency) {
    errors.fiatCurrency = getLocale("pleaseSelectACurrency");
  }
  if (Number(payload.price) < payload?.fiatCurrency?.minP2PAmount) {
    errors.price = getLocale("priceGreaterThanMinLimit");
  }
  if (Number(payload.price) > payload?.fiatCurrency?.maxP2PAmount) {
    errors.price = getLocale("priceLessThanMaxLimit");
  }
  return {
    isvalid: Object.keys(errors).length === 0,
    errors,
  };
};

export const step2Error = (payload) => {
  const errors = {};
  if (payload?.type === "SELL") {
    if (payload?.amount > payload?.coin?.userFundingAmount) {
      errors.amount = getLocale("insufficientBalance");
    }
  }
  if (!payload.amount) {
    errors.amount = getLocale("enterAmount");
  }
  if (!payload.minLimit) {
    errors.minLimit = getLocale("enterOrderLimit");
  }
  if (!payload.maxLimit) {
    errors.maxLimit = getLocale("enterOrderLimit");
  }
  if (payload.paymentMethods?.length <= 0) {
    errors.paymentMethods = getLocale("priceGreaterThanZero");
  }
  if (!payload.paymentTimeLimit) {
    errors.paymentTimeLimit = getLocale("selectPaymentTimeLimit");
  }
  if (Number(payload.minLimit) > Number(payload?.maxLimit)) {
    errors.minLimit = getLocale("shouldBeLessThanMaxOrderLimit");
  }
  if (
    Number(payload.minLimit) / Number(payload?.price) >
    Number(payload?.amount)
  ) {
    errors.minLimit = getLocale("shouldBeLessThanOrderAmount");
  }
  return {
    isvalid: Object.keys(errors).length === 0,
    errors,
  };
};

export const step3Error = (payload) => {
  const errors = {};
  if (!payload.remarks) {
    errors.remarks = getLocale("fillTheMemo");
  }
  return {
    isvalid: Object.keys(errors).length === 0,
    errors,
  };
};

export const validatePayoad = (payload, fields, byWhich, amount, buy) => {
  const errors = {};
  if (!payload.paymentMethods) {
    errors.paymentMethods = getLocale("pleaseChooseAPaymentMethod");
  }
  if (!payload.totalAmountCoin) {
    errors.totalAmountCoin = getLocale("pleaseEnterAmount");
  }
  if (byWhich) {
    if (Number(payload.totalAmountCoin) > fields?.maxLimit) {
      errors.totalAmountCoin = getLocale("pleaseEnterAmountLessThanMaxLimit");
    }
    if (Number(payload.totalAmountCoin) < fields?.minLimit) {
      errors.totalAmountCoin = getLocale("pleaseEnterAmountGreaterThanMaxLimit");
    }
    if (
      Number(payload.totalAmountCoin) / Number(fields?.price) > amount &&
      !buy
    ) {
      errors.totalAmountCoin = getLocale("insufficientAmountInWallet");
    }
  } else {
    if (Number(payload.totalAmountCoin) > fields?.maxLimit / fields?.price) {
      errors.totalAmountCoin = getLocale("pleaseEnterAmountLessThanMaxLimit");
    }
    if (Number(payload.totalAmountCoin) < fields?.minLimit / fields?.price) {
      errors.totalAmountCoin = getLocale("pleaseEnterAmountGreaterThanMaxLimit");
    }
    if (Number(payload.totalAmountCoin) > amount && !buy) {
      errors.totalAmountCoin = getLocale("insufficientAmountInWallet");
    }
  }

  return {
    isvalid: Object.keys(errors).length === 0,
    errors,
  };
};

