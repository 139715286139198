import { IconArrowNarrowLeft, IconX } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAllCoins, getAllPaymethods } from '../../../service/api';
import { Danger } from '../../Core/Errors';
import Loader from '../../Core/Loader';
import CurrencySlider from './CurrencySlider';

const paymentTypes = [
    {
        title: "IMPS",
        color: "bg-yellow-500"
    },
    {
        title: "Paytm",
        color: "bg-blue-500"
    },
    {
        title: "UPI",
        color: "bg-red-500"
    },
    {
        title: "Bank tranfer (India)",
        color: "bg-orange-500"
    },


]
function Recomended() {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [currFilter, setCurrFilter] = useState(false)
    const [selectedCurr, setSelectedCurr] = useState({ _id: -1 })
    const [pageNumber, setPageNumber] = useState(1)
    const [sizePerPage, setSizePerPage] = useState(10)
    const [paymentTypes, setPaymentTypes] = useState([])
    
    const [count, setCount] = useState(0)
    const getAdminAddedPayMethods = () => {
        try {
            setLoader(true)
            getAllPaymethods(pageNumber, true, sizePerPage).then(res => {
                setPaymentTypes(res.data?.result)
                setCount(res.data?.total)
                setLoader(false);
            }).catch(error => {
                
                Danger(error?.response?.data?.message)
                setLoader(false);
            })
        } catch (error) {
            
            Danger(error?.response?.data?.message)
            setLoader(false);
        }
    }
    useEffect(()=>{
        getAdminAddedPayMethods()
    },[])
    return (
        <div>
            {loader && <Loader />}
            <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col ">
                <div>
                    <div className=' flex justify-between items-center' >
                        <IconArrowNarrowLeft className="size-7 cursor-pointer" onClick={() => navigate("/paymentMethods")} />
                        <h1 className='font-medium leading-[145%] tracking-[-1px] text-[18px] text-[#111111] '></h1>
                        <p></p>
                    </div>
                </div>
                <h1 className='font-semibold text-left mt-5 leading-[145%] tracking-[1px] text-[20px] text-[#111111] '>Recommended</h1>
                <div className=' mt-5'>
                    <p className='font- opacity-50 text-left mt-5 leading-[145%] tracking-[1px] text-[16px] text-[#111111]'>Select a payment method</p>
                    {
                        paymentTypes?.map((item, index) => (
                            <div key={`pay-${index}`} onClick={() => navigate("/addPayMethod", { state: { ID: item?._id } })} className="rounded-md bg-opacity-10 w-full cursor-pointer mt-2 flex items-center justify-center py-4 mx-auto active:bg-[#ccc] border">
                                <div className="text-[#111] text-left ml-3 text-base mr-auto flex h-full items-center gap-0.5">
                                    <div className={`${item.color} w-[2px] h-5`}></div>
                                    <p>{item?.type}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>

                <div onClick={() => setCurrFilter(true)} className=' px-4 w-full absolute bottom-0 left-0 justify-center flex items-center '>
                    <button className=' w-[95%]  text-clip absolute bottom-0 bg-bgBlue text-textWhite py-2 rounded-md tracking-[0.2px] leading-[140%] mb-2'>All Payment Methods</button>
                </div>
            </div>
            <CurrencySlider currFilter={currFilter} setCurrFilter={setCurrFilter} selectedCoin={selectedCurr} setSelectedCoin={setSelectedCurr} />
        </div>
    )
}

export default Recomended