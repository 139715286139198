import actions from "./actions";

const initialState = {
  user: null,
  token: null,
  loggedIn: false,
  loading: false,
  firebaseToken: null,
  selectedCoin: null,
  selectedCurrency: null,
  p2pFormStep: 1,
  activePair: null,
  activeCoin: {},
  activeWithdrawCoin: null,
  p2pAddPayload: {
    coin: null,
    type: "BUY",
    fiatCurrency: null,
    price: null,
    minLimit: null,
    maxLimit: null,
    paymentMethods: [],
    paymentTimeLimit: null,
    amount: null,
    remarks: null,
  },
  P2PorderPayload: {
    type: "",
    offerId: null,
    totalAmountCoin: null,
    totalPaymentInFait: null,
    paymentMethods: null,
    paymentTimeLimitMinutes: null,
  },
  orderChatId: null,
  language:"en"
};

const {
  SET_USER,
  SET_TOKEN,
  SET_LOADING,
  SET_LOGGEDIN,
  SET_FIREBASE_TOKEN,
  SET_SELECTED_COIN,
  SET_SELECTED_CURRENCY,
  SET_P2P_PAYLOAD,
  REMOVE_P2P_PAYLOAD,
  SET_P2P_FROM_STEP,
  SET_P2P_ORDER_FORM,
  REMOVE_P2P_ORDER_FORM,
  ACTIVE_PAIR,
  ACTIVE_COIN,
  ADD_CHAT_ID,
  REMOVE_CHAT_ID,
  ACTIVE_WITHDRAW_COIN,
  SET_LANGUAGE,
} = actions;
const UserReducer = (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case SET_USER:
      return {
        ...state,
        user: data,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: data,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    case SET_LOGGEDIN:
      return {
        ...state,
        loggedIn: data,
      };
    case SET_FIREBASE_TOKEN:
      return {
        ...state,
        firebaseToken: data,
      };
    case SET_SELECTED_COIN:
      return {
        ...state,
        selectedCoin: data,
      };
    case SET_SELECTED_CURRENCY:
      return {
        ...state,
        selectedCurrency: data,
      };
    case SET_P2P_PAYLOAD:
      return {
        ...state,
        p2pAddPayload: { ...data },
      };
    case REMOVE_P2P_PAYLOAD:
      return {
        ...state,
        selectedCurrency: { ...data },
      };
    case SET_P2P_ORDER_FORM:
      return {
        ...state,
        P2PorderPayload: { ...data },
      };
    case REMOVE_P2P_ORDER_FORM:
      return {
        ...state,
        P2PorderPayload: { ...data },
      };
    case ACTIVE_PAIR:
      return {
        ...state,
        activePair: { ...data },
      };
    case ACTIVE_COIN:
      return {
        ...state,
        activeCoin: { ...data },
      };
    case ACTIVE_WITHDRAW_COIN:
      return {
        ...state,
        activeWithdrawCoin: { ...data },
      };
    case SET_P2P_FROM_STEP:
      return {
        ...state,
        p2pFormStep: data,
      };
    case ADD_CHAT_ID:
      return {
        ...state,
        orderChatId: data,
      };
    case REMOVE_CHAT_ID:
      return {
        ...state,
        orderChatId: data,
      };
      case SET_LANGUAGE:
        return {
          ...state,
          language: data,
        };
    default:
      return state;
  }
};

export default UserReducer;
