import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

const NotradeModal = ({ warn, setWarn }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  console.log("%c Line:10 🍻 user", "color:#b03734", user);
  return (
    <Modal
      open={warn}
      onClose={() => setWarn(false)}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "#fefefe",
          boxShadow: 24,
          borderRadius: 5,
          p: 3,
          textAlign: "center",
          outline: "none",
        }}
      >
        <button
          onClick={() => setWarn(false)}
          style={{
            position: "absolute",
            top: 10,
            right: 15,
            background: "transparent",
            border: "none",
            cursor: "pointer",
            fontSize: "1.5rem",
            lineHeight: "1",
            color: "#000",
            width: 20,
            height: 20,
          }}
          aria-label="Close"
        >
          &times;
        </button>

        <Typography
          id="modal-title"
          variant="h6"
          sx={{ mt: 2 }}
          fontWeight="bold"
        >
          {user?.kycStatus === "PENDING"
            ? "KYC Not Initiated"
            : "KYC Pending Approval"}
        </Typography>
        <Typography
          id="modal-description"
          variant="body2"
          sx={{ mt: 1, mb: 2 }}
        >
          You cannot proceed with trades until your KYC is successfully verified
          and approved.
        </Typography>

        <div className="flex justify-center items-center w-full gap-2 mt-5">
          {user?.kycStatus === "PENDING" ||
            (user?.kycStatus === "IN-PROCESS" && user?.kycLevel !== 4) ||
            (user?.kycStatus === "REJECTED" && (
              <button
                onClick={() => {
                  setWarn(false);
                  if (user?.kycLevel === 1) {
                    navigate(ROUTES.SECURITY_ID);
                  } else if (user?.kycLevel === 2) {
                    navigate(ROUTES.PERSONAL_INFO);
                  } else if (user?.kycLevel === 3) {
                    navigate(ROUTES.S_PHOTO_ID);
                  }
                }}
                className="w-full p-3 bg-bgBlue text-white rounded-full text-base font-[600]"
              >
                {user?.kycStatus === "REJECTED"
                  ? "Reiniated KYC"
                  : "Complete KYC"}
              </button>
            ))}
        </div>
      </Box>
    </Modal>
  );
};

export default NotradeModal;
