import { IconLockFilled, IconX } from "@tabler/icons-react";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import stepImage from "../../Assets/image/step3.png";
import cardImage from "../../Assets/image/uploadImg.png";
import { uploadDcoument } from "../../service/api";
import { Danger, Success } from "../Core/Errors";
import Loader from "../Core/Loader";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";

function FaceId() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const frontImg = useLocation().state?.front;
  console.log("frontImg: ", frontImg);
  const backImg = useLocation().state?.back;
  console.log("backImg: ", backImg);
  const profileImg = useLocation().state?.profile;
  console.log("profileImg: ", profileImg);

  const uploadFIles = () => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("documentFrontImage", frontImg);
      formData.append("documentBackImage", backImg);
      formData.append("userSelfie", profileImg);
      uploadDcoument(formData)
        .then((resp) => {
          console.log("resp: ", resp);
          if (resp.status) {
            Success(resp?.message).then((_) => {
              navigate(ROUTES.ADDRESS);
            });
          }
        })
        .catch((error) => {
          console.log("error: ", error?.response?.data?.message);
          Danger(error?.response?.data?.message);
          setLoading(false);
        })
        .finally((res) => {
          setLoading(false);
        });
    } catch (error) {}
  };
  return (
    <div>
      {loading && <Loader />}
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col justify-between">
        <div>
          <div className=" flex justify-between items-center">
            <IconX
              className="size-7 cursor-pointer"
              onClick={() => navigate(-1)}
            />
            <img src={stepImage} alt="step-top" />
            <p></p>
          </div>
          <div className="flex items-start justify-center w-full mt-[50px]">
            <img src={cardImage} alt="card-image" />
          </div>
          <div className=" mt-[100px]">
            <h2 className=" text-[22px] font-[700] text-center ">
              {/* Verify with Photo ID */}
              {getLocale("verifyPhotoId")}
            </h2>
            <p className=" text-left text-[#707070] text-[16px] font-normal space-x-[0.2px] mt-3">
              {/* Financial regulations require us to verify your identity. This
              helps prevent someone else from creating an account in your name. */}
              {getLocale("photoIdInfo")}
            </p>
          </div>
        </div>
        <button
          onClick={uploadFIles}
          className="w-full p-4 bg-bgBlue text-white rounded-lg mt-2 text-base"
        >
          {/* Continue */}
          {getLocale("continue")}
        </button>
      </div>
    </div>
  );
}

export default FaceId;
