import React, { useState } from "react";
import PopUp from "./PopUp";
import { getLocale } from "../../../constants/language";

function Confrim({ setCurrFilter, currFilter, placeOrder }) {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <PopUp
      height={100}
      open={currFilter}
      setOpen={setCurrFilter}
      title={"P2P Terms"}
    >
      <div className=" h-full relative pb-5">
        <div className=" px-4">
          <p className=" text-textPrime opacity-50 text-[10px] text-left  ">
            {/* Make sure to scroll through and read the Binance P2P Additional Verification Requirements & Guidelines before agreeing. */}
            {getLocale("makeSureScroll")}
          </p>

          <h1 className=" mt-5 text-[14px] text-textPrime  text-left font-semibold ">
            {/* If you are a P2P taker: */}
            {getLocale("p2pTracker")}
          </h1>

          <div className=" px-4 mt-10">
            <ul>
              <li className=" list-disc text-[14px] text-textPrime text-left">
                {/* You acknowledge and agree that Binance is not a party singly or together with your counterparty to the P2P transaction. You also acknowledge and agree that you are sharing your personal information for identity verification purposes with your counterparty and not with Binance. */}
                {getLocale("youAclnowledge")}
              </li>
              <li className=" list-disc text-[14px] text-textPrime text-left mt-5">
                {/* Your conduct of a P2P transaction with a counterparty will be deemed your agreement to provide required personal information for identity verification purposes to that counterparty in accordance with the applicable laws and regulations. Such information may be used to verify your identity, identify traces of money laundering, */}
                {getLocale("youConduct")}
              </li>
              <li className=" list-disc text-[14px] text-textPrime text-left mt-5">
                {/* In addition to providing such information, you agree to allow your counterparty to keep a record of that information in compliance with the applicable laws and regulations. You also authorize the counterparty to conduct necessary investigations directly or through a third party to verify your identity or protect you and/or */}
                {getLocale("inAddition")}
              </li>
            </ul>
          </div>
        </div>
        <div className=" absolute bottom-[60px] left-0 w-full flex flex-col justify-center px-4  ">
          <div className=" flex justify-start items-center gap-1 mb-4 ml-2">
            <input
              type="checkbox"
              id="checked"
              className=" scale-[1.3] cursor-pointer"
              value={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
            <label
              htmlFor="checked"
              className=" cursor-pointer text-textPrime opacity-100 text-[14px] text-left "
            >
              {/* I have read and agree to the terms. */}
              {getLocale("readTC")}
            </label>
          </div>
          <button
            disabled={!isChecked}
            onClick={() => {
              placeOrder();
            }}
            className="  w-full rounded-md m-auto py-3 text-center flex items-center justify-center gap-1 text-textWhite bottom-[0px] left-0  bg-bgBlue disabled:bg-bgGray"
          >
            <span>
              {/* Confirm */}
              {getLocale("confirm")}
            </span>
          </button>
        </div>
      </div>
    </PopUp>
  );
}

export default Confrim;
