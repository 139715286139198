import { IconArrowNarrowLeft, IconX } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import referBarCode from "../../Assets/image/referBarCode.png";
import crypto from "../../Assets/image/Crypto.png";
import { getLocale } from "../../constants/language";

import { ROUTES } from "../../constants/routes";

function Recieve() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col justify-between">
        <div>
          <div className=" flex justify-between items-center">
            <IconX
              className="size-7 cursor-pointer"
              onClick={() => navigate(-1)}
            />
            <h1 className="font-medium leading-[140%] tracking-[-1px] ">
              {/* Receive KMB */}
              {getLocale("receiveKMB")}
            </h1>
            <p></p>
          </div>
          <div className="w-full pt-[70px] border border-[#CFCFCF] rounded-lg overflow-hidden  flex justify-center items-center mt-4 flex-col">
            <img className=" object-cover" src={referBarCode} alt="barcode" />
            <div className="mt-[50px] w-full  border-t  border-[#CFCFCF] py-4 flex items-center justify-between px-2">
              <div>
                <p className=" text-left leading-[145%] font-normal tracking-normal">
                  {/* Wallet address */}
                  {getLocale("walletAddress")}
                </p>
                <p className="text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal text-center">
                  k-mbio.ai/refer192845
                </p>
              </div>
              <button className="rounded-md border border-[#CFCFCF] py-2 px-4 text-center text-[16px] tracking-normal leading-[140%] font-medium">
                {/* Copiar */}
                {getLocale("copy")}
              </button>
            </div>
          </div>
        </div>

        <div>
          <div className=" w-full py-3 border border-[#CFCFCF] rounded-md flex justify-between items-center px-4 mb-6">
            <div className=" flex gap-3 items-center">
              <img
                src={crypto}
                className=" h-[50px] w-[50px] rounded-full"
                alt=""
              />
              <p className=" text-[#111111] leading-[140%] tracking-[0.02px] text-[16px]">
                KMB {getLocale("wallet")}
              </p>
            </div>
            <div>
              <p className="text-right text-[14px] leading-[145%] font-normal tracking-normal text-[#111111]">
                $13.85%
              </p>
              <p className=" text-right text-[14px] leading-[145%] font-normal tracking-normal text-[#707070]">
                8.52889997 KMB
              </p>
            </div>
          </div>
          <button
            onClick={() => navigate(ROUTES.MARKET)}
            className="w-full p-4 bg-bgBlue text-white rounded-lg mt-2 text-base"
          >
            {/* Share address */}
            {getLocale("shareAddress")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Recieve;
