import { IconX } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import circleLogo from "../../Assets/image/Ellipse.png";
import { useNavigate } from "react-router-dom";
import { getAllCoins } from "../../service/api";
import { Danger } from "../Core/Errors";
import Loader from "../Core/Loader";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";

// const data = [
//     {
//         name: "Bitcoin",
//         currency: "BTC",
//         price: "36,701.78",
//         inflation: "-6.28"
//     },
//     {
//         name: "Bitcoin",
//         currency: "BTC",
//         price: "36,701.78",
//         inflation: "+2.28"
//     },
//     {
//         name: "Bitcoin",
//         currency: "BTC",
//         price: "36,701.78",
//         inflation: "-6.28"
//     },
//     {
//         name: "Bitcoin",
//         currency: "BTC",
//         price: "36,701.78",
//         inflation: "-6.28"
//     },
//     {
//         name: "Bitcoin",
//         currency: "BTC",
//         price: "36,701.78",
//         inflation: "+4.28"
//     },
//     {
//         name: "Bitcoin",
//         currency: "BTC",
//         price: "36,701.78",
//         inflation: "-6.28"
//     },
//     {
//         name: "Bitcoin",
//         currency: "BTC",
//         price: "36,701.78",
//         inflation: "-6.28"
//     },
//     {
//         name: "Bitcoin",
//         currency: "BTC",
//         price: "36,701.78",
//         inflation: "-6.28"
//     },
//     {
//         name: "Bitcoin",
//         currency: "BTC",
//         price: "36,701.78",
//         inflation: "-6.28"
//     },
// ]
function SelectAssetsToBuy() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);

  const getAssets = () => {
    try {
      setLoader(true);
      getAllCoins(pageNumber, true, sizePerPage)
        .then((res) => {
          setData(res.data?.result);
          setCount(res.data?.total);
          setLoader(false);
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoader(false);
        });
    } catch (error) {
      Danger(error?.response?.data?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    getAssets();
  }, []);
  return (
    <div>
      {loader && <Loader />}
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col ">
        <div>
          <div className=" flex justify-between items-center">
            <IconX
              className="size-7 cursor-pointer"
              onClick={() => navigate(-1)}
            />
            <h1 className="font-medium leading-[145%] tracking-[-1px] text-[18px] text-[#111111] ">
              {/* Select an asset to buy */}
              {getLocale("select")}
            </h1>
            <p></p>
          </div>
        </div>

        <div>
          {data?.map((item, index) => (
            <div
              key={item?._id}
              onClick={() => navigate(ROUTES.BUY, { state: { coin: item } })}
              className="rounded-md bg-opacity-10 w-[98%] px-4 cursor-pointer mt-2 flex items-center justify-center h-[74px] mx-auto active:bg-[#ccc]"
            >
              <img
                className=" w-10 h-10 rounded-full"
                alt={item?.name}
                src={item?.image}
              />
              <div className="text-[#111] text-left ml-3 text-base mr-auto">
                <h3 className="text-base">{item?.name}</h3>
                <h6 className="text-sm opacity-60">{item?.symbol}</h6>
              </div>
              <div className="text-right text-base ml-auto">
                <h3 className="text-base">${item?.price}</h3>
                <h6
                  className={`text-sm ${
                    item?.inflation?.[0] == "-"
                      ? "text-[#E25C5C]"
                      : "text-[#3f845f]"
                  }`}
                >
                  {item?.inflation}%
                </h6>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SelectAssetsToBuy;
