import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import successLofo from "../../Assets/image/BuySent.png";
import { getLocale } from "../../constants/language";


const BuySent = () => {
  const navigate = useNavigate();
  const [passwordToggle, setPasswordToggle] = useState(false);
  return (
    <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col justify-between">
      <div className=" flex flex-col justify-center items-center pt-[60px]">
        <img src={successLofo} alt="success" />
        <div className="mt-8">
          <h2 className="text-[#111111] text-[22px] space-x-[-1px] text-center">
            {/* Your order has been sent */}
            {getLocale("orderSent")}
          </h2>
          <p className="text-[#707070] mt-4 text-center">
            {/* We’ll send you an email with your status order */}
            {getLocale("orderSentInfo")}
          </p>
        </div>
      </div>
      <button
        onClick={() => navigate("/orderStatus")}
        className="w-full p-4 bg-bgBlue text-white rounded-lg mt-10 text-base"
      >
        {/* My account */}
        {getLocale("myAccount")}
      </button>
    </div>
  );
};

export default BuySent;
